import { returnsTypes } from './actions';

const initialState = {
  returnsStatuses: [],
  countReturns: [],
  returns: [],
  has_remaining_returns: false,
  activeReturn: {},
  carriers: [],
  category: [],
  filterFields: {
    from: '',
    to: '',
    carrier: '',
    channel: '',
    status: '',
    reason: '',
    state: '',
    archived: false,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case returnsTypes.SET_FILTER_FIELDS.SUCCESS:
      return {
        ...state,
        filterFields: action.payload
      };
    case returnsTypes.GET_RETURNS.SUCCESS:
      const { savePrevious, data } = action.payload;
      const returnsById = data.reduce((acc, row) => {
        acc[row.idReturn] = row;
        return acc;
      }, {});
      const returnsUpdated = state.returns.map((row) => {
        const returnId = returnsById[row.idReturn];
        delete returnsById[row.idReturn];

        return returnId || row;
      });

      return {
        ...state,
        returns: savePrevious ? [...returnsUpdated, ...Object.values(returnsById)] : data,
        has_remaining_returns: action.payload.has_remaining_returns,
      };
    case returnsTypes.GET_CARRIERS.SUCCESS:
      return {
        ...state,
        carriers: action.payload
      };
    case returnsTypes.GET_REASONS.SUCCESS:
      return {
        ...state,
        category: action.payload
      };
    case returnsTypes.GET_RETURN.SUCCESS:
      return {
        ...state,
        activeReturn: action.payload
      };
    case returnsTypes.CREATE_RETURN.SUCCESS:
      return {
        ...state
        // returns: action.payload
      };
    case returnsTypes.GET_RETURNS_STATUSES.SUCCESS:
      return {
        ...state,
        returnsStatuses: action.payload
      };
    case returnsTypes.GET_COUNT_RETURNS.SUCCESS:
      return {
        ...state,
        countReturns: action.payload
      };
    case returnsTypes.EDIT_RETURN.SUCCESS:
      return {
        ...state,
        activeReturn: {
          ...state.activeReturn,
          return: {
            ...state.activeReturn.return,
            ...action.payload.return
          },
          property: state.activeReturn.property.map((property) => {
            const activeOption = action.payload.options.find(
              (option) => +option.idCategory === +property.idCategory
            );
            return {
              ...property,
              selectedOption: activeOption?.idOption || property?.selectedOption
            };
          }),
          activities: [action.payload.activity, ...state.activeReturn.activities],
          products: {
            ...state.activeReturn.products,
            products: state.activeReturn.products.products.map((product) => {
              const activeProduct =
                action.payload.products.find(
                  (responseProduct) => +responseProduct.idReturnProduct === +product.idReturnProduct
                ) || {};
              return {
                ...product,
                ...activeProduct
              };
            })
          }
        }
      };
    case returnsTypes.ADD_RETURN_MESSAGE.SUCCESS:
      return {
        ...state,
        activeReturn: {
          ...state.activeReturn,
          chat: [...state.activeReturn.chat, action.payload]
        }
      };
    case returnsTypes.DELETE_RETURN_MESSAGE.SUCCESS:
      return {
        ...state,
        activeReturn: {
          ...state.activeReturn,
          chat: state.activeReturn.chat.filter((message) => +message.idMessage !== +action.payload)
        }
      };
    default:
      return { ...state };
  }
};
