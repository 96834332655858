import NoImage from 'assets/images/no-photo.png';
import ArrowIcon from 'assets/svg/arrow-left-blue.svg';
import clx from 'classnames';
import BrokenAddAndEditModal from 'Containers/Brokens/containers/BrokensList/containers/BrokenAddAndEditModal';
import Modal from 'Containers/Common/Modal';
import ReturnAddAndEditModal from 'Containers/Returns/containers/ReturnsList/containers/ReturnAddAndEditModal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import useWindowSize from 'utils/hooks/useWindowSize';

import BinIcon from '../../../../assets/svg/bin_red.svg';
import EyeIcon from '../../../../assets/svg/eye.svg';
import PlusIcon from '../../../../assets/svg/plus-icon.svg';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import ManageFilesModal from '../../../Common/ManageFilesModal';
import SideModal from '../../../Common/SideModal';
import TextFieldMockup from '../../../Common/TextFieldMockup';
import { supportsActions } from '../../store';
import { selectSupport } from '../../store/selectors';
import styles from './SupportDetails.module.scss';

const SupportDetails = () => {
  const dispatch = useDispatch();
  const { supportId } = useParams();
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  const [isCreateReturnModalOpen, setIsCreateReturnModalOpen] = useState(false);
  const [isCreateBrokenModalOpen, setIsCreateBrokenModalOpen] = useState(false);
  const [isManageFilesModalOpen, setIsManageFilesModalOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);

  const support = useSelector(selectSupport());

  useEffect(() => {
    dispatch(supportsActions.GET_SUPPORT.REQUEST(supportId));
  }, []);

  const infoDetails = [
    {
      id: 1,
      label: 'Numero Ordine',
      value: support?.number
    },
    {
      id: 2,
      label: 'Corriere di andata',
      value: support?.carrier
    },
    {
      id: 3,
      label: 'Data creazione ordine',
      value: moment(support?.date).format('DD/MM/YYYY')
    },
    {
      id: 4,
      label: 'Nome cliente',
      value: `${support?.customer?.name || ''} ${support?.customer?.surname || ''}`
    },
    {
      id: 5,
      label: 'Canale',
      value: support?.channel
    },
    {
      id: 6,
      label: 'Email Cliente',
      value: support?.customer?.email
    },
    {
      id: 7,
      label: 'Indirizzo di spedizione',
      value: support.shippingAddress?.address
        ? `${support.shippingAddress?.address} ${support.shippingAddress?.city}
         ${support.shippingAddress?.postalCode} ${support.shippingAddress?.state}`
        : 'Nessun indirizzo trovato'
    },
    {
      id: 8,
      label: 'Data di spedizione',
      value: support.shipping_date
    },
    {
      id: 9,
      label: 'Tracking',
      value: support.tracking
    }
  ];

  const actions = [
    {
      id: 1,
      value: support?.return,
      label: 'Reso'
    },
    {
      id: 2,
      value: support?.break,
      label: 'Rottura'
    },
    {
      id: 3,
      value: support?.error,
      label: 'Errore'
    }
  ];

  const refuseSupport = () => {
    dispatch(supportsActions.EDIT_SUPPORT.REQUEST({ id: supportId, status: 2 }));
  };

  const openProductImagesSidebar = (product) => {
    setActiveProduct(product);
    setIsManageFilesModalOpen(true);
  };

  return Object.values(support).length
? (
    <>
      {location.state?.page >= 0
? (
        <PrimaryButton
          height="34px"
          fontSize="13px"
          width="100px"
          padding="8px 10px"
          margin="0 0 20px 0"
          borderRadius="40px"
          text={'Indietro'}
          leftIconSrc={ArrowIcon}
          onClick={() =>
            navigate(location.state?.pathname, { state: { page: location.state?.page || 0 } })
          }
        />
      )
: null}
      <div className={clx(styles['container-inner'], { [styles.container_mbl]: isMobile })}>
        <div className={styles.info}>
          <div className={styles['block-wrapper']}>
            <p className={styles.info__title}>Info richiesta assistenza</p>
            <div className={styles.info__body}>
              {infoDetails.map((i) => (
                <TextFieldMockup key={i.id} maxWidth={'275px'} value={i.value} label={i.label} />
              ))}
            </div>
          </div>
          {!!support?.products?.length && (
            <div className={styles.products_container}>
              <h2 className={styles.products_title}>Prodotti segnalati</h2>
              <div className={styles['products-list']}>
                {support?.products?.map((product) => (
                  <div key={product.idProduct} className={styles['product-item']}>
                    <div className={styles['first-row']}>
                      <div className={styles.image}>
                        <img src={product?.image || NoImage} alt="product-image" />
                      </div>
                      <div className={styles.inner_info}>
                        <div className={styles['name-info']}>
                          <div className={styles['name-status']}>
                            <div className={styles.name}>
                              {product.name} ({product.sku})
                            </div>
                          </div>
                          <div className={styles['info-files']}>
                            <b>Motivo richiesta</b>
                            <div className={styles['long-value']}>
                              {support?.support?.customerReason}
                            </div>
                            <div className={styles.files}>
                              <img
                                src={EyeIcon}
                                onClick={() => openProductImagesSidebar(product)}
                              />
                              <div className={styles.files__qty}>
                                +{product.supportValue?.length && product?.supportValue[0]?.images?.length || 0}
                                &nbsp; allegato
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.quantities}>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità ordine</p>
                            <div className={styles['qty-value']}>
                              {product.quantity}
                            </div>
                          </div>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità rese</p>
                            <div className={styles['qty-value']}>
                              {product.supportValue?.length && product.supportValue[0]?.returnQuantity}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles['second-row']}>
                      <div>
                        <p>Nota</p>
                        <textarea disabled name="" id="" cols="20" rows="5">{product.supportValue?.length ?
                          product?.supportValue[0]?.note : ''}</textarea>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!support?.productsOriginal?.length && (
            <div className={styles.products_container}>
              <h2 className={styles.products_title}>{"Prodotti nell'ordine"}</h2>
              <div className={styles['products-list']}>
                {support?.productsOriginal?.map((product) => (
                  <div key={product.idProduct} className={styles['product-item']}>
                    <div className={styles['first-row']}>
                      <div className={styles.image}>
                        <img src={product?.image || NoImage} alt="product-image" />
                      </div>
                      <div className={styles.inner_info}>
                        <div className={styles['name-info']}>
                          <div className={styles['name-status']}>
                            <div className={styles.name}>
                              {product.name} ({product.sku})
                            </div>
                          </div>
                        </div>
                        <div className={styles.quantities}>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità ordine</p>
                            <div className={styles['qty-value']}>
                              {product.quantity}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles.made}>
          <p className={styles.info__title}>Accetta richiesta di assistenza</p>
          <div className={styles.made__actions}>
            {actions.map((action) => (
              <div key={action.id} className={styles.actions__item}>
                <PrimaryButton
                  onClick={() => {
                    switch (action.id) {
                      case 1: {
                        action?.value
                          ? navigate(`${ROUTES.RETURNS}/${action?.value}`)
                          : setIsCreateReturnModalOpen(true);
                        break;
                      }
                      case 2: {
                        action?.value
                          ? navigate(`${ROUTES.BROKENS}/${action?.value}`)
                          : setIsCreateBrokenModalOpen(true);
                        break;
                      }
                      case 3: {
                        return;
                      }
                    }
                  }}
                  leftIconSrc={action.value ? EyeIcon : PlusIcon}
                  background={`${action.value ? '#4E44C6' : '#E1DFDF'}`}
                  text={`${action.value ? `Vedi ${action.label}` : `Crea ${action.label}`}`}
                  fontSize="10px"
                  color={`${action.value ? '#fff' : '#5D5D5D'}`}
                  padding="2px 4px"
                  border=""
                  leftIconHeight="8px"
                />
              </div>
            ))}
          </div>
          <p className={styles.info__title}>Vuoi rifiutare questa richiesta di assistenza?</p>
          <PrimaryButton
            onClick={refuseSupport}
            leftIconSrc={BinIcon}
            text="Rifiuta"
            border="1px solid #D11E1E"
            fontSize="10px"
            color="#D11E1E"
            padding="2px 4px"
            margin="10px 0 0"
            leftIconHeight="10px"
          />
        </div>
      </div>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isCreateReturnModalOpen}
        setIsModalOpen={() => setIsCreateReturnModalOpen(false)}>
        <ReturnAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsCreateReturnModalOpen(false)}
          defaultValues={{
            idOrder: support?.idOrder || '',
            idAlberoOrder: support?.idAlberoOrder || '',
            carrier: support?.carrier || '',
            number: support?.number || '',
            channelCarrier: support?.channelCarrier || '',
            channel: support?.channel || '',
            date: support?.date || null,
            clientName: support?.customer?.name || '',
            customer: {
              email: support?.customer?.email || ''
            }
          }}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isCreateBrokenModalOpen}
        setIsModalOpen={() => setIsCreateBrokenModalOpen(false)}>
        <BrokenAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsCreateBrokenModalOpen(false)}
          defaultValues={{
            idOrder: support?.idOrder || '',
            idAlberoOrder: support?.idAlberoOrder || '',
            carrier: support?.carrier || '',
            number: support?.number || '',
            channel: support?.channel || '',
            date: support?.date || null,
            clientName: support?.customer?.name || '',
            customer: {
              email: support?.customer?.email || ''
            }
          }}
        />
      </Modal>
      <SideModal isModalOpen={isManageFilesModalOpen && activeProduct}>
        <ManageFilesModal
          product={activeProduct?.supportValue?.length && activeProduct?.supportValue[0]}
          readonly={true}
          setIsModalOpen={() => setIsManageFilesModalOpen(false)}
          type={'support'}
        />
      </SideModal>
    </>
  )
: null;
};

export default SupportDetails;
