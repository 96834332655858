import { Api } from 'entries/ApiTransport';
import { objectToQuery } from 'helpers';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import sagaAssessor from '../../../utils/sagaAssessor';
import { getUser } from '../../Auth/store/selectors';
import { brokensActions, brokensTypes } from './actions';

const api = Api.getInstance();

const getBrokensStatuses = ({ _, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get('break/status'));
        yield put(brokensActions.GET_BROKENS_STATUSES.SUCCESS(data));
        callback && typeof callback === 'function' && callback(data);
      },
    (err) => brokensActions.GET_BROKENS_STATUSES.FAILED(err)
  );

const getBrokens = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { params, offset } = payload;
        const query = objectToQuery({params, offset}, { stayBoolean: true });
        const {
          data: { data, has_remaining_breaks }
        } = yield call(() => api.get(`break${query}`));
        yield put(brokensActions.GET_BROKENS.SUCCESS({data, has_remaining_breaks, savePrevious: payload?.savePrevious}));
        callback && typeof callback === 'function' && callback();
      },
    (err) => brokensActions.GET_BROKENS.FAILED(err)
  );

const getBroken = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get(`break/${payload}`));
        yield put(brokensActions.GET_BROKEN.SUCCESS(data));
      },
    (err) => brokensActions.GET_BROKEN.FAILED(err)
  );

const createBroken = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.post('break/create', payload));
        // yield put(brokensActions.CREATE_RETURN.SUCCESS(data));
        callback && typeof callback === 'function' && callback(data.idBreak);
      },
    (err) => brokensActions.CREATE_RETURN.FAILED(err)
  );

const editBroken = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { activity }
        } = yield call(() => api.put('break', payload));
        yield put(
          brokensActions.EDIT_BROKEN.SUCCESS({
            break:
              {
                ...payload.breakObj,
                status: activity.status
              } || {},
            options: payload.property || [],
            products: payload.products || [],
            activity
          })
        );
        callback && typeof callback === 'function' && callback();
      },
    (err) => brokensActions.EDIT_BROKEN.FAILED(err)
  );

const addImageToProduct = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { id, ...rest } = payload;
        yield call(() => api.post('break/image', rest));
        yield put(brokensActions.GET_BROKEN.REQUEST(id));
        callback && typeof callback === 'function' && callback();
      },
    (err) => brokensActions.ADD_IMAGE_TO_PRODUCT_BROKEN.FAILED(err)
  );

const deleteImageFromProduct = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { id, idBrokenProductsImage } = payload;
        yield call(() => api.delete(`break/image/${idBrokenProductsImage}`));
        yield put(brokensActions.GET_BROKEN.REQUEST(id));
        callback && typeof callback === 'function' && callback();
      },
    (err) => brokensActions.DELETE_IMAGE_FROM_PRODUCT_BROKEN.FAILED(err)
  );

const createBrokenMessage = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { message }
        } = yield call(() => api.post('/break/users/chat', payload));
        const user = yield select(getUser());
        yield put(brokensActions.ADD_BROKEN_MESSAGE.SUCCESS({ ...message, user }));
        callback && typeof callback === 'function' && callback();
      },
    (err) => brokensActions.ADD_BROKEN_MESSAGE.FAILED(err)
  );

const deleteBrokenMessage = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const query = objectToQuery(payload);
        const {
          data: { idMessage }
        } = yield call(() => api.delete(`/break/users/chat${query}`));
        yield put(brokensActions.DELETE_BROKEN_MESSAGE.SUCCESS(idMessage));
        callback && typeof callback === 'function' && callback();
      },
    (err) => brokensActions.DELETE_BROKEN_MESSAGE.FAILED(err)
  );

const deleteBroken = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.delete(`break/${payload}`));
        yield put(brokensActions.GET_BROKENS.REQUEST());
        callback && typeof callback === 'function' && callback();
      },
    (err) => brokensActions.DELETE_BROKEN.FAILED(err)
  );

export default function* () {
  yield takeLatest(brokensTypes.GET_BROKENS_STATUSES.REQUEST, getBrokensStatuses);
  yield takeLatest(brokensTypes.GET_BROKENS.REQUEST, getBrokens);
  yield takeLatest(brokensTypes.GET_BROKEN.REQUEST, getBroken);
  yield takeLatest(brokensTypes.CREATE_BROKEN.REQUEST, createBroken);
  yield takeLatest(brokensTypes.EDIT_BROKEN.REQUEST, editBroken);
  yield takeLatest(brokensTypes.ADD_IMAGE_TO_PRODUCT_BROKEN.REQUEST, addImageToProduct);
  yield takeLatest(brokensTypes.DELETE_IMAGE_FROM_PRODUCT_BROKEN.REQUEST, deleteImageFromProduct);
  yield takeLatest(brokensTypes.ADD_BROKEN_MESSAGE.REQUEST, createBrokenMessage);
  yield takeLatest(brokensTypes.DELETE_BROKEN_MESSAGE.REQUEST, deleteBrokenMessage);
  yield takeLatest(brokensTypes.DELETE_BROKEN.REQUEST, deleteBroken);
}
