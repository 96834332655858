import { Api } from 'entries/ApiTransport';
import { objectToQuery } from 'helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import sagaAssessor from 'utils/sagaAssessor';

import { productsActions, productsTypes } from './actions';

const api = Api.getInstance();

const getProducts = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get(`products?offset=${payload?.offset || 0}`));
        yield put(
          productsActions.GET_PRODUCTS.SUCCESS({
            savePrevious: payload?.savePrevious,
            data
          })
        );
      },
    (err) => productsActions.GET_PRODUCTS.FAILED(err)
  );

const getProduct = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get(`products/${payload}`));
        yield put(productsActions.GET_PRODUCT.SUCCESS(data));
      },
    (err) => productsActions.GET_PRODUCT.FAILED(err)
  );

const editProduct = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const { productId, ...request } = payload;
        yield call(() => api.put(`products/${productId}`, request));
        yield put(productsActions.EDIT_PRODUCT.SUCCESS(request));
      },
    (err) => productsActions.EDIT_PRODUCT.FAILED(err)
  );

const searchProducts = ({ payload, callback }) => {
  const { noLimit, withLoading, ...rest } = payload;

  return sagaAssessor(
    () =>
      function* () {
        const query = objectToQuery(rest);
        const {
          data: { data }
        } = yield call(() => api.get(`products/info/search${query}${noLimit ? '&limit=no' : ''}`));
        yield put(
          productsActions.SEARCH_PRODUCTS.SUCCESS({
            products: data.ricerca,
            query: rest.query,
            categories: data.categories
          })
        );
        typeof callback === 'function' && callback();
      },
    (err) => productsActions.SEARCH_PRODUCTS.FAILED(err),
    !!withLoading
  );
};

export default function* () {
  yield takeLatest(productsTypes.GET_PRODUCTS.REQUEST, getProducts);
  yield takeLatest(productsTypes.GET_PRODUCT.REQUEST, getProduct);
  yield takeLatest(productsTypes.EDIT_PRODUCT.REQUEST, editProduct);
  yield takeLatest(productsTypes.SEARCH_PRODUCTS.REQUEST, searchProducts);
}
