import { Api } from 'entries/ApiTransport';
import { objectToQuery } from 'helpers';
import { call, put, takeLatest } from 'redux-saga/effects';

import sagaAssessor from '../../../utils/sagaAssessor';
import { ordersActions, ordersTypes } from './actions';

const api = Api.getInstance();

const getOrders = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { offset, params } = payload;
        const query = objectToQuery({ ...params, offset: offset || 0 }, { stayBoolean: true });
        const { data } = yield call(() => api.get(`orders${query}`));
        yield put(
          ordersActions.GET_ORDERS.SUCCESS({
            savePrevious: payload?.savePrevious,
            data: data.orders,
            has_remaining_orders: data.has_remaining_orders
          })
        );
        typeof callback === 'function' && callback();
      },
    (err) => ordersActions.GET_ORDERS.FAILED(err)
  );

const searchOrders = ({ payload, callback }) => {
  const { noLimit, withLoading, ...rest } = payload;

  return sagaAssessor(
    () =>
      function* () {
        const query = objectToQuery(rest);
        const {
          data: { orders }
        } = yield call(() => api.get(`orders/info/search${query}${noLimit ? '&limit=no' : ''}`));
        yield put(ordersActions.SEARCH_ORDERS.SUCCESS({ orders, query: rest.query }));
        typeof callback === 'function' && callback(orders);
      },
    (err) => ordersActions.SEARCH_ORDERS.FAILED(err),
    !!withLoading
  );
};

const getOrder = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`orders/${payload}`));
        yield put(ordersActions.GET_ORDER.SUCCESS(data.order));
        typeof callback === 'function' && callback();
      },
    (err) => ordersActions.GET_ORDER.FAILED(err)
  );
const getShipping = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`/estimate?idOrder=${payload}`));
        yield put(ordersActions.GET_SHIPPING.SUCCESS(data));
        typeof callback === 'function' && callback();
      },
    (err) => ordersActions.GET_SHIPPING.FAILED({ message: err })
  );
const createOrder = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`/scrapeById?id=${payload}`));
        yield put(ordersActions.CREATE_ORDER.SUCCESS(data));
        typeof callback === 'function' && callback();
      },
    (err) => ordersActions.CREATE_ORDER.FAILED({ message: err })
  );
const editChannel = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.patch('orders/channel', payload));
        yield put(ordersActions.GET_ORDER.SUCCESS(data.order));
        typeof callback === 'function' && callback();
      },
    (err) => ordersActions.EDIT_CHANNEL.FAILED({ message: err })
  );

export default function* () {
  yield takeLatest(ordersTypes.GET_ORDERS.REQUEST, getOrders);
  yield takeLatest(ordersTypes.CREATE_ORDER.REQUEST, createOrder);

  yield takeLatest(ordersTypes.SEARCH_ORDERS.REQUEST, searchOrders);
  yield takeLatest(ordersTypes.GET_ORDER.REQUEST, getOrder);
  yield takeLatest(ordersTypes.GET_SHIPPING.REQUEST, getShipping);

  yield takeLatest(ordersTypes.EDIT_CHANNEL.REQUEST, editChannel);
}
