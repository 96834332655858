import { createSelector } from 'reselect';

const selectSupportsState = (state) => state.supportsReducer;

export const selectSupports = () => createSelector(selectSupportsState, (state) => state.supports);
export const selectFilterFields = () => createSelector(selectSupportsState, (state) => state.filterFields);
export const selectStatuses = () => createSelector(selectSupportsState, (state) => state.statuses);
export const selectSupport = () => createSelector(selectSupportsState, (state) => state.activeSupport);
// export const selectAlberoOrderById = (id) => createSelector(selectSupportsState, (state) =>
//   state.orders.find(order => +order.idAlberoOrder === id));

