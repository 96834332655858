import { TABLE_KEYS } from 'Containers/Common/DataTables/constants';
import InputWithDebounce from 'Containers/Common/InputWithDebounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import useWindowSize from 'utils/hooks/useWindowSize';

import TableHeader from '../../../../Common/DataTables/containers/TableHeader/TableHeader';
import Modal from '../../../../Common/Modal';
import ClientsTable from '../../../../Tables/ClientsTable';
import { userActions } from '../../../store';
import { selectClientById, selectClients } from '../../../store/selectors';
import ClientAddAndEditModal from '../ClientAddAndEditModal';
import styles from './ClientsList.module.scss';

export default () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const clientsList = useSelector(selectClients());
  const clientToEdit = useSelector(selectClientById(searchParams.get(`${TABLE_KEYS.CLIENTS}Edit`)));
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);

  const handleReset = () => {
    dispatch(userActions.GET_ALL_CLIENTS.REQUEST());
  };

  useEffect(() => {
    if (!state?.withoutRefreshItems) {
      dispatch(userActions.GET_ALL_CLIENTS.REQUEST());
    }
  }, [dispatch]);

  return (
    <div className={styles.container__inner}>
      <TableHeader
        title="Cliente"
        buttonText="Aggiungi Cliente"
        showAddButton={false}
        action={() => setIsAddClientModalOpen(true)}
        showSearchButton={true}
        searchComponent={
          <InputWithDebounce
            onReset={handleReset}
            onChange={(inputValue) => {
              dispatch(userActions.SEARCH_ALL_CLIENTS.REQUEST(inputValue));
            }}
          />
        }
      />
      <ClientsTable clientsList={clientsList} />
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isAddClientModalOpen}
        setIsModalOpen={() => setIsAddClientModalOpen(false)}>
        <ClientAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsAddClientModalOpen(false)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={clientToEdit}
        setIsModalOpen={() => setSearchParams('')}>
        <ClientAddAndEditModal
          isEditForm={true}
          clientToEdit={clientToEdit}
          setIsModalOpen={() => setSearchParams('')}
        />
      </Modal>
    </div>
  );
};
