import FileIcon from '../../../../../../../assets/svg/doc.svg';
import { checkInaccessibleFile } from '../../../../../../../helpers';
import PDFViewer from '../../../../../PDFViewer';
import styles from './PreviewFile.module.scss';

const PreviewsList = ({
  filesInfo,
  setIsFileFullSize,
  isFileFullSize,
  setFilesInfo,
  isEditForm = false
}) => (
  <div className={styles.preview__wrapper}>
    <div className={styles['uploaded-files']}>
      {filesInfo?.map((file, idx) => {
        const { alreadyAdded, fileType } = checkInaccessibleFile(file?.url);
        return (
          <div key={idx} className={styles['uploaded-file__wrapper']}>
            <div className={styles['uploaded-file__header']}>
              <span
                className="checked__btn"
                onClick={() => {
                  if (isEditForm) {
                    setFilesInfo((prevState) => [...prevState, file?.id]);
                  } else {
                    const filteredFiles = filesInfo?.filter((file, i) => i !== idx);
                    setFilesInfo(filteredFiles || []);
                    if (isFileFullSize === file?.url) {
                      setIsFileFullSize(false);
                    }
                  }
                }}
              />
            </div>
            {fileType === 'pdf' && !alreadyAdded
? (
              <PDFViewer pageWidth={200} height="100px" pdf={file?.url} />
            )
: fileType === 'pdf' && alreadyAdded
? (
              <iframe src={file?.url} />
            )
: fileType === 'docx' || fileType === 'doc'
? (
              <img src={FileIcon} alt="file" className={styles['uploaded-file__app']} />
            )
: (
              <img src={file?.url} alt="file" className={styles['uploaded-file']} />
            )}
            <div className={styles['uploaded-file__footer']}>
              <span className={styles['footer__type']}>{file.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default PreviewsList;
