import { call, put, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { authActions, authTypes } from './actions';

const api = Api.getInstance();

const signIn = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('user/login', payload));
        localStorage.setItem('jwtToken', data.token);
        yield put(authActions.SIGN_IN.SUCCESS());
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.SIGN_IN.FAILED(err)
  );

const getUser = ({ _, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get('user/me'));
        yield put(authActions.FETCH_USER.SUCCESS(data.user));
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.FETCH_USER.FAILED(err)
  );

const logOut = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        localStorage.removeItem('jwtToken');
        Object.keys(localStorage)
          .filter(
            (item) =>
              item.includes('searchText') ||
              item.includes('filterList') ||
              item.includes('sortingOptions')
          )
          .forEach((item) => {
            localStorage.removeItem(`${item}`);
          });
        yield put(authActions.LOG_OUT.SUCCESS());
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.LOG_OUT.FAILED(err)
  );

export default function* () {
  yield takeLatest(authTypes.SIGN_IN.REQUEST, signIn);
  yield takeLatest(authTypes.FETCH_USER.REQUEST, getUser);
  yield takeLatest(authTypes.LOG_OUT.REQUEST, logOut);
}
