import { all } from 'redux-saga/effects';

import { authSaga } from '../Containers/Auth/store/';
import { brokensSaga } from '../Containers/Brokens/store';
import { invoicesSaga } from '../Containers/Invoices/store';
import { ordersSaga } from '../Containers/Orders/store';
import { productsSaga } from '../Containers/Products/store';
import { returnsSaga } from '../Containers/Returns/store';
import { settingsSaga } from '../Containers/Settings/store';
import { supportsSaga } from '../Containers/Supports/store';
import { usersSaga } from '../Containers/Users/store';

export default function* rootSaga() {
  yield all([
    authSaga(),
    usersSaga(),
    returnsSaga(),
    ordersSaga(),
    supportsSaga(),
    settingsSaga(),
    productsSaga(),
    invoicesSaga(),
    brokensSaga()
  ]);
}
