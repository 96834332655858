import PrimaryButton from 'Containers/Common/Buttons/PrimaryButton';
import { Card } from 'Containers/Common/Card';
import TextField from 'Containers/Common/TextField';
import { selectEstimation } from 'Containers/Settings/store/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { settingsActions } from '../../store';
import styles from './Estimation.module.scss';

export default () => {
  const dispatch = useDispatch();
  const estimation = useSelector(selectEstimation());
  const [estimationInput, setEstimationInput] = useState(0);
  useEffect(() => {
    dispatch(settingsActions.GET_ESTIMATION.REQUEST());
  }, [dispatch]);
  useEffect(() => {
    setEstimationInput(estimation);
  }, [estimation]);
  return (
    <div className={styles.container}>
      <Card title="Scarto">
        <div className={styles.body}>
          <TextField
            borderRadius="4px"
            height="auto"
            type="number"
            value={estimationInput}
            onChange={(e) => setEstimationInput(e.target.value)}
          />
          <PrimaryButton
            text={'Salva'}
            onClick={() => dispatch(settingsActions.SET_ESTIMATION.REQUEST(estimationInput))}
          />
        </div>
      </Card>
    </div>
  );
};
