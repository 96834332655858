export const STATUSES = [
  {
    label: 'Disabilitato',
    value: '1'
  },
  {
    label: 'Attivo',
    value: '0'
  }
];
