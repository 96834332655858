import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FilterModal from 'Containers/Brokens/containers/BrokensList/containers/FilterModal';
import TextField from 'Containers/Common/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useWindowSize from 'utils/hooks/useWindowSize';

import { TABLE_KEYS } from '../../../Common/DataTables/constants';
import TableHeader from '../../../Common/DataTables/containers/TableHeader/TableHeader';
import Modal from '../../../Common/Modal';
import BrokensTable from '../../../Tables/BrokensTable';
import { brokensActions } from '../../store';
import styles from './BrokensList.module.scss';
import BrokenAddAndEditModal from './containers/BrokenAddAndEditModal';
import StatusHeader from './containers/StatusHeader';

export default ({ brokens, brokenStatuses, selectedStatus, setSelectedStatus }) => {
  const { statusId } = useParams();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [isAddBrokenModalOpen, setIsAddBrokenModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      brokensActions.GET_BROKENS_STATUSES.REQUEST(null, (data) =>
        setSelectedStatus(data?.find((status) => +status?.idBreakStatus === +statusId))
      )
    );
    dispatch(brokensActions.GET_BROKENS.REQUEST());
  }, [dispatch]);

  return (
    <div className={styles.container__inner}>
      <StatusHeader
        brokenStatuses={brokenStatuses}
        value={selectedStatus}
        setValue={setSelectedStatus}
      />
      <TableHeader
        title="Rotture"
        buttonText="Aggiungi Rottura"
        showAddButton={true}
        action={() => setIsAddBrokenModalOpen(true)}
        showFilterButton={true}
        filterAction={() => setIsFilterModalOpen(true)}
        showSearchButton={true}
        searchComponent={
          <TextField
            placeholder="Cerco..."
            width="200px"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            iconComponent={
              <>
                {searchText && (
                  <div onClick={() => setSearchText('')}>
                    <CloseIcon style={{ margin: '0' }} />
                  </div>
                )}
                <SearchIcon style={{ margin: '0 10px' }} />
              </>
            }
          />
        }
      />
      <BrokensTable searchText={searchText} brokensList={brokens} tableKey={TABLE_KEYS.BROKENS} />
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isAddBrokenModalOpen}
        setIsModalOpen={() => setIsAddBrokenModalOpen(false)}>
        <BrokenAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsAddBrokenModalOpen(false)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isFilterModalOpen}
        setIsModalOpen={() => setIsFilterModalOpen(false)}>
        <FilterModal closeModal={() => setIsFilterModalOpen(false)} />
      </Modal>
    </div>
  );
};
