import { userTypes } from './actions';

const initialState = {
  clients: [],
  users: [],
  activeClient: {},
  activeUser: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userTypes.GET_ALL_CLIENTS.SUCCESS:
      const { savePrevious, data } = action.payload;
      return {
        ...state,
        clients: savePrevious ? [...state.clients, ...data] : data
      };
    case userTypes.GET_CLIENT.SUCCESS:
      return {
        ...state,
        activeClient: action.payload
      };
    case userTypes.ADD_CLIENT.SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload]
      };
    case userTypes.EDIT_CLIENT.SUCCESS:
      return {
        ...state,
        clients: [
          ...state.clients.filter((cli) => +cli.idAzienda !== +action.payload.idAzienda),
          action.payload
        ],
        activeClient: {
          ...state.activeClient,
          infoCliente: action.payload
        }
      };
    case userTypes.GET_ALL_USERS.SUCCESS:
      return {
        ...state,
        users: action.payload
      };
    case userTypes.ADD_USER.SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload]
      };
    case userTypes.EDIT_USER.SUCCESS:
      return {
        ...state,
        users: [
          ...state.users.filter((user) => +user.idUser !== +action.payload.idUser),
          action.payload
        ],
        activeUser: {
          ...state.activeUser,
          infoUtente: action.payload
        }
      };
    case userTypes.SEARCH_CLIENTS.SUCCESS:
    case userTypes.SEARCH_ALL_CLIENTS.SUCCESS:
      return {
        ...state,
        clients: action.payload
      };
    default:
      return { ...state };
  }
};
