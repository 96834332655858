import clx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from 'utils/hooks/useWindowSize';

import LogoImage from '../../../assets/images/logo.svg';
import Loader from '../../../shared/Loader/Loader';
import { authActions } from '../../Auth/store';
import { getAuth } from '../../Auth/store/selectors';
import SideBar from '../../SideBar';
import styles from './PageWrapper.module.scss';

const PageWrapper = ({ children }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const [isCollapsedSideBar, setIsCollapsedSideBar] = useState(false);
  const isAuthenticated = useSelector(getAuth());

  useEffect(() => {
    isAuthenticated && dispatch(authActions.FETCH_USER.REQUEST());
  }, [dispatch]);

  return (
    <div className={clx(styles['page-wrapper'], { [styles.section_mbl]: isMobile })}>
      <Loader />
      <SideBar
        isMobile={isMobile}
        isCollapsedSideBar={isCollapsedSideBar}
        setIsCollapsedSideBar={setIsCollapsedSideBar}
      />
      <div className={styles.section__wrapper}>
        {/* {isCollapsedSideBar
? (
          <div className={styles.banner}>
            <div className={styles.banner__logo}>
              <img src={LogoImage} />
            </div>
          </div>
        )
: null} */}
        <section>{children}</section>
      </div>
    </div>
  );
};

export default PageWrapper;
