import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectFilteredReturns, selectReturnFilterStatuses } from '../../store/selectors';
import { ReturnsList } from '../index';

export default () => {
  const returnStatuses = useSelector(selectReturnFilterStatuses(true));
  const [selectedStatus, setSelectedStatus] = useState(returnStatuses[0]);

  return (
    <ReturnsList
      archived={true}
      returnStatuses={returnStatuses}
      selectedStatus={selectedStatus}
      setSelectedStatus={setSelectedStatus}
    />
  );
};
