import { productsActions } from 'Containers/Products/store';
import { selectProductsQuery } from 'Containers/Products/store/selectors';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { usePrevious } from 'utils/hooks/usePrevious';

import { DataTableCustomized } from '../../Common/DataTables';
import styles from './ProductsTable.module.scss';

export default ({
  productsList,
  tableName,
  tableKey,
  paginationOption = true,
  isDetails = true,
  isDelete = false,
  isEdit = false
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const prevList = usePrevious(productsList);

  const productsQuery = useSelector(selectProductsQuery());

  const [offset, setOffset] = useState(state?.offset || 50);

  const tableColumns = useMemo(
    () => [
      {
        name: 'sku',
        label: 'SKU Prodotto',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'name',
        label: 'Nome prodotto',
        filter: false,
        sort: true,
        centered: false
      },
      {
        name: 'totalOrders',
        label: 'Numeri ordine',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'totalReturns',
        label: 'Numero Resi',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'totalBreaks',
        label: 'Numero Rotture',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'totalError',
        label: 'Numero Errori',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'packageType',
        label: 'Tipo Imballaggio',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <div className={styles.type}>{data?.option?.value || '--'}</div>
      }
    ],
    []
  );

  const fetchNewItems = async () => {
    const nextOffset = offset + 50;
    setOffset(nextOffset);
    await dispatch(
      productsActions.GET_PRODUCTS.REQUEST({ offset: nextOffset, savePrevious: true })
    );
  };

  return (
    <div>
      <DataTableCustomized
        tableData={productsList?.map((product) => ({
          id: product?.idProduct,
          sku: product?.sku,
          name: product?.name,
          totalOrders: product?.totalOrders || 0,
          totalReturns: product?.totalReturns || 0,
          totalBreaks: product?.totalBreaks || 0,
          totalError: product?.totalError || 0,
          packageType: product?.packageTypeCategory
        }))}
        tableName={tableName}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        tableKey={tableKey}
        detailsLink={ROUTES.PRODUCTS}
        isDetails={isDetails}
        isDelete={isDelete}
        offset={offset}
        blockFetchPagination={productsList?.length === prevList?.length || !!productsQuery}
        isEdit={isEdit}
        fetchNew={fetchNewItems}
      />
    </div>
  );
};
