import * as yup from 'yup';

export const initialValues = {
  searchString: '',
  activeOrder: {
    idAlberoOrder: '',
    CorriereDiAndata: '',
    channel: '',
    date: null,
    clientName: '',
    customer: {
      email: ''
    }
  },
  date: null,
  ldv: '',
  returnCarrier: '',
  carrier: '',
  channel: ''
};

export const validationCreateSchema = yup.object().shape({
  activeOrder: yup.object().shape({
    idOrder: yup.number().required('Obbligatorio')
  }),
  returnCarrier: yup.string().required('Obbligatorio'),
  date: yup.date().required('Obbligatorio'),
});

export const validationEditSchema = yup.object().shape({
  returnCarrier: yup.string().required('Obbligatorio'),
  carrier: yup.string().required('Obbligatorio'),
  channel: yup.string().required('Obbligatorio'),
});
