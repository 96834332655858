import { Api } from 'entries/ApiTransport';
import { objectToQuery } from 'helpers';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import sagaAssessor from '../../../utils/sagaAssessor';
import { getUser } from '../../Auth/store/selectors';
import { returnsActions, returnsTypes } from './actions';

const api = Api.getInstance();

const getReturnsStatuses = ({ _, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get('returns/status'));
        yield put(returnsActions.GET_RETURNS_STATUSES.SUCCESS(data));
        callback && typeof callback === 'function' && callback(data);
      },
    (err) => returnsActions.GET_RETURNS_STATUSES.FAILED(err)
  );

const getCountReturns = ({ _, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get('count/returns'));
        yield put(returnsActions.GET_COUNT_RETURNS.SUCCESS(data));
        callback && typeof callback === 'function' && callback(data);
      },
    (err) => returnsActions.GET_COUNT_RETURNS.FAILED(err)
  );

const getReturns = ({ payload = {}, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { offset, params } = payload;
        const {
          data: { data, has_remaining_returns }
        } = yield call(() => api.get('returns', { params: {...params, offset} }));
        yield put(returnsActions.GET_RETURNS.SUCCESS({
          savePrevious: payload?.savePrevious,
          data, has_remaining_returns}));
        callback && typeof callback === 'function' && callback();
      },
    (err) => returnsActions.GET_RETURNS.FAILED(err)
  );

const getCarriers = () =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get('carriers'));
        yield put(returnsActions.GET_CARRIERS.SUCCESS(data.carriers));
      },
    (err) => returnsActions.GET_CARRIERS.FAILED(err)
  );

const getReturn = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get(`returns/${payload}`));
        yield put(returnsActions.GET_RETURN.SUCCESS(data));
      },
    (err) => returnsActions.GET_RETURN.FAILED(err)
  );

const createReturn = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.post('returns/create', payload));
        // yield put(returnsActions.CREATE_RETURN.SUCCESS(data));
        callback && typeof callback === 'function' && callback(data.idReturn);
      },
    (err) => returnsActions.CREATE_RETURN.FAILED(err)
  );

const editReturn = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { activity }
        } = yield call(() => api.put('returns', payload));
        yield put(
          returnsActions.EDIT_RETURN.SUCCESS({
            return:
              {
                ...payload.returnObj,
                status: activity.status
              } || {},
            options: payload.property || [],
            products: payload.products || [],
            activity
          })
        );
        yield put(returnsActions.GET_RETURN.REQUEST(payload.returnObj.idReturn));
        callback && typeof callback === 'function' && callback();
      },
    (err) => returnsActions.EDIT_RETURN.FAILED(err)
  );

const addImageToProduct = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { id, ...rest } = payload;
        yield call(() => api.post('returns/image', rest));
        yield put(returnsActions.GET_RETURN.REQUEST(id));
        callback && typeof callback === 'function' && callback();
      },
    (err) => returnsActions.ADD_IMAGE_TO_PRODUCT_RETURN.FAILED(err)
  );

const deleteImageFromProduct = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { id, idReturnProductsImage } = payload;
        yield call(() => api.delete(`return/image/${idReturnProductsImage}`));
        yield put(returnsActions.GET_RETURN.REQUEST(id));
        callback && typeof callback === 'function' && callback();
      },
    (err) => returnsActions.DELETE_IMAGE_FROM_PRODUCT_RETURN.FAILED(err)
  );

const addReturnMessage = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { message }
        } = yield call(() => api.post('/returns/users/chat', payload));
        const user = yield select(getUser());
        yield put(returnsActions.ADD_RETURN_MESSAGE.SUCCESS({ ...message, user }));
        callback && typeof callback === 'function' && callback();
      },
    (err) => returnsActions.ADD_RETURN_MESSAGE.FAILED(err)
  );

const deleteReturnMessage = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const query = objectToQuery(payload);
        const {
          data: { idMessage }
        } = yield call(() => api.delete(`/returns/users/chat${query}`));
        yield put(returnsActions.DELETE_RETURN_MESSAGE.SUCCESS(idMessage));
        callback && typeof callback === 'function' && callback();
      },
    (err) => returnsActions.DELETE_RETURN_MESSAGE.FAILED(err)
  );

const deleteReturn = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.delete(`returns/${payload}`));
        yield put(returnsActions.GET_RETURNS.REQUEST());
        callback && typeof callback === 'function' && callback();
      },
    (err) => returnsActions.DELETE_RETURN.FAILED(err)
  );

const getReasons = () =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get('category/5'));
        yield put(returnsActions.GET_REASONS.SUCCESS(data.data.options));
      },
    (err) => returnsActions.GET_REASONS.FAILED(err)
  );

export default function* () {
  yield takeLatest(returnsTypes.GET_RETURNS_STATUSES.REQUEST, getReturnsStatuses);
  yield takeLatest(returnsTypes.GET_RETURNS.REQUEST, getReturns);
  yield takeLatest(returnsTypes.GET_CARRIERS.REQUEST, getCarriers);
  yield takeLatest(returnsTypes.GET_RETURN.REQUEST, getReturn);
  yield takeLatest(returnsTypes.CREATE_RETURN.REQUEST, createReturn);
  yield takeLatest(returnsTypes.EDIT_RETURN.REQUEST, editReturn);
  yield takeLatest(returnsTypes.ADD_IMAGE_TO_PRODUCT_RETURN.REQUEST, addImageToProduct);
  yield takeLatest(returnsTypes.DELETE_IMAGE_FROM_PRODUCT_RETURN.REQUEST, deleteImageFromProduct);
  yield takeLatest(returnsTypes.ADD_RETURN_MESSAGE.REQUEST, addReturnMessage);
  yield takeLatest(returnsTypes.DELETE_RETURN_MESSAGE.REQUEST, deleteReturnMessage);
  yield takeLatest(returnsTypes.DELETE_RETURN.REQUEST, deleteReturn);
  yield takeLatest(returnsTypes.GET_REASONS.REQUEST, getReasons);
  yield takeLatest(returnsTypes.GET_COUNT_RETURNS.REQUEST, getCountReturns);
}
