import * as yup from 'yup';

export const initialValues = {
  searchString: '',
  activeOrder: {
    idAlberoOrder: '',
    CorriereDiAndata: '',
    channel: '',
    date: null,
    clientName: '',
    customer: {
      email: ''
    }
  },
  date: null,
  packagesNumber: '',
  shipmentWeight: '',
  totalOrder: '',
  contrassegno: false
};

export const validationCreateSchema = yup.object().shape({
  activeOrder: yup.object().shape({
    idOrder: yup.number().required('Obbligatorio')
  }),
  date: yup.date().required('Obbligatorio'),
});

export const validationEditSchema = yup.object().shape({
  date: yup.date().required('Obbligatorio'),
  packagesNumber: yup.number().typeError('Must be a number'),
  shipmentWeight: yup.number().typeError('Must be a number'),
  totalOrder: yup.number().typeError('Must be a number'),
});
