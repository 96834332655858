import FilterModal from 'Containers/Orders/containers/OrdersList/containers/FilterModal';
import OrderHeader from 'Containers/Orders/containers/OrdersList/containers/OrderHeader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import useWindowSize from 'utils/hooks/useWindowSize';

import BrokenAddAndEditModal from '../../../Brokens/containers/BrokensList/containers/BrokenAddAndEditModal';
import { TABLE_KEYS } from '../../../Common/DataTables/constants';
import Modal from '../../../Common/Modal';
import ReturnAddAndEditModal from '../../../Returns/containers/ReturnsList/containers/ReturnAddAndEditModal';
import OrdersTable from '../../../Tables/OrdersTable';
import { ordersActions } from '../../store';
import { selectAlberoOrderById, selectFilterFields, selectHasRemainingOrders, selectOrders } from '../../store/selectors';

const OrdersList = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { isMobile } = useWindowSize();
  const filterFields = useSelector(selectFilterFields());

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const orders = useSelector(selectOrders());
  const pathname = window.location.pathname;
  const activeReturnOrder = useSelector(
    selectAlberoOrderById(+searchParams.get(`${TABLE_KEYS.ORDERS}AddReturn`))
  );
  const activeBrokenOrder = useSelector(
    selectAlberoOrderById(+searchParams.get(`${TABLE_KEYS.ORDERS}AddBroken`))
  );

  useEffect(() => {
    if (!state?.withoutRefreshItems) {
      dispatch(ordersActions.GET_ORDERS.REQUEST({
        params: { canceled: pathname.includes('canceled')},
      }));
    }
    dispatch(ordersActions.SET_FILTER_FIELDS.SUCCESS({
      ...filterFields,
      canceled: pathname.includes('canceled')
    }));
  }, [pathname]);

  return (
    <div>
      <OrderHeader openFilters={() => setIsFilterModalOpen(true)}/>
      <OrdersTable tableKey={TABLE_KEYS.ORDERS} ordersList={orders}/>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={activeReturnOrder}
        setIsModalOpen={() => setSearchParams('')}>
        <ReturnAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setSearchParams('')}
          defaultValues={{
            idOrder: activeReturnOrder?.idOrder || '',
            idAlberoOrder: activeReturnOrder?.idAlberoOrder || '',
            CorriereDiAndata: activeReturnOrder?.CorriereDiAndata || '',
            channelCarrier: activeReturnOrder?.channelCarrier || '',
            channel: activeReturnOrder?.channel || '',
            date: activeReturnOrder?.date || null,
            clientName: activeReturnOrder?.customer?.name || '',
            customer: {
              email: activeReturnOrder?.customer?.email || ''
            }
          }}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={activeBrokenOrder}
        setIsModalOpen={() => setSearchParams('')}>
        <BrokenAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setSearchParams('')}
          defaultValues={{
            idOrder: activeBrokenOrder?.idOrder || '',
            idAlberoOrder: activeBrokenOrder?.idAlberoOrder || '',
            CorriereDiAndata: activeBrokenOrder?.CorriereDiAndata || '',
            channelCarrier: activeBrokenOrder?.channelCarrier || '',
            channel: activeBrokenOrder?.channel || '',
            date: activeBrokenOrder?.date || null,
            clientName: activeBrokenOrder?.customer?.name || '',
            customer: {
              email: activeBrokenOrder?.customer?.email || ''
            }
          }}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isFilterModalOpen}
        setIsModalOpen={() => setIsFilterModalOpen(false)}>
        <FilterModal closeModal={() => setIsFilterModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OrdersList;
