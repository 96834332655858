import actionCreator from '../../../utils/actionCreator';

const ordersActionTypes = [
  'GET_ORDERS',
  'CREATE_ORDER',
  'SEARCH_ORDERS',
  'GET_ORDER',
  'GET_SHIPPING',
  'SET_FILTER_FIELDS',
  'EDIT_CHANNEL',
];

export const { types: ordersTypes, actions: ordersActions } = actionCreator(ordersActionTypes);
