import ArrowIcon from 'assets/svg/arrow-left-blue.svg';
import clx from 'classnames';
import PrimaryButton from 'Containers/Common/Buttons/PrimaryButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import useWindowSize from 'utils/hooks/useWindowSize';

import BrokenAddAndEditModal from '../../../Brokens/containers/BrokensList/containers/BrokenAddAndEditModal';
import { TABLE_KEYS } from '../../../Common/DataTables/constants';
import Modal from '../../../Common/Modal';
import TextFieldMockup from '../../../Common/TextFieldMockup';
import ReturnAddAndEditModal from '../../../Returns/containers/ReturnsList/containers/ReturnAddAndEditModal';
import OrdersTable from '../../../Tables/OrdersTable';
import { productsActions } from '../../store';
import {
  selectActiveProduct,
  selectProductCategories,
  selectProductOrderById
} from '../../store/selectors';
import { FILTER_TYPES } from './constants';
import styles from './ProductDetails.module.scss';

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { isMobile } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(FILTER_TYPES[0].key);

  const activeReturnOrder = useSelector(
    selectProductOrderById(+searchParams.get(`${TABLE_KEYS.ORDERS}AddReturn`))
  );

  const activeBrokenOrder = useSelector(
    selectProductOrderById(+searchParams.get(`${TABLE_KEYS.ORDERS}AddBroken`))
  );
  useEffect(() => {
    window.history.replaceState({}, document.title);
    dispatch(productsActions.GET_PRODUCT.REQUEST(productId));
  }, [dispatch]);

  const activeProduct = useSelector(selectActiveProduct());
  const productCategories = useSelector(selectProductCategories());
  const activeCategories = [
    {
      key: 'packageType',
      id: 13
    }
  ];

  const filteredOrders = activeProduct?.productOrders?.reduce(
    (acc, item) => {
      FILTER_TYPES.filter((type) => item[type.key]).forEach(({ key }) => acc[key].push(item));
      return acc;
    },
    {
      all: activeProduct?.productOrders,
      return: [],
      break: [],
      error: []
    }
  );

  return (
    <>
      {location.state?.page >= 0 ? (
        <PrimaryButton
          height="34px"
          fontSize="13px"
          width="100px"
          padding="8px 10px"
          margin="0 0 20px 0"
          borderRadius="40px"
          text={'Indietro'}
          leftIconSrc={ArrowIcon}
          onClick={() =>
            navigate(location.state?.pathname, {
              state: {
                page: location.state?.page || 0,
                offset: location.state?.offset || 0,
                withoutRefreshItems: true
              }
            })
          }
        />
      ) : null}
      <div className={clx(styles['container-inner'], { [styles.container_mbl]: isMobile })}>
        <div className={styles.info}>
          <div className={styles['block-wrapper']}>
            <p className={styles.info__title}>Info prodotto</p>
            <div className={styles.img_info}>
              <div className={styles.img_wrapper}>
                <img
                  className={styles.product_image}
                  src={activeProduct?.image}
                  alt="product_img"
                />
              </div>
              <div className={styles.info__body}>
                {productCategories.map((category) => {
                  const activeCategory = activeCategories.find(
                    (item) => +item.id === +category.idCategory
                  );
                  const activeOptions = category?.options?.map(({ idOption, value }) => ({
                    value: idOption,
                    label: value
                  }));
                  const activeOption = activeOptions.find(
                    (status) => +activeProduct[activeCategory.key] === +status.value
                  );
                  return (
                    <div
                      key={`${category.idCategory}${productId}`}
                      className={styles.select_wrapper}>
                      <div className={styles.select_label}>{category.title}</div>
                      <Select
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        onChange={(data) => {
                          const request = {
                            productId
                          };
                          request[activeCategory.key] = data.value;
                          dispatch(productsActions.EDIT_PRODUCT.REQUEST(request));
                        }}
                        styles={{
                          menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                          control: (provided) => {
                            const activeColor = category.options.find(
                              (status) => activeProduct[activeCategory.key] === +status.idOption
                            )?.color;
                            return {
                              ...provided,
                              borderRadius: '10px',
                              marginLeft: '1px',
                              backgroundColor: activeColor || 'transparent',
                              minHeight: '36px',
                              height: '36px'
                            };
                          },
                          input: (provided) => ({
                            ...provided,
                            color: 'white!important',
                            margin: '0px',
                            input: { fontSize: '16px' }
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: 'white',
                            padding: '2px 0'
                          }),
                          indicatorsContainer: (provided) => ({
                            ...provided,
                            height: '36px'
                          })
                        }}
                        value={activeOption}
                        options={activeOptions}
                      />
                    </div>
                  );
                })}
                <TextFieldMockup
                  maxWidth={'275px'}
                  value={`${activeProduct?.idAlberoProduct}`}
                  label={'ID Prodotto'}
                />
                <div
                  style={{
                    display: 'flex'
                  }}>
                  <TextFieldMockup
                    maxWidth={'275px'}
                    value={activeProduct?.name}
                    label={'Product name'}
                    textarea={true}
                  />
                </div>
                <TextFieldMockup maxWidth={'275px'} value={activeProduct?.sku} label={'Sku'} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.orders}>
          <p className={styles.orders__title}>Lista ordine prodotto</p>
          <div className={styles.orders__filter}>
            {FILTER_TYPES.map((i) => (
              <div
                key={i.key}
                className={clx(styles.orders__filter__item, {
                  [styles.orders__filter__item_active]: filter === i.key
                })}
                onClick={() => setFilter(i.key)}>
                {i.name}
                <div className={styles.orders__filter__item__count}>
                  {filteredOrders[i.key].length}
                </div>
              </div>
            ))}
          </div>
          <OrdersTable tableKey={TABLE_KEYS.ORDERS} ordersList={filteredOrders[filter]} />
        </div>
      </div>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={activeReturnOrder}
        setIsModalOpen={() => setSearchParams('')}>
        <ReturnAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setSearchParams('')}
          defaultValues={{
            idOrder: activeReturnOrder?.idOrder || '',
            idAlberoOrder: activeReturnOrder?.idAlberoOrder || '',
            CorriereDiAndata: activeReturnOrder?.CorriereDiAndata || '',
            channelCarrier: activeReturnOrder?.channelCarrier || '',
            channel: activeReturnOrder?.channel || '',
            date: activeReturnOrder?.date || null,
            clientName: activeReturnOrder?.customer?.name || '',
            customer: {
              email: activeReturnOrder?.customer?.email || ''
            }
          }}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={activeBrokenOrder}
        setIsModalOpen={() => setSearchParams('')}>
        <BrokenAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setSearchParams('')}
          defaultValues={{
            idOrder: activeBrokenOrder?.idOrder || '',
            idAlberoOrder: activeBrokenOrder?.idAlberoOrder || '',
            CorriereDiAndata: activeBrokenOrder?.CorriereDiAndata || '',
            channelCarrier: activeBrokenOrder?.channelCarrier || '',
            channel: activeBrokenOrder?.channel || '',
            date: activeBrokenOrder?.date || null,
            clientName: activeBrokenOrder?.customer?.name || '',
            customer: {
              email: activeBrokenOrder?.customer?.email || ''
            }
          }}
        />
      </Modal>
    </>
  );
};

export default ProductDetails;
