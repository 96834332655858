import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { supportsActions } from '../../../../store';
import { selectFilterFields } from '../../../../store/selectors';
import styles from './SupportsHeader.module.scss';
import InputWithDebounce from 'Containers/Common/InputWithDebounce';
import PrimaryButton from 'Containers/Common/Buttons/PrimaryButton';

const SupportsHeader = ({ openFilters }) => {
  const dispatch = useDispatch();

  const filterFields = useSelector(selectFilterFields());

  const handleReset = () => {
    dispatch(supportsActions.GET_SUPPORTS.REQUEST({
      params: {...filterFields, free_text: ''}
    }));
  };


  return (
    <div className={styles.wrapper}>
      <h1>Tutte le richieste</h1>
      <InputWithDebounce
        onReset={handleReset}
        onChange={(inputValue) => {
          dispatch(supportsActions.SET_FILTER_FIELDS.SUCCESS({
            ...filterFields,
            free_text: inputValue
          }));
          dispatch(
            supportsActions.GET_SUPPORTS.REQUEST({
              params: {...filterFields, free_text: inputValue}
            })
          );
        }}
      />
      <PrimaryButton
        height="34px"
        fontSize="13px"
        padding="8px 10px"
        borderRadius="40px"
        text="Filtri"
        onClick={openFilters}
        filterIcon={true}
      />
    </div>
  );
};

export default SupportsHeader;
