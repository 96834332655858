import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { getUserRoleId } from '../../Containers/Auth/store/selectors';
import { ROUTES } from '../constants';

export const PrivateRoute = ({ component: RouteComponent, roles, isAuthenticated }) => {
  const location = useLocation();
  const role_id = useSelector(getUserRoleId());

  const userHasRequiredRole = role_id && !!roles.filter((role) => +role?.id === +role_id).length;

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} />;
  }

  // if (!userHasRequiredRole) {
  //   return <div>Access denied</div>;
  // }

  return <RouteComponent />;
};
