import { TableCell, TableFooter, TableRow } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-nero.svg';
import clx from 'classnames';
import { useEffect } from 'react';

import styles from './DataTableFooter.module.scss';

const DataTableFooter = ({
  count,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  textLabels,
  blockFetchPagination,
  fetchNew,
  setPage
}) => {
  const currentPage = page + 1;
  const pagesNum = Math.ceil(count / rowsPerPage);

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    if (setPage) {
      setPage(page);
    }
  }, [page]);

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={6}>
          <div className={styles.pagination}>
            <button
              className={clx(styles.arrow__back, { [styles.btn__disabled]: page === 0 })}
              disabled={page === 0}
              onClick={() => {
                changePage(page - 1);
              }}>
              <ArrowIcon />
            </button>
            <span className={styles.text}>
              <b>{currentPage}</b>&nbsp;/&nbsp;{pagesNum}
            </span>
            <button
              className={clx(styles.arrow__next, {
                [styles.btn__disabled]: currentPage === pagesNum && blockFetchPagination
              })}
              disabled={currentPage === pagesNum && blockFetchPagination}
              onClick={async () => {
                if (currentPage === pagesNum && fetchNew) {
                  await fetchNew();
                }
                changePage(page + 1);
              }}>
              <ArrowIcon />
            </button>
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default DataTableFooter;
