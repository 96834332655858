import { Api } from 'entries/ApiTransport';
import { call, put, takeLatest } from 'redux-saga/effects';

import sagaAssessor from '../../../utils/sagaAssessor';
import { userActions, userTypes } from './actions';

const api = Api.getInstance();

const getAllClients = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`customers?offset=${payload?.offset || 0}`));
        yield put(
          userActions.GET_ALL_CLIENTS.SUCCESS({
            savePrevious: payload?.savePrevious,
            data: data.data
          })
        );
      },
    (err) => userActions.GET_ALL_CLIENTS.FAILED(err)
  );

const getClient = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`customers/${payload}`));
        yield put(userActions.GET_CLIENT.SUCCESS(data.data));
      },
    (err) => userActions.GET_CLIENT.FAILED(err)
  );

const searchClients = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { customers }
        } = yield call(() => api.get(`customer/search?textvalue=${payload}`));
        yield put(userActions.SEARCH_CLIENTS.SUCCESS(customers));
        typeof callback === 'function' && callback(customers);
      },
    (err) => userActions.SEARCH_CLIENTS.FAILED(err)
  );

const searchAllClients = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get(`customers?freetext=${payload}`));
        yield put(userActions.SEARCH_ALL_CLIENTS.SUCCESS(data));
        typeof callback === 'function' && callback();
      },
    (err) => userActions.SEARCH_ALL_CLIENTS.FAILED(err)
  );

const addClient = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('admin/clienti', payload));
        yield put(userActions.ADD_CLIENT.SUCCESS(data.insert));
        callback && typeof callback === 'function' && callback(data.insert.idAzienda);
      },
    (err) => userActions.ADD_CLIENT.FAILED(err)
  );

const editClient = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { id, ...user } = payload;
        const { data } = yield call(() => api.put(`admin/clienti/${id}`, { cliente: user }));
        yield put(userActions.EDIT_CLIENT.SUCCESS(data.azienda));
        callback && typeof callback === 'function' && callback();
      },
    (err) => userActions.EDIT_CLIENT.FAILED(err)
  );

const getAllUsers = () =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { users }
        } = yield call(() => api.get('user/list'));
        yield put(userActions.GET_ALL_USERS.SUCCESS(users));
      },
    (err) => userActions.GET_ALL_USERS.FAILED(err)
  );

const addUser = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('user/signup', payload));
        yield put(userActions.ADD_USER.SUCCESS(data.user));
        callback && typeof callback === 'function' && callback(data.user.idUser);
      },
    (err) => userActions.ADD_USER.FAILED(err)
  );

const editUser = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.put('user/update', payload));
        yield put(userActions.EDIT_USER.SUCCESS(data.user));
        callback && typeof callback === 'function' && callback();
      },
    (err) => userActions.EDIT_USER.FAILED(err)
  );

export default function* () {
  yield takeLatest(userTypes.GET_ALL_CLIENTS.REQUEST, getAllClients);
  yield takeLatest(userTypes.GET_CLIENT.REQUEST, getClient);
  yield takeLatest(userTypes.SEARCH_CLIENTS.REQUEST, searchClients);
  yield takeLatest(userTypes.SEARCH_ALL_CLIENTS.REQUEST, searchAllClients);
  yield takeLatest(userTypes.ADD_CLIENT.REQUEST, addClient);
  yield takeLatest(userTypes.EDIT_CLIENT.REQUEST, editClient);
  yield takeLatest(userTypes.GET_ALL_USERS.REQUEST, getAllUsers);
  yield takeLatest(userTypes.ADD_USER.REQUEST, addUser);
  yield takeLatest(userTypes.EDIT_USER.REQUEST, editUser);
}
