import clx from 'classnames';

import styles from './PrimaryButton.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const PrimaryButton = ({
  background = 'transparent',
  color = '#001EBE',
  height = '40px',
  width = 'auto',
  fontSize = '18px',
  padding = '8px',
  borderRadius = '7px',
  margin = '0',
  border = '1px solid #8491DD',
  text,
  leftIconSrc,
  leftIconHeight = '100%',
  rightIconHeight = '100%',
  rightIconSrc,
  onClick,
  className,
  type,
  wordBreak = 'keep-all',
  disabled,
  filterIcon = false
}) => (
  <button
    style={{
      height,
      width,
      wordBreak,
      fontSize,
      borderRadius,
      margin,
      border,
      padding,
      color,
      background
    }}
    className={clx(styles.primary__btn, styles[`${className}`])}
    onClick={onClick}
    type={type}
    disabled={disabled}>
    {leftIconSrc && (
      <img
        style={{ height: leftIconHeight }}
        className={styles['left-icon']}
        src={leftIconSrc}
        alt="icon"
      />
    )}
    {
      filterIcon && (
        <FontAwesomeIcon icon={faFilter} className={styles['left-icon']} />
      )
    }
    <span>{text}</span>
    {rightIconSrc && (
      <img
        style={{ height: rightIconHeight }}
        className={styles['right-icon']}
        src={rightIconSrc}
        alt="icon"
      />
    )}
  </button>
);

export default PrimaryButton;
