import { createSelector } from 'reselect';

const selectBrokensState = (state) => state.brokensReducer;
export const selectActiveBroken = () =>
  createSelector(selectBrokensState, (state) => state.activeBroken);
export const selectHasRemainingBreaks = () => createSelector(selectBrokensState, (state) => state.has_remaining_breaks);
export const selectActiveBrokenStatuses = () =>
  createSelector(
    selectBrokensState,
    (state) =>
      state.brokenStatuses.reduce(
        (acc, status) => {
          const isActiveStatusOnBroken = state.activeBroken?.break?.status === status.idBreakStatus;
          return {
            IsActiveStatusFind: isActiveStatusOnBroken || acc.IsActiveStatusFind,
            statuses: [
              ...acc.statuses,
              {
                ...status,
                isActive: isActiveStatusOnBroken,
                isCompleted:
                  state.activeBroken?.break?.status === 4
                    ? true
                    : !acc.IsActiveStatusFind && !isActiveStatusOnBroken
              }
            ]
          };
        },
        { statuses: [], IsActiveStatusFind: false }
      ).statuses
  );

export const selectBrokenFilterStatuses = (is_archived) =>
  createSelector(selectBrokensState, (state) => {
    const activeBrokens = state.brokens.filter((item) => !!item.archived === is_archived);
    return activeBrokens.reduce(
      (acc, item) =>
        acc.map((status) =>
          +status.idBreakStatus === +item.status ? { ...status, count: status.count + 1 } : status
        ),
      [
        {
          idBreakStatus: 0,
          name: 'TUTTI LE ROTTURE',
          count: activeBrokens.length
        },
        ...state.brokenStatuses.map(({ idBreakStatus, name }) => ({
          idBreakStatus,
          name,
          count: 0
        }))
      ]
    );
  });

export const selectFilteredBrokens = (status_id, is_archived) =>
  createSelector(selectBrokensState, (state) =>
    state.brokens.filter(
      (item) => (!status_id || +item.status === +status_id) && !!item.archived === is_archived
    )
  );

export const selectBrokenProductById = (id) =>
  createSelector(selectBrokensState, (state) =>
    state.activeBroken?.products?.products?.find((product) => +product?.idBreakProduct === +id)
  );

export const selectBrokenProductStatuses = () =>
  createSelector(selectBrokensState, (state) => state.activeBroken?.products?.statuses);

export const selectFilterFields = () =>
  createSelector(selectBrokensState, (state) => state.filterFields);
