import clx from 'classnames';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useWindowSize from 'utils/hooks/useWindowSize';
import PrimaryButton from '../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../Common/TextField';
import { userActions } from '../../../store';
import styles from './UserAddAndEditModal.module.scss';
import { VALIDATION_EDIT_OBJECT } from './constants';
import * as yup from 'yup';

const UserAddAndEditModal = ({ isEditForm = false, editValue = {}, setIsModalOpen }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const INITIAL_VALUES = {
    email: editValue?.email || '',
    password: '',
    name: editValue?.name || '',
    surname: editValue?.surname || ''
  };

  const VALIDATION_CREATE_OBJECT = {
    ...VALIDATION_EDIT_OBJECT,
    password: yup.string().required('Obbligatorio')
  };

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      validationSchema={yup
        .object()
        .shape(isEditForm ? VALIDATION_EDIT_OBJECT : VALIDATION_CREATE_OBJECT)}
      onSubmit={(values) => {
        if (isEditForm) {
          dispatch(
            userActions.EDIT_USER.REQUEST({ idUser: editValue.idUser, ...values }, setIsModalOpen)
          );
        } else {
          dispatch(
            userActions.ADD_USER.REQUEST(values, (id) => {
              setIsModalOpen();
              // navigate(`${ROUTES.CLIENTS}/${id}`);
            })
          );
        }
      }}>
      {() => (
        <Form
          className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}
          autoComplete="off">
          <h2 className={styles.form_title}>{isEditForm ? 'Modifica' : 'Aggiungi'} Utente</h2>
          <div className={styles.main_form}>
            <Field name="name" id="name" label="Nome" placeholder="Nome" component={TextField} />
            <Field
              name="surname"
              id="surname"
              label="Cognome"
              placeholder="Cognome"
              component={TextField}
            />
            <Field
              name="email"
              id="email"
              label="Email"
              placeholder="email"
              type="email"
              component={TextField}
              disabled={isEditForm}
            />
            <Field
              name="password"
              id="password"
              label="Password"
              placeholder="password"
              disabled={isEditForm}
              showPassword={true}
              component={TextField}
              type="password"
            />
          </div>
          <PrimaryButton
            type="submit"
            width="100%"
            text={`${isEditForm ? 'Modifica' : 'Aggiungi'} Utente`}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UserAddAndEditModal;
