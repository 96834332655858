import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from 'utils/hooks/useWindowSize';

import { TABLE_KEYS } from '../../../Common/DataTables/constants';
import SupportsTable from '../../../Tables/SupportsTable';
import { supportsActions } from '../../store';
import { selectFilterFields, selectSupports } from '../../store/selectors';
import SupportsHeader from './containers/SupportsHeader';
import Modal from 'Containers/Common/Modal';
import FilterModal from './containers/FilterModal';

const SupportsList = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const filterFields = useSelector(selectFilterFields());
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const supports = useSelector(selectSupports());
  //
  // const [searchParams, setSearchParams] = useSearchParams();
  // const activeReturnOrder = useSelector(
  //   selectAlberoOrderById(+searchParams.get(`${TABLE_KEYS.ORDERS}AddReturn`))
  // );
  //
  useEffect(() => {
    dispatch(supportsActions.SET_FILTER_FIELDS.SUCCESS({
      ...filterFields,
    }));
    dispatch(supportsActions.GET_SUPPORTS.REQUEST());
  }, []);

  return (
    <div>
      <SupportsHeader openFilters={() => setIsFilterModalOpen(true)}/>
      <SupportsTable tableKey={TABLE_KEYS.ASSISTANCE_REQUEST} supportsList={supports} />
      {/*<Modal*/}
      {/*  width={isMobile && '100%'}*/}
      {/*  height={isMobile && '100%'}*/}
      {/*  showCloseBtn={true}*/}
      {/*  isModalOpen={activeOrder}*/}
      {/*  setIsModalOpen={() => setSearchParams('')}>*/}
      {/*  <ReturnAddAndEditModal*/}
      {/*    isMobile={isMobile}*/}
      {/*    setIsModalOpen={() => setSearchParams('')}*/}
      {/*    defaultValues={{*/}
      {/*      idOrder: activeOrder?.idOrder || '',*/}
      {/*      idAlberoOrder: activeOrder?.idAlberoOrder || '',*/}
      {/*      CorriereDiAndata: activeOrder?.CorriereDiAndata || '',*/}
      {/*      channelCarrier: activeOrder?.channelCarrier || '',*/}
      {/*      channel: activeOrder?.channel || '',*/}
      {/*      date: activeOrder?.date || null,*/}
      {/*      clientName: activeOrder?.customer?.name || '',*/}
      {/*      customer: {*/}
      {/*        email: activeOrder?.customer?.email || ''*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Modal>*/}
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isFilterModalOpen}
        setIsModalOpen={() => setIsFilterModalOpen(false)}>
        <FilterModal closeModal={() => setIsFilterModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default SupportsList;
