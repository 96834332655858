import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TABLE_KEYS } from '../../../Common/DataTables/constants';
import ProductsTable from '../../../Tables/ProductsTable';
import { productsActions } from '../../store';
import { selectProducts } from '../../store/selectors';
import ProductsHeader from './containers/ProductsHeader';

const ProductsList = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const products = useSelector(selectProducts());

  useEffect(() => {
    if (!state?.withoutRefreshItems) {
      dispatch(productsActions.GET_PRODUCTS.REQUEST());
    }
  }, []);

  return (
    <div>
      <ProductsHeader />
      <ProductsTable tableKey={TABLE_KEYS.PRODUCTS} productsList={products} />
    </div>
  );
};

export default ProductsList;
