import 'moment/locale/it';

import SearchIcon from '@mui/icons-material/Search';
import { TextField as MaterialTextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import clx from 'classnames';
import { ordersActions } from 'Containers/Orders/store';
import { settingsActions } from 'Containers/Settings/store';
import { selectChannels } from 'Containers/Settings/store/selectors';
import { Field, Form, Formik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { ROUTES } from 'routes/constants';
import useWindowSize from 'utils/hooks/useWindowSize';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../Common/TextField';
import TextFieldMockup from '../../../../../Common/TextFieldMockup';
import { returnsActions } from '../../../../store';
import { selectCarriers } from '../../../../store/selectors';
import { initialValues, validationCreateSchema, validationEditSchema } from './constants';
import styles from './ReturnAddAndEditModal.module.scss';

const ReturnAddAndEditModal = ({
  isEditForm = false,
  setIsModalOpen,
  defaultValues = false,
  options = [],
  returnValues = {},
  idReturn
}) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const carriers = useSelector(selectCarriers());
  const channels = useSelector(selectChannels());

  const options_values = options.reduce((acc, option) => {
    acc[option.idCategory] = option.selectedOption;
    return acc;
  }, {});

  useEffect(() => {
    dispatch(returnsActions.GET_CARRIERS.REQUEST());
    if (isEditForm) {
      dispatch(settingsActions.GET_CHANNELS.REQUEST());
    }
  }, []);

  const searchOrders = (inputValue, callback) => {
    dispatch(
      ordersActions.SEARCH_ORDERS.REQUEST({ query: inputValue }, (value) =>
        callback(
          value.map((item) => ({
            value: item,
            label: (
              <div>
                <div>#{item.number}</div>
                {item?.customer?.name && <div>{item.customer.name}</div>}
                {item?.customer?.email && <div>{item.customer.email}</div>}
              </div>
            )
          }))
        )
      )
    );
  };
  return (
    <Formik
      enableReinitialize
      initialValues={
        isEditForm
          ? { ...initialValues, ...returnValues, ...options_values, activeOrder: defaultValues }
          : defaultValues
          ? { ...initialValues, activeOrder: defaultValues }
          : initialValues
      }
      validationSchema={isEditForm ? validationEditSchema : validationCreateSchema}
      onSubmit={(values) => {
        const {
          searchString,
          activeOrder,
          date,
          returnCarrier,
          carrier,
          channel,
          ldv,
          ...options
        } = values;
        const request = {
          returnCarrier,
          carrier,
          channel,
          ldv,
          date: moment(date).format('YYYY-MM-DD')
        };
        if (isEditForm) {
          const arrayOfOptions = Object.entries(options)
            .map(([idCategory, idOption]) => ({
              idCategory,
              idOption
            }))
            .filter(({ idOption }) => idOption);
          const req = {
            returnObj: {
              ...request,
              idReturn
            },
            products: [],
            property: arrayOfOptions
          };
          dispatch(returnsActions.EDIT_RETURN.REQUEST(req, setIsModalOpen));
        } else {
          dispatch(
            returnsActions.CREATE_RETURN.REQUEST(
              {
                ...request,
                idorder: activeOrder.idAlberoOrder
              },
              (id) => navigate(`${ROUTES.RETURNS}/${id}`)
            )
          );
        }
      }}>
      {({ errors, touched, values, setFieldValue }) => (
        <Form
          className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}
          autoComplete="off">
          <h2 className={styles.form_title}>{isEditForm ? 'Modifica' : 'Aggiungi'} reso</h2>
          {!defaultValues && !isEditForm ? (
            <div className={styles.search_container}>
              <div className={styles.select_wrapper}>
                <div className={styles.select_label}>Cerca nuovo ordine o info cliente</div>
                <AsyncSelect
                  components={{
                    DropdownIndicator: () => <SearchIcon style={{ margin: '0 10px' }} />
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    input: (provided) => ({
                      ...provided,
                      input: {
                        opacity: '1 !important'
                      }
                    }),
                    container: (provided) => ({
                      ...provided,
                      width: '100%'
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '30px',
                      minHeight: '36px'
                    })
                  }}
                  placeholder={'Cerca...'}
                  cacheOptions
                  loadOptions={searchOrders}
                  isSearchable
                  onChange={({ value }) =>
                    setFieldValue('activeOrder', {
                      idOrder: value?.idOrder || '',
                      idAlberoOrder: value?.idAlberoOrder || '',
                      CorriereDiAndata: value?.CorriereDiAndata || '',
                      carrier: value?.carrier || '',
                      channel: value?.channel || '',
                      date: value?.date || null,
                      number: value?.number || '',
                      clientName: value?.customer.name || '',
                      customer: {
                        email: value?.customer?.email || ''
                      }
                    })
                  }
                  inputValue={values.searchString}
                  value={{ value: values.searchString }}
                  onInputChange={(value, actions) => {
                    switch (actions.action) {
                      case 'input-change':
                        return setFieldValue('searchString', value);
                    }
                    return values.searchString;
                  }}
                />
                {touched.searchString && errors.activeOrder?.idOrder && (
                  <div className={styles.select_error}>{errors.activeOrder.idOrder}</div>
                )}
              </div>
            </div>
          ) : null}
          <div className={styles.main_form}>
            <TextFieldMockup value={values?.activeOrder?.number} label="Numero ordine" />
            {!isEditForm && (
              <TextFieldMockup value={values?.activeOrder?.carrier} label="Carrier" />
            )}
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Data ordine</p>
                <DatePicker
                  label="Data ordine"
                  disabled={true}
                  value={values.activeOrder?.date || ''}
                  onChange={(value) => setFieldValue('activeOrder.date', value)}
                  renderInput={(params) => (
                    <MaterialTextField
                      {...params}
                      size="small"
                      error={!!touched.activeOrder?.date && !!errors.activeOrder?.date}
                      helperText={!!touched.activeOrder?.date && errors.activeOrder?.date}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            {!isEditForm && <TextFieldMockup value={values?.activeOrder?.channel} label="Canale" />}
            <TextFieldMockup value={values?.activeOrder?.clientName} label="Nome Cliente" />
            <TextFieldMockup value={values?.activeOrder?.customer?.email} label="Email Cliente" />
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Data reso</p>
                <DatePicker
                  label="Data reso"
                  value={values.date}
                  onChange={(value) => setFieldValue('date', value)}
                  renderInput={(params) => (
                    <MaterialTextField
                      {...params}
                      size="small"
                      error={!!touched.date && !!errors.date}
                      helperText={!!touched.date && errors.date}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Corriere di ritorno</div>
              <Select
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={(data) => setFieldValue('returnCarrier', data?.value)}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '30px',
                    marginLeft: '1px',
                    minHeight: '36px'
                  }),
                  input: (provided) => ({
                    ...provided,
                    input: { fontSize: '16px' }
                  })
                }}
                value={carriers
                  .map(({ id, name }) => ({ value: id, label: name }))
                  .find((item) => item.value === values?.returnCarrier)}
                name="disable"
                options={carriers.map(({ id, name }) => ({ value: id, label: name }))}
              />
              {touched.returnCarrier && errors.returnCarrier && (
                <div className={styles.select_error}>{errors.returnCarrier}</div>
              )}
            </div>
            {isEditForm ? (
              <>
                <div className={styles.select_wrapper}>
                  <div className={styles.select_label}>Corriere di andata</div>
                  <Select
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onChange={(data) => setFieldValue('carrier', data?.value)}
                    isDisabled={true}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{
                      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '30px',
                        marginLeft: '1px',
                        minHeight: '36px'
                      }),
                      input: (provided) => ({
                        ...provided,
                        input: { fontSize: '16px' }
                      })
                    }}
                    value={carriers
                      .map(({ id, name }) => ({ value: id, label: name }))
                      .find((item) => item.value === values?.carrier)}
                    name="disable"
                    options={carriers.map(({ id, name }) => ({ value: id, label: name }))}
                  />
                  {touched.carrier && errors.carrier && (
                    <div className={styles.select_error}>{errors.carrier}</div>
                  )}
                </div>
                <div className={styles.select_wrapper}>
                  <div className={styles.select_label}>Canale</div>
                  <Select
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onChange={(data) => setFieldValue('channel', data?.value)}
                    isDisabled={true}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{
                      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '30px',
                        marginLeft: '1px',
                        minHeight: '36px'
                      }),
                      input: (provided) => ({
                        ...provided,
                        input: { fontSize: '16px' }
                      })
                    }}
                    value={channels
                      .map(({ id, name }) => ({ value: id, label: name }))
                      .find((item) => item.value === values?.channel)}
                    name="disable"
                    options={channels.map(({ id, name }) => ({ value: id, label: name }))}
                  />
                  {touched.channel && errors.channel && (
                    <div className={styles.select_error}>{errors.channel}</div>
                  )}
                </div>
              </>
            ) : null}
            {options
              ?.filter((property) => property.idCategory !== 9)
              ?.map((property) => (
                <div key={property.idCategory} className={styles.select_wrapper}>
                  <div className={styles.select_label}>{property.name}</div>
                  <Select
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onChange={(data) => setFieldValue(property.idCategory, data?.value)}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{
                      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '30px',
                        marginLeft: '1px',
                        minHeight: '36px'
                      }),
                      input: (provided) => ({
                        ...provided,
                        input: { fontSize: '16px' }
                      })
                    }}
                    value={property.categoryOptions
                      .map(({ value, idOption }) => ({ value: idOption, label: value }))
                      .find((item) => +item.value === +values[property.idCategory])}
                    options={property.categoryOptions.map(({ value, idOption }) => ({
                      value: idOption,
                      label: value
                    }))}
                  />
                  {touched[property.idCategory] && errors[property.idCategory] && (
                    <div className={styles.select_error}>{errors[property.idCategory]}</div>
                  )}
                </div>
              ))}
            <Field
              name="ldv"
              id="ldv"
              label="Num. altra LDV"
              placeholder="Num. altra LDV"
              component={TextField}
            />
          </div>
          <div className={styles.form__action}>
            <PrimaryButton
              type="submit"
              width="40%"
              background="#4E44C6"
              color="white"
              text={`${isEditForm ? 'Modifica' : 'Crea Nuovo'} Reso`}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReturnAddAndEditModal;
