export async function downloadUsingFetch(IMG_URL, FILE_NAME) {
  const image = await fetch(IMG_URL);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const anchor = document.createElement('a');
  anchor.href = imageURL;
  anchor.download = FILE_NAME;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
