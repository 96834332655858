import { createSelector } from 'reselect';

const selectReturnsState = (state) => state.returnsReducer;
export const selectReturns = () => createSelector(selectReturnsState, (state) => state.returns);
export const selectHasRemainingReturns = () => createSelector(selectReturnsState, (state) => state.has_remaining_returns);
export const selectCarriers = () => createSelector(selectReturnsState, (state) => state.carriers);
export const selectReasons = () => createSelector(selectReturnsState, (state) => state.category);
export const selectFilterFields = () =>
  createSelector(selectReturnsState, (state) => state.filterFields);
export const selectActiveReturn = () =>
  createSelector(selectReturnsState, (state) => state.activeReturn);
export const selectActiveReturnStatuses = () =>
  createSelector(
    selectReturnsState,
    (state) =>
      state.returnsStatuses.reduce(
        (acc, status) => {
          const isActiveStatusOnReturn =
            state.activeReturn?.return?.status === status.idReturnStatus;
          return {
            IsActiveStatusFind: isActiveStatusOnReturn || acc.IsActiveStatusFind,
            statuses: [
              ...acc.statuses,
              {
                ...status,
                isActive: isActiveStatusOnReturn,
                isCompleted:
                  state.activeReturn?.return?.status === 4
                    ? true
                    : !acc.IsActiveStatusFind && !isActiveStatusOnReturn
              }
            ]
          };
        },
        { statuses: [], IsActiveStatusFind: false }
      ).statuses
  );

export const selectReturnFilterStatuses = (is_archived) =>
  createSelector(selectReturnsState, (state) => {
    const activeReturns = state.returns.filter((item) => !!item.archived === is_archived);
    const totalRows = state.countReturns?.reduce((acc, item) => acc + item.count, 0);
    return activeReturns.reduce(
      (acc, item) =>
        acc.map((status) => {
          if (+status.idReturnStatus === +item.status ) {
            return { ...status, count: state.countReturns.find(count => +count?.idReturnStatus === +status.idReturnStatus)?.count ?? 0 };
          }
          else return status;
        }),
      [
        {
          idReturnStatus: 0,
          name: 'TUTTI I RESI',
          count: totalRows
        },
        ...state.returnsStatuses.map(({ idReturnStatus, name }) => ({
          idReturnStatus,
          name,
          count: state.countReturns.find(count => +count?.idReturnStatus === idReturnStatus)?.count ?? 0
        }))
      ]
    );
  });

export const selectFilteredReturns = (status_id) =>
  createSelector(selectReturnsState, (state) =>
    state.returns.filter(
      (item) => !status_id || +item.status === +status_id
    )
  );


export const selectReturnProductById = (id) =>
  createSelector(selectReturnsState, (state) =>
    state.activeReturn?.products?.products?.find((product) => +product?.idReturnProduct === +id)
  );

export const selectReturnProductStatuses = () =>
  createSelector(selectReturnsState, (state) => state.activeReturn?.products?.statuses);
