import { ROUTES } from 'routes/constants';

import Sidebar1 from '../../../assets/svg/sideBar/01.svg';
import Sidebar2 from '../../../assets/svg/sideBar/02.svg';
import Sidebar3 from '../../../assets/svg/sideBar/03.svg';
import Sidebar4 from '../../../assets/svg/sideBar/04.svg';
import Sidebar5 from '../../../assets/svg/sideBar/05.svg';
import Sidebar6 from '../../../assets/svg/sideBar/06.svg';
import Sidebar7 from '../../../assets/svg/sideBar/07.svg';
import Sidebar8 from '../../../assets/svg/sideBar/08.svg';
import Sidebar9 from '../../../assets/svg/sideBar/09.svg';
import Sidebar10 from '../../../assets/svg/sideBar/10.svg';
import Sidebar11 from '../../../assets/svg/sideBar/11.svg';

export const NAV_BAR_ADMIN_ROUTES = [
  {
    label: 'Resi',
    icon: Sidebar1,
    is_active: false,
    is_active_children: false,
    active_children_link: `${ROUTES.RETURNS}/all/0`,
    children: [
      {
        label: 'Tutti i resi',
        link: `${ROUTES.RETURNS}/all/0`,
        is_active: false
      },
      {
        label: 'Archivio resi',
        link: `${ROUTES.RETURNS}/archive/0`,
        is_active: false
      }
    ]
  },
  {
    label: 'Richiesta assistenza',
    icon: Sidebar2,
    link: ROUTES.ASSISTANCE_REQUEST,
    is_active: false,
    is_active_children: false
  },
  {
    label: 'Ordini',
    icon: Sidebar3,
    is_active: false,
    is_active_children: false,
    active_children_link: `${ROUTES.ORDERS}/all`,
    children: [
      {
        label: 'Tutti gli ordini',
        link: `${ROUTES.ORDERS}/all`,
        is_active: false
      },
      {
        label: 'Ordini cancellati',
        link: `${ROUTES.ORDERS}/canceled`,
        is_active: false
      }
    ]
  },
  {
    label: 'Fatture',
    icon: Sidebar11,
    link: ROUTES.INVOICES,
    is_active_children: false,
    active_children_link: `${ROUTES.INVOICES}/all`,
    children: [
      {
        label: 'Tutte le fatture',
        link: `${ROUTES.INVOICES}/all`,
        is_active: false
      },
      {
        label: 'Archivio fatture',
        link: `${ROUTES.INVOICES}/archive`,
        is_active: false
      }
    ]
  },
  {
    label: 'Clienti',
    icon: Sidebar4,
    link: ROUTES.CLIENTS,
    is_active: false,
    is_active_children: false
  },
  {
    label: 'Rotture',
    icon: Sidebar5,
    is_active: false,
    is_active_children: false,
    active_children_link: `${ROUTES.BROKENS}/all/0`,
    children: [
      {
        label: 'Tutti i rotture',
        link: `${ROUTES.BROKENS}/all/0`,
        is_active: false
      },
      {
        label: 'Archivio rotture',
        link: `${ROUTES.BROKENS}/archive/0`,
        is_active: false
      }
    ]
  },
  {
    label: 'Errori',
    icon: Sidebar6,
    link: ROUTES.ERRORS,
    is_active: false,
    is_active_children: false
  },
  {
    label: 'Prodotti',
    icon: Sidebar7,
    link: ROUTES.PRODUCTS,
    is_active: false,
    is_active_children: false
  },
  {
    label: 'Assegnazione corriere',
    icon: Sidebar8,
    link: ROUTES.DELIVERY,
    is_active: false,
    is_active_children: false
  },
  {
    label: 'Statistiche',
    icon: Sidebar9,
    is_active: false,
    is_active_children: false,
    active_children_link: `${ROUTES.STATISTIC}/general`,
    children: [
      {
        label: 'Generali',
        link: `${ROUTES.STATISTIC}/general`,
        is_active: false
      },
      {
        label: 'Resi',
        link: `${ROUTES.STATISTIC}/returns`,
        is_active: false
      },
      {
        label: 'Rotture',
        link: `${ROUTES.STATISTIC}/brokens`,
        is_active: false
      },
      {
        label: 'Errori',
        link: `${ROUTES.STATISTIC}/errors`,
        is_active: false
      }
    ]
  },
  {
    label: 'Impostazioni',
    icon: Sidebar10,
    is_active: false,
    is_active_children: false,
    active_children_link: `${ROUTES.SETTINGS}/all_fields_tags`,
    children: [
      {
        label: 'Tutti i campi tag',
        link: `${ROUTES.SETTINGS}/all_fields_tags`,
        is_active: false
      },
      {
        label: 'Gestione utenti',
        link: `${ROUTES.SETTINGS}/users`,
        is_active: false
      },
      {
        label: 'Admin',
        link: `${ROUTES.SETTINGS}/admins`,
        is_active: false
      },
      {
        label: 'Magazziniere',
        link: `${ROUTES.SETTINGS}/workers`,
        is_active: false
      },
      {
        label: 'Ufficio',
        link: `${ROUTES.SETTINGS}/office`,
        is_active: false
      },
      {
        label: 'Preventivo',
        link: `${ROUTES.SETTINGS}/preventivo`,
        is_active: false
      }
    ]
  }
];
