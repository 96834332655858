import { productsTypes } from './actions';

const initialState = {
  productsCategories: [],
  products: [],
  activeProduct: {
    productOrders: []
  },
  query: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case productsTypes.GET_PRODUCTS.SUCCESS:
      const { savePrevious, data } = action.payload;
      return {
        ...state,
        query: '',
        products: savePrevious ? [...state.products, ...data.products] : data.products,
        productsCategories: data.categories
      };
    case productsTypes.GET_PRODUCT.SUCCESS:
      return {
        ...state,
        activeProduct: action.payload.product,
        productsCategories: action.payload.categories
      };
    case productsTypes.EDIT_PRODUCT.SUCCESS:
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          ...action.payload
        }
      };
    case productsTypes.SEARCH_PRODUCTS.SUCCESS:
      const { query, products, categories } = action.payload;
      return {
        ...state,
        products,
        productsCategories: categories,
        query
      };
    default:
      return { ...state };
  }
};
