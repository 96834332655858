import moment from 'moment';

import styles from './Card.module.scss';

const Card = ({ data }) => (
  <div className={styles.card}>
    <div>
      <span className={styles.title}>
        Utente・
        <span className={styles.id}>
          ID-{data?.user?.idUser} {data?.user?.name} {data?.user?.surname}
        </span>{' '}
        ▸ Azione compiuta
      </span>
      <p className={styles.description}>{data?.text}</p>
    </div>
    <div className={styles.time}>{moment(data?.created_at).format('hh:mm A')}</div>
  </div>
);
export default Card;
