export const FILTER_TYPES = [
  {
    key: 'all',
    name: 'Tutti'
  },
  {
    key: 'return',
    name: 'Reso'
  },
  {
    key: 'break',
    name: 'Rotture'
  },
  {
    key: 'error',
    name: 'Errori'
  }
];
