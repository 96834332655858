import { invoicesTypes } from './actions';

const initialState = {
  invoices: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case invoicesTypes.GET_INVOICES.SUCCESS:
      return {
        ...state,
        invoices: action.payload
      };
    case invoicesTypes.EDIT_INVOICE.SUCCESS:
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.idOrder === action.payload.idOrder ? { ...invoice, ...action.payload } : invoice
        )
      };
    default:
      return { ...state };
  }
};
