import ArrowIcon from 'assets/svg/arrow-left-blue.svg';
import clx from 'classnames';
import PrimaryButton from 'Containers/Common/Buttons/PrimaryButton';
import { TABLE_KEYS } from 'Containers/Common/DataTables/constants';
import { FILTER_TYPES } from 'Containers/Products/containers/ProductDetails/constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useWindowSize from 'utils/hooks/useWindowSize';

import BrokenAddAndEditModal from '../../../../Brokens/containers/BrokensList/containers/BrokenAddAndEditModal';
import Modal from '../../../../Common/Modal';
import TextFieldMockup from '../../../../Common/TextFieldMockup';
import ReturnAddAndEditModal from '../../../../Returns/containers/ReturnsList/containers/ReturnAddAndEditModal';
import OrdersTable from '../../../../Tables/OrdersTable';
import { userActions } from '../../../store';
import { selectClient, selectUserOrderById } from '../../../store/selectors';
import styles from './ClientsDetails.module.scss';

export default () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState(FILTER_TYPES[0].key);
  const [searchParams, setSearchParams] = useSearchParams();

  const client = useSelector(selectClient());

  const activeReturnOrder = useSelector(
    selectUserOrderById(+searchParams.get(`${TABLE_KEYS.ORDERS}AddReturn`))
  );

  const activeBrokenOrder = useSelector(
    selectUserOrderById(+searchParams.get(`${TABLE_KEYS.ORDERS}AddBroken`))
  );

  const filteredOrders = client?.orders?.reduce(
    (acc, item) => {
      FILTER_TYPES.filter((type) => item[type.key]).forEach(({ key }) => acc[key].push(item));
      return acc;
    },
    {
      all: client?.orders,
      return: [],
      break: [],
      error: []
    }
  );

  const infoDetails = [
    {
      id: 1,
      label: 'ID Cliente',
      value: client?.idCustomer
    },
    {
      id: 2,
      label: 'Nome cliente',
      value: client?.name
    },
    {
      id: 3,
      label: 'Data Registrazione',
      value: moment(client?.registrationDate).format('DD/MM/YYYY')
    },
    {
      id: 4,
      label: 'Email cliente',
      value: client?.email
    }
  ];

  const addresses = [
    {
      id: 1,
      label: 'Indirizzo Spedizione',
      value: client.shippingAddress?.address
        ? `${client.shippingAddress?.address} ${client.shippingAddress?.city}
         ${client.shippingAddress?.postalCode} ${client.shippingAddress?.state}`
        : 'Nessun indirizzo trovato'
    },
    {
      id: 2,
      label: 'Indirizzo Fatturazione',
      value: client.billingAddress?.address
        ? `${client.billingAddress?.address} ${client.billingAddress?.city} 
         ${client.billingAddress?.postalCode} ${client.billingAddress?.state}`
        : 'Nessun indirizzo trovato'
    }
  ];

  useEffect(() => {
    window.history.replaceState({}, document.title);
    dispatch(userActions.GET_CLIENT.REQUEST(clientId));
  }, []);

  return Object.values(client).length ? (
    <>
      {location.state?.page >= 0 ? (
        <PrimaryButton
          height="34px"
          fontSize="13px"
          width="100px"
          padding="8px 10px"
          margin="0 0 20px 0"
          borderRadius="40px"
          text={'Indietro'}
          leftIconSrc={ArrowIcon}
          onClick={() =>
            navigate(location.state?.pathname, {
              state: {
                page: location.state?.page || 0,
                offset: location.state?.offset || 0,
                withoutRefreshItems: true
              }
            })
          }
        />
      ) : null}
      <div className={clx(styles['container-inner'], { [styles.container_mbl]: isMobile })}>
        <div className={styles.info_wrapper}>
          <div className={styles.info}>
            <div className={styles['block-wrapper']}>
              <div className={styles.header}>
                <p className={styles.info__title}>Info del cliente</p>
                <div className={styles.type}>{client?.type}</div>
              </div>
              <div className={styles.info__body}>
                {infoDetails.map((i) => (
                  <TextFieldMockup key={i.id} maxWidth={'275px'} value={i.value} label={i.label} />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.addresses}>
            {addresses?.map((address) => (
              <TextFieldMockup
                key={address.id}
                maxWidth={'275px'}
                value={address.value}
                label={address.label}
              />
            ))}
          </div>
        </div>
        <div className={styles.orders}>
          <p className={styles.orders__title}>Lista ordine clienti</p>
          <div className={styles.orders__filter}>
            {FILTER_TYPES.map((i) => (
              <div
                key={i.key}
                className={clx(styles.orders__filter__item, {
                  [styles.orders__filter__item_active]: filter === i.key
                })}
                onClick={() => setFilter(i.key)}>
                {i.name}
                <div className={styles.orders__filter__item__count}>
                  {filteredOrders[i.key].length}
                </div>
              </div>
            ))}
          </div>
          <OrdersTable tableKey={TABLE_KEYS.ORDERS} ordersList={filteredOrders[filter]} />
        </div>
      </div>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={activeReturnOrder}
        setIsModalOpen={() => setSearchParams('')}>
        <ReturnAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setSearchParams('')}
          defaultValues={{
            idOrder: activeReturnOrder?.idOrder || '',
            idAlberoOrder: activeReturnOrder?.idAlberoOrder || '',
            CorriereDiAndata: activeReturnOrder?.CorriereDiAndata || '',
            channelCarrier: activeReturnOrder?.channelCarrier || '',
            channel: activeReturnOrder?.channel || '',
            date: activeReturnOrder?.date || null,
            clientName: activeReturnOrder?.customer?.name || '',
            customer: {
              email: activeReturnOrder?.customer?.email || ''
            }
          }}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={activeBrokenOrder}
        setIsModalOpen={() => setSearchParams('')}>
        <BrokenAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setSearchParams('')}
          defaultValues={{
            idOrder: activeBrokenOrder?.idOrder || '',
            idAlberoOrder: activeBrokenOrder?.idAlberoOrder || '',
            CorriereDiAndata: activeBrokenOrder?.CorriereDiAndata || '',
            channelCarrier: activeBrokenOrder?.channelCarrier || '',
            channel: activeBrokenOrder?.channel || '',
            date: activeBrokenOrder?.date || null,
            clientName: activeBrokenOrder?.customer?.name || '',
            customer: {
              email: activeBrokenOrder?.customer?.email || ''
            }
          }}
        />
      </Modal>
    </>
  ) : null;
};
