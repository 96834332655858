import { createSelector } from 'reselect';

const selectOrdersState = (state) => state.ordersReducer;

export const selectOrders = () => createSelector(selectOrdersState, (state) => state.orders);
export const selectHasRemainingOrders = () => createSelector(selectOrdersState, (state) => state.has_remaining_orders);
export const selectOrder = () => createSelector(selectOrdersState, (state) => state.activeOrder);
export const selectAlberoOrderById = (id) =>
  createSelector(selectOrdersState, (state) =>
    state.orders.find((order) => +order.idAlberoOrder === id)
  );
export const selectCreateOrderResponse = () =>
  createSelector(selectOrdersState, (state) => state.createOrderResponse);

export const selectShipping = () => createSelector(selectOrdersState, (state) => state.shipping);
export const selectQuerySearch = () => createSelector(selectOrdersState, (state) => state.query);
export const selectFilterFields = () =>
  createSelector(selectOrdersState, (state) => state.filterFields);
