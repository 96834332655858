import 'moment/locale/it';

import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, FormControlLabel, TextField as MaterialTextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import clx from 'classnames';
import { ordersActions } from 'Containers/Orders/store';
import { Field, Form, Formik } from 'formik';
import moment from 'moment/moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { ROUTES } from 'routes/constants';
import useWindowSize from 'utils/hooks/useWindowSize';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../Common/TextField';
import TextFieldMockup from '../../../../../Common/TextFieldMockup';
import { brokensActions } from '../../../../store';
import styles from './BrokenAddAndEditModal.module.scss';
import { initialValues, validationCreateSchema, validationEditSchema } from './constants';

const BrokenAddAndEditModal = ({
  isEditForm = false,
  setIsModalOpen,
  defaultValues = false,
  options = [],
  brokenValues = {},
  idBreak
}) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options_values = options.reduce((acc, option) => {
    acc[option.idCategory] = option.selectedOption;
    return acc;
  }, {});
  const searchOrders = (inputValue, callback) => {
    dispatch(
      ordersActions.SEARCH_ORDERS.REQUEST({ query: inputValue }, (value) =>
        callback(
          value.map((item) => ({
            value: item,
            label: (
              <div>
                <div>#{item.number}</div>
                {item?.customer?.name && <div>{item.customer.name}</div>}
                {item?.customer?.email && <div>{item.customer.email}</div>}
              </div>
            )
          }))
        )
      )
    );
  };
  return (
    <Formik
      enableReinitialize
      initialValues={
        isEditForm
          ? { ...initialValues, ...brokenValues, ...options_values, activeOrder: defaultValues }
          : defaultValues
          ? { ...initialValues, activeOrder: defaultValues }
          : initialValues
      }
      validationSchema={isEditForm ? validationEditSchema : validationCreateSchema}
      onSubmit={(values) => {
        const {
          searchString,
          activeOrder,
          date,
          packagesNumber,
          shipmentWeight,
          insuranceValue,
          totalOrder,
          contrassegno,
          ...options
        } = values;
        const request = {
          date: moment(date).format('YYYY-MM-DD'),
          packagesNumber,
          shipmentWeight,
          insuranceValue,
          totalOrder,
          contrassegno
        };
        if (isEditForm) {
          const arrayOfOptions = Object.entries(options).map(([idCategory, idOption]) => ({
            idCategory,
            idOption
          }));
          const res = {
            breakObj: {
              ...request,
              idBreak
            },
            products: [],
            property: arrayOfOptions
          };
          dispatch(brokensActions.EDIT_BROKEN.REQUEST(res, setIsModalOpen));
        } else {
          dispatch(
            brokensActions.CREATE_BROKEN.REQUEST(
              {
                ...request,
                idorder: activeOrder.idAlberoOrder
              },
              (id) => navigate(`${ROUTES.BROKENS}/${id}`)
            )
          );
        }
      }}>
      {({ errors, touched, values, setFieldValue }) => (
        <Form
          className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}
          autoComplete="off">
          <h2 className={styles.form_title}>{isEditForm ? 'Modifica' : 'Aggiungi'} rottura</h2>
          {!defaultValues && !isEditForm ? (
            <div className={styles.search_container}>
              <div className={styles.select_wrapper}>
                <div className={styles.select_label}>Cerca nuovo ordine o info cliente</div>
                <AsyncSelect
                  components={{
                    DropdownIndicator: () => <SearchIcon style={{ margin: '0 10px' }} />
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    input: (provided) => ({
                      ...provided,
                      input: {
                        opacity: '1 !important'
                      }
                    }),
                    container: (provided) => ({
                      ...provided,
                      width: '100%'
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '30px',
                      minHeight: '36px'
                    })
                  }}
                  placeholder={'Cerca...'}
                  cacheOptions
                  loadOptions={searchOrders}
                  isSearchable
                  onChange={({ value }) => {
                    setFieldValue('activeOrder', {
                      idOrder: value?.idOrder || '',
                      idAlberoOrder: value?.idAlberoOrder || '',
                      carrier: value?.carrier || '',
                      channel: value?.channel || '',
                      date: value?.date || null,
                      number: value?.number || '',
                      clientName: value?.customer?.name || '',
                      customer: {
                        email: value?.customer?.email || ''
                      }
                    });
                  }
                  }
                  inputValue={values.searchString}
                  value={{ value: values.searchString }}
                  onInputChange={(value, actions) => {
                    switch (actions.action) {
                      case 'input-change':
                        return setFieldValue('searchString', value);
                    }
                    return values.searchString;
                  }}
                />
                {touched.searchString && errors.activeOrder?.idOrder && (
                  <div className={styles.select_error}>{errors.activeOrder.idOrder}</div>
                )}
              </div>
            </div>
          ) : null}
          <div className={styles.main_form}>
            <TextFieldMockup value={values?.activeOrder?.number} label="Numero ordine" />
            {!isEditForm && (
              <TextFieldMockup value={values?.activeOrder?.carrier} label="Corriere di andata" />
            )}
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Data ordine</p>
                <DatePicker
                  label="Data ordine"
                  disabled={true}
                  value={values.activeOrder?.date || ''}
                  onChange={() => {}}
                  renderInput={(params) => (
                    <MaterialTextField
                      {...params}
                      size="small"
                      error={!!touched.activeOrder?.date && !!errors.activeOrder?.date}
                      helperText={!!touched.activeOrder?.date && errors.activeOrder?.date}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            {!isEditForm && <TextFieldMockup value={values?.activeOrder?.channel} label="Canale" />}
            <TextFieldMockup value={values?.activeOrder?.clientName} label="Nome Cliente" />
            <TextFieldMockup value={values?.activeOrder?.customer?.email} label="Email Cliente" />
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Data rottura</p>
                <DatePicker
                  label="Data rottura"
                  value={values.date}
                  onChange={(value) => setFieldValue('date', value)}
                  renderInput={(params) => (
                    <MaterialTextField
                      {...params}
                      size="small"
                      error={!!touched.date && !!errors.date}
                      helperText={!!touched.date && errors.date}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            {isEditForm ? (
              <>
                <TextFieldMockup
                  value={values?.activeOrder?.indirizzo}
                  label="Indirizzo"
                  // maxWidth={'275px'}
                />
                <Field
                  name="packagesNumber"
                  id="packagesNumber"
                  label="Numero colli"
                  placeholder="Numero colli"
                  component={TextField}
                />
                <Field
                  name="shipmentWeight"
                  id="shipmentWeight"
                  label="Peso della spedizione"
                  placeholder="Peso della spedizione"
                  component={TextField}
                />
                <Field
                  name="insuranceValue"
                  id="insuranceValue"
                  label="Val. Assicurato"
                  placeholder="Val. Assicurato"
                  component={TextField}
                />
                <Field
                  name="totalOrder"
                  id="totalOrder"
                  label="Totale Ordine"
                  placeholder="Totale Ordine"
                  component={TextField}
                />
              </>
            ) : null}
            {options?.map((property) => (
              <div key={property.idCategory} className={styles.select_wrapper}>
                <div className={styles.select_label}>{property.name}</div>
                <Select
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  onChange={(data) => setFieldValue(property.idCategory, data?.value)}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '30px',
                      marginLeft: '1px',
                      minHeight: '36px'
                    }),
                    input: (provided) => ({
                      ...provided,
                      input: { fontSize: '16px' }
                    })
                  }}
                  value={property.categoryOptions
                    .map(({ value, idOption }) => ({ value: idOption, label: value }))
                    .find((item) => +item.value === +values[property.idCategory])}
                  options={property.categoryOptions.map(({ value, idOption }) => ({
                    value: idOption,
                    label: value
                  }))}
                />
                {touched[property.idCategory] && errors[property.idCategory] && (
                  <div className={styles.select_error}>{errors[property.idCategory]}</div>
                )}
              </div>
            ))}
          </div>
          {isEditForm ? (
            <FormControlLabel
              style={{ margin: 0 }}
              labelPlacement="start"
              control={
                <Checkbox
                  checked={values.contrassegno}
                  onChange={({ target: { checked } }) => setFieldValue('contrassegno', checked)}
                />
              }
              label={'Contrassegno'}
            />
          ) : null}
          <div className={styles.form__action}>
            <PrimaryButton
              type="submit"
              width="40%"
              background="#4E44C6"
              color="white"
              text={`${isEditForm ? 'Modifica' : 'Crea Nuovo'} Rottura`}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BrokenAddAndEditModal;
