import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import useWindowSize from 'utils/hooks/useWindowSize';
import { TABLE_KEYS } from '../../../../Common/DataTables/constants';
import TableHeader from '../../../../Common/DataTables/containers/TableHeader/TableHeader';
import Modal from '../../../../Common/Modal';
import { userActions } from '../../../store';
import {selectUserById, selectUsers} from '../../../store/selectors';
import styles from './UsersList.module.scss';
import UserAddAndEditModal from '../UserAddAndEditModal';
import UsersTable from '../../../../Tables/UsersTable';

export default () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const usersList = useSelector(selectUsers());

  const userToEdit = useSelector(selectUserById(searchParams.get(`${TABLE_KEYS.USERS}Edit`)));
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  useEffect(() => {
    dispatch(userActions.GET_ALL_USERS.REQUEST());
  }, [dispatch]);

  return (
    <div className={styles.container__inner}>
      <TableHeader
        title="Utenti"
        buttonText="Aggiungi Utente"
        showAddButton={true}
        action={() => setIsAddUserModalOpen(true)}
      />
      <UsersTable usersList={usersList} />
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isAddUserModalOpen}
        setIsModalOpen={() => setIsAddUserModalOpen(false)}>
        <UserAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsAddUserModalOpen(false)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={userToEdit}
        setIsModalOpen={() => setSearchParams('')}>
        <UserAddAndEditModal
          isEditForm={true}
          editValue={userToEdit}
          setIsModalOpen={() => setSearchParams('')}
        />
      </Modal>
    </div>
  );
};
