import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {selectBrokenFilterStatuses, selectFilteredBrokens} from '../../store/selectors';
import {BrokensList} from '../index';

export default () => {
  const brokenStatuses = useSelector(selectBrokenFilterStatuses(true));
  const [selectedStatus, setSelectedStatus] = useState(brokenStatuses[0]);
  const brokens = useSelector(selectFilteredBrokens(selectedStatus?.idBreakStatus, true));
  return <BrokensList
    brokens={brokens}
    brokenStatuses={brokenStatuses}
    selectedStatus={selectedStatus}
    setSelectedStatus={setSelectedStatus}
  />;
};
