import clx from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LogoImage from '../../assets/images/logo.svg';
import { ReactComponent as ArrowIcon } from '../../assets/svg/arrow-small.svg';
import LogoutIcon from '../../assets/svg/logout.svg';
import { ROLES } from '../../routes/constants';
import { authActions } from '../Auth/store';
import { getUser, getUserRoleId } from '../Auth/store/selectors';
import SecondaryButton from '../Common/Buttons/SecondaryButton';
import { NAV_BAR_ADMIN_ROUTES } from './constants';
import NavItem from './containers/NavItem';
import styles from './SideBar.module.scss';

const SideBar = ({ isMobile, isCollapsedSideBar, setIsCollapsedSideBar }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const user = useSelector(getUser());
  const userId = useSelector(getUserRoleId());
  const [navBarRoutes, setNavBarRoutes] = useState([]);

  useEffect(() => {
    const a = 1;
    return setNavBarRoutes(NAV_BAR_ADMIN_ROUTES);
    // switch (userId) {
    //   case ROLES.ADMIN.id:
    //     return setNavBarRoutes(NAV_BAR_ADMIN_ROUTES);
    //   case ROLES.DOCTOR.id:
    //     return setNavBarRoutes(NAV_BAR_DOCTOR_ROUTES);
    // }
  }, [userId]);
  useEffect(() => {
    if (location) {
      setNavBarRoutes((routes) =>
        routes.map((route) => {
          const activeParent = location.pathname === route?.link;
          if (activeParent) {
            setIsCollapsedSideBar((isCollapse) =>
              !isCollapse && isMobile ? !isCollapse : isCollapse
            );
            return {
              ...route,
              is_active: true
            };
          }
          const activeChildren = route?.children?.find(
            (subItem) => subItem.link === location.pathname
          );
          if (activeChildren) {
            const isParentLink = !!location.state?.isParentLink;
            const is_active_children = !!(
              !(isParentLink && route.is_active_children) ||
              (route.is_active_children && isCollapsedSideBar)
            );
            setIsCollapsedSideBar(
              (isCollapse) =>
                (isCollapse && !is_active_children) ||
                (isMobile && !is_active_children && !route?.children?.length) ||
                (isMobile && is_active_children && !isParentLink)
            );
            return {
              ...route,
              is_active: true,
              is_active_children,
              active_children_link: activeChildren.link,
              children: route?.children?.map((subItem) =>
                subItem.link === location.pathname
                  ? { ...subItem, is_active: true }
                  : { ...subItem, is_active: false }
              )
            };
          }
          return {
            ...route,
            is_active: false,
            is_active_children: false
          };
        })
      );
    }
  }, [location]);

  useEffect(() => {
    if (isMobile) {
      return setIsCollapsedSideBar(true);
    }
  }, [isMobile]);

  return (
    <aside
      className={clx(
        styles.sidebar__wrapper,
        { [styles.mobile_sidebar__wrapper]: isMobile },
        { [styles.collapse_sidebar__wrapper]: isCollapsedSideBar }
      )}>
      <div className={styles.sidebar__inner}>
        <div>
          <div className={styles.logo__wrapper}>
            <div />
            <img src={LogoImage} alt="logo" className={styles.logo} />
            <ArrowIcon
              onClick={() => setIsCollapsedSideBar(!isCollapsedSideBar)}
              className={clx(styles.collapse_arrow)}
            />
          </div>
          <nav className={styles['nav-bar']}>
            <ul>
              {navBarRoutes.map((navItem, idx) => (
                <NavItem key={idx} navItem={navItem} isCollapsedSideBar={isCollapsedSideBar} />
              ))}
            </ul>
          </nav>
        </div>
        <div className={styles.exit_button_wrapper}>
          <SecondaryButton
            height="20px"
            leftIconSrc={LogoutIcon}
            text="Esci"
            fontSize="12px"
            color="#D70015"
            onClick={() => dispatch(authActions.LOG_OUT.REQUEST())}
          />
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
