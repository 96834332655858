import { authTypes } from './actions';

const initialState = {
  user: {},
  isLogin: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SIGN_IN.REQUEST:
      return {
        ...state
      };
    case authTypes.SIGN_IN.FAILED:
      return {
        ...state
      };
    case authTypes.FETCH_USER.SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case authTypes.SIGN_IN.SUCCESS:
      return {
        ...state,
        isLogin: true
      };
    case authTypes.LOG_OUT.SUCCESS:
      return {
        ...state,
        isLogin: false,
        user: {}
      };
    default:
      return { ...state };
  }
};
