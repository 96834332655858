import { returnsActions } from 'Containers/Returns/store';
import { Api } from 'entries/ApiTransport';
import { call, put, takeLatest } from 'redux-saga/effects';
import sagaAssessor from 'utils/sagaAssessor';

import { settingsActions, settingsTypes } from './actions';

const api = Api.getInstance();

const getArchiveReturnsStatuses = () =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.get('category'));
        yield put(settingsActions.GET_ARCHIVE_RETURNS_STATUSES.SUCCESS(data));
      },
    (err) => settingsActions.GET_ARCHIVE_RETURNS_STATUSES.FAILED(err)
  );
const getEstimation = () =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { scarto }
        } = yield call(() => api.get('scarto'));
        yield put(settingsActions.GET_ESTIMATION.SUCCESS(scarto));
      },
    (err) => settingsActions.GET_ESTIMATION.FAILED(err)
  );
const getStates = () =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { states }
        } = yield call(() => api.get('states'));
        yield put(settingsActions.GET_STATES.SUCCESS(states));
      },
    (err) => settingsActions.GET_STATES.FAILED(err)
  );
const getChannels = () =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get('channels'));
        yield put(settingsActions.GET_CHANNELS.SUCCESS(data.channels));
      },
    (err) => settingsActions.GET_CHANNELS.FAILED(err)
  );
const setEstimation = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.put('/scarto?scarto=' + payload));
        yield put(settingsActions.GET_ESTIMATION.SUCCESS(payload));
        callback();
      },
    (err) => settingsActions.GET_ESTIMATION.FAILED(err)
  );

const addArchiveReturnsStatuses = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.post('category', payload));
        yield put(settingsActions.ADD_ARCHIVE_RETURNS_STATUSES.SUCCESS(data));
        callback && typeof callback === 'function' && callback();
      },
    (err) => settingsActions.ADD_ARCHIVE_RETURNS_STATUSES.FAILED(err)
  );

const editArchiveReturnsStatuses = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.put('category', payload));
        yield put(settingsActions.EDIT_ARCHIVE_RETURNS_STATUSES.SUCCESS(data));
        callback && typeof callback === 'function' && callback();
      },
    (err) => settingsActions.EDIT_ARCHIVE_RETURNS_STATUSES.FAILED(err)
  );

const editArchiveReturnsStatusesOption = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { data }
        } = yield call(() => api.put('category', payload));
        yield put(settingsActions.EDIT_ARCHIVE_RETURNS_STATUSES_OPTION.SUCCESS(data));
        callback && typeof callback === 'function' && callback();
      },
    (err) => settingsActions.EDIT_ARCHIVE_RETURNS_STATUSES_OPTION.FAILED(err)
  );

const addOptionToCategory = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('category/option', payload));
        yield put(settingsActions.ADD_OPTION_TO_CATEGORY.SUCCESS(data.data));
        callback && typeof callback === 'function' && callback();
      },
    (err) => settingsActions.ADD_OPTION_TO_CATEGORY.FAILED(err)
  );

const editOption = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.put('category/option', payload));
        yield put(settingsActions.GET_ARCHIVE_RETURNS_STATUSES.REQUEST());
        callback && typeof callback === 'function' && callback();
      },
    (err) => settingsActions.EDIT_OPTION.FAILED(err)
  );

export default function* () {
  yield takeLatest(settingsTypes.GET_ARCHIVE_RETURNS_STATUSES.REQUEST, getArchiveReturnsStatuses);
  yield takeLatest(settingsTypes.ADD_ARCHIVE_RETURNS_STATUSES.REQUEST, addArchiveReturnsStatuses);
  yield takeLatest(settingsTypes.ADD_ARCHIVE_RETURNS_STATUSES.REQUEST, editArchiveReturnsStatuses);
  yield takeLatest(settingsTypes.GET_ESTIMATION.REQUEST, getEstimation);
  yield takeLatest(settingsTypes.GET_STATES.REQUEST, getStates);
  yield takeLatest(settingsTypes.GET_CHANNELS.REQUEST, getChannels);
  yield takeLatest(settingsTypes.SET_ESTIMATION.REQUEST, setEstimation);

  yield takeLatest(
    settingsTypes.EDIT_ARCHIVE_RETURNS_STATUSES_OPTION.REQUEST,
    editArchiveReturnsStatusesOption
  );
  yield takeLatest(settingsTypes.ADD_OPTION_TO_CATEGORY.REQUEST, addOptionToCategory);
  yield takeLatest(settingsTypes.EDIT_OPTION.REQUEST, editOption);
}
