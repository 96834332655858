import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import { globalRadioTypes } from 'Containers/Common/CustomRadios/constants';
import * as React from 'react';

import styles from './CustomRadios.module.scss';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  }
}));

export default function CustomRadios({
  value,
  label,
  withBorder = false,
  disabled = false,
  options,
  radioType = globalRadioTypes.WIDE_BLACK,
  formTitleStyles = {},
  formLabelStyles = {},
  formControlStyles = {},
  outputComponent,
  handleChange
}) {
  const StyledFormLabel = styled(FormLabel)(() => ({
    ...formTitleStyles,
    color: `${+palette === 2 ? '#fff' : '#000'}!important`
  }));
  const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    ...formLabelStyles,
    ...(withBorder
      ? {
          padding: '15px 10px',
          margin: '0',
          '&:not(:last-child)': { borderBottom: '1px solid #E2E2E2' }
        }
      : {})
  }));
  const StyledFormControl = styled(FormControl)(() => formControlStyles);
  const StyledRadioGroup = styled(RadioGroup)(() =>
    withBorder
      ? { border: '1px solid #E2E2E2', borderRadius: '5px', marginTop: '15px' }
      : { marginTop: '10px' }
  );

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor:
      radioType === globalRadioTypes.WIDE_BLACK
        ? '#000'
        : radioType === globalRadioTypes.THIN_GRAY
        ? '#717579'
        : '',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `radial-gradient(#fff,#fff ${
        radioType === globalRadioTypes.WIDE_BLACK
          ? '15'
          : radioType === globalRadioTypes.THIN_GRAY
          ? '38'
          : ''
      }%,transparent 10%)`,
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: disabled ? '' : '#1e1c1c'
    }
  });

  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const palette = process.env.NEXT_PUBLIC_PALLET || '0';

  return (
    <StyledFormControl disabled={disabled}>
      <StyledFormLabel>{label}</StyledFormLabel>
      <StyledRadioGroup value={value}>
        {options.map((val, idx) => (
          <div key={idx}>
            <StyledFormControlLabel
              value={val?.value}
              control={<BpRadio />}
              label={outputComponent(val?.label || val?.value, val?.iconSrc)}
              onChange={() => handleChange(val?.value)}
            />
            {val?.content && val.value === value ? (
              <div className={styles.accordion}>{val?.content}</div>
            ) : null}
          </div>
        ))}
      </StyledRadioGroup>
    </StyledFormControl>
  );
}
