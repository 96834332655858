import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from 'Containers/Common/TextField';
import React, { useEffect, useState } from 'react';

function InputWithDebounce({ onChange, onReset }) {
  const [inputValue, setInputValue] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    if (debounceTimeout !== null) {
      clearTimeout(debounceTimeout);
    }

      setDebounceTimeout(
        setTimeout(() => {
          onChange(inputValue);
        }, 500)
      );
  }, [inputValue]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      onChange={handleChange}
      placeholder="Cerca..."
      width="300px"
      margin={'0'}
      value={inputValue}
      iconComponent={
        <>
          {onReset && inputValue && (
            <div
              onClick={() => {
                setInputValue('');
                onReset();
              }}>
              <CloseIcon style={{ margin: '0 0px' }} />
            </div>
          )}
          <SearchIcon style={{ margin: '0 10px' }} />
        </>
      }
    />
  );
}

export default InputWithDebounce;
