import ArchiveIcon from 'assets/svg/archive_white_arrow_red.svg';
import CustomCheckbox from 'Containers/Common/CustomCheckbox';
import { invoicesActions } from 'Containers/Invoices/store';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DataTableCustomized } from '../../Common/DataTables';
import styles from './InvoicesTable.module.scss';

export default ({
  invoicesList,
  tableName,
  tableKey,
  paginationOption = true,
  isDetails = false,
  isDelete = false,
  isEdit = false,
  tableNumber = 50
}) => {
  const dispatch = useDispatch();

  const tableColumns = useMemo(
    () => [
      {
        name: 'data',
        label: 'Data ordine',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <span>{data ? moment(data).format('DD/MM/YYYY') : ''}</span>
      },
      {
        name: 'number',
        label: 'Numero ordine',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'fatturaAcconto',
        label: 'Fattura acconto',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => (
          <CustomCheckbox
            checked={data?.value}
            setChecked={() =>
              dispatch(
                invoicesActions.EDIT_INVOICE.REQUEST({ ...data, fatturaAcconto: !data?.value })
              )
            }
            size={20}
          />
        )
      },
      {
        name: 'spedito',
        label: 'Spedito',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <span>{data ? moment(data).format('DD/MM/YYYY') : ''}</span>
      },
      {
        name: 'fatturaNormale',
        label: 'Fattura normale',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <CustomCheckbox disabled={true} checked={data} size={20} />
      },
      {
        name: 'fatturaSaldo',
        label: 'Fattura saldo',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => (
          <CustomCheckbox
            checked={data?.value}
            setChecked={() =>
              dispatch(
                invoicesActions.EDIT_INVOICE.REQUEST({ ...data, fatturaSaldo: !data?.value })
              )
            }
            size={20}
          />
        )
      },
      /*
      {
        name: 'differenzaTotale',
        label: 'Differenza totale',
        filter: false,
        sort: true,
        centered: true
      },
      */
      {
        name: 'customer_name',
        label: 'Nome',
        filter: false,
        sort: true,
        centered: false,
      },
      {
        name: 'vat_company_name',
        label: 'Azienda',
        filter: false,
        sort: true,
        centered: false,
      },
      {
        name: 'concordant_addresses',
        label: 'Stato concordante',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <CustomCheckbox disabled={true} checked={data} size={20} onClick={null}/>
      },
      {
        name: 'vat_verified',
        label: 'Vat verificato',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <CustomCheckbox disabled={true} checked={data} size={20} onClick={null}/>
      },
      {
        name: 'vies_verified',
        label: 'Vies verificato',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <CustomCheckbox disabled={true} checked={data} size={20} onClick={null}/>
      },
      {
        name: 'archived',
        label: 'ARCHIVE',
        filter: false,
        sort: false,
        centered: true,
        customBodyRender: (data) => (
          <img
            onClick={() =>
              dispatch(invoicesActions.EDIT_INVOICE.REQUEST({ ...data, archived: !data?.value }))
            }
            className={styles.archive}
            src={ArchiveIcon}
            alt="archive-icon"
          />
        )
      }
    ],
    []
  );

  return (
    <div className={styles.container__inner}>
      <DataTableCustomized
        tableData={invoicesList?.map((invoice) => ({
          id: invoice?.idOrder,
          data: invoice?.data,
          number: invoice?.number,
          fatturaAcconto: {
            idOrder: invoice?.idOrder,
            value: invoice?.fatturaAcconto,
            fatturaSaldo: invoice?.fatturaSaldo,
            archived: invoice?.archived
          },
          spedito: invoice?.spedito,
          fatturaNormale: invoice?.fatturaNormale,
          fatturaSaldo: {
            idOrder: invoice?.idOrder,
            fatturaAcconto: invoice?.fatturaAcconto,
            value: invoice?.fatturaSaldo,
            archived: invoice?.archived
          },
          differenzaTotale: invoice?.differenzaTotale,
          concordant_addresses: invoice?.concordant_addresses,
          customer_name: invoice?.name,
          vat_company_name: invoice?.vat_company_name,
          vat_verified: invoice?.vat_verified,
          vies_verified: invoice?.vies_verified,
          archived: {
            idOrder: invoice?.idOrder,
            fatturaAcconto: invoice?.fatturaAcconto,
            fatturaSaldo: invoice?.fatturaSaldo,
            value: invoice?.archived
          }
        }))}
        tableName={tableName}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        tableKey={tableKey}
        isDetails={isDetails}
        isDelete={isDelete}
        isEdit={isEdit}
        rowsPerPageNum={tableNumber}
      />
    </div>
  );
};
