import clx from 'classnames';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { ROUTES } from '../../../../../routes/constants';
import useWindowSize from 'utils/hooks/useWindowSize';
import PrimaryButton from '../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../Common/TextField';
import { userActions } from '../../../store';
import styles from './ClientAddAndEditModal.module.scss';
import {
  COMPANY_STATUSES,
  initialCreateValues,
  validationCreateSchema,
  validationEditSchema
} from './constants';

const ClientAddAndEditModal = ({ isEditForm = false, clientToEdit = {}, setIsModalOpen }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialEditValues = {
    id: clientToEdit?.idAzienda,
    nomeAzienda: clientToEdit?.nomeAzienda || '',
    logo: '',
    idAdmin: clientToEdit?.idAdmin || '',
    nome: clientToEdit?.nome || '',
    cognome: clientToEdit?.cognome || '',
    email: clientToEdit?.email || '',
    statusAzienda: clientToEdit?.status || 0,
    sdi: clientToEdit?.sdi || '',
    pec: clientToEdit?.pec || '',
    pIva: clientToEdit?.pIva || '',
    via: clientToEdit?.indirizzo?.via || '',
    citta: clientToEdit?.indirizzo?.citta || '',
    civico: clientToEdit?.indirizzo?.civico || '',
    provincia: clientToEdit?.indirizzo?.provincia || '',
    cap: clientToEdit?.indirizzo?.cap || '',
    idIndirizzo: clientToEdit?.indirizzo?.idIndirizzo || 0
  };

  return (
    <Formik
      enableReinitialize
      initialValues={isEditForm ? initialEditValues : initialCreateValues}
      validationSchema={isEditForm ? validationEditSchema : validationCreateSchema}
      onSubmit={(values) => {
        const { via, citta, civico, provincia, cap, idIndirizzo, ...rest } = values;
        const result = {
          ...rest,
          indirizzo: {
            idIndirizzo,
            via,
            citta,
            civico,
            provincia,
            cap
          }
        };
        if (isEditForm) {
          dispatch(userActions.EDIT_CLIENT.REQUEST(result, setIsModalOpen));
        } else {
          dispatch(
            userActions.ADD_CLIENT.REQUEST({ cliente: result }, (id) =>
              navigate(`${ROUTES.CLIENTS}/${id}`)
            )
          );
        }
      }}>
      {({ errors, touched, values, setFieldValue }) => (
        <Form
          className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}
          autoComplete="off">
          <h2 className={styles.form_title}>{isEditForm ? 'Modifica' : 'Aggiungi'} Cliente</h2>
          <div className={styles.main_form}>
            <Field
              name="nomeAzienda"
              id="nomeAzienda"
              label="Nome Azienda"
              placeholder="Nome Azienda"
              component={TextField}
            />
            <Field name="nome" id="nome" label="Nome" placeholder="Nome" component={TextField} />
            <Field
              name="cognome"
              id="cognome"
              label="Cognome"
              placeholder="Cognome"
              component={TextField}
            />
            <Field
              name="email"
              id="email"
              label="Email"
              placeholder="email"
              type="email"
              component={TextField}
              disabled={isEditForm}
            />
            <Field
              name="pwd"
              id="pwd"
              label="Password"
              placeholder="password"
              disabled={isEditForm}
              showPassword={true}
              component={TextField}
              type="password"
            />
            <Field
              name="sdi"
              id="sdi"
              label="SDI"
              placeholder="SDI"
              type="text"
              component={TextField}
            />
            <Field
              name="pec"
              id="pec"
              label="PEC"
              placeholder="PEC"
              type="text"
              component={TextField}
            />
            <Field
              name="pIva"
              id="pIva"
              label="Partita IVA"
              placeholder="Partita IVA"
              type="text"
              component={TextField}
            />
            {isEditForm
? (
              <div className={styles.select_wrapper}>
                <div className={styles.select_label}>Status</div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(data) => setFieldValue('statusAzienda', data?.value)}
                  value={COMPANY_STATUSES.find(
                    (status) => +status.value === +values?.statusAzienda
                  )}
                  isClearable={true}
                  name="statusAzienda"
                  options={COMPANY_STATUSES}
                />
                {touched.statusAzienda && errors.statusAzienda && (
                  <div className={styles.select_error}>{errors.statusAzienda}</div>
                )}
              </div>
            )
: null}
          </div>
          <hr/>
          <div className={styles.address_section}>
            <Field
              name="via"
              id="via"
              label="Via"
              placeholder="Via"
              type="text"
              component={TextField}
            />
            <Field
              name="citta"
              id="citta"
              label="Citta"
              placeholder="Citta"
              type="text"
              component={TextField}
            />
            <Field
              name="civico"
              id="civico"
              label="Civico"
              placeholder="Civico"
              type="text"
              component={TextField}
            />
            <Field
              name="provincia"
              id="provincia"
              label="Provincia"
              placeholder="Provincia"
              type="text"
              component={TextField}
            />
            <Field
              name="cap"
              id="cap"
              label="Cap"
              placeholder="Cap"
              type="text"
              component={TextField}
            />
          </div>
          <PrimaryButton
            type="submit"
            width="100%"
            text={`${isEditForm ? 'Modifica' : 'Aggiungi'} Cliente`}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ClientAddAndEditModal;
