import { createTheme } from '@mui/material/styles';

const colors = {
  black: '#2E3338',
  viola: '#8944AB',
  backViols: '#00189A',
  darkBlue: '#0040DD',
  middleGray: '#DCDFE3',
  softGray: '#CFD4DF',
  textGray: '#AEAEB2',
  gray: 'rgba(46, 51, 56, 0.9)',
  green: '#248A3D',
  red: '#D70015',
  lightBlue: '#D9EBFF',
  blueHover: '#007AFF',
  blue: '#409CFF',
  white: '#FFFFFF',
  clearGray: '#F6F7F8',
  yellow: '#F5E23D',
  clearWhite: '#FAFAFA',
  darkGray: '#22262A',
  mint: '#58D7C7'
};

const muiTheme = (isMobile) =>
  createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: '36px',
            backgroundColor: colors.blue,
            color: colors.white,
            marginRight: '20px',
            fontWeight: 400,
            fontSize: '14px',
            '&:last-child': {
              marginRight: 0
            },
            '&.Mui-selected': {
              backgroundColor: colors.darkBlue,
              color: colors.white,
              fontWeight: 600
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '30px',
            margin: '0px 0px 10px'
          },
          input: {
            // padding: '5px !important'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            backgroundColor: colors.white,
            marginBottom: '40px',
            '&.noFilters': {
              '& .MuiToolbar-root': {
                display: 'none'
              },
              '& .MuiTableHead-root tr': {
                '& th:first-of-type': {
                  borderRadius: '8px 0 0 0'
                },
                '& th:last-child': {
                  borderRadius: '0 8px 0 0'
                }
              }
            },
            '&.withFilters': {
              '& .MuiTableHead-root': {
                display: 'table-header-group'
              }
            }
          },
          elevation4: {
            boxShadow: 'none'
          },
          rounded: {
            borderRadius: '0'
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            display: isMobile ? 'grid' : 'table-row',
            height: '100px'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            position: 'relative',
            '&.centeredHeader': {
              '& span': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              },
              '& div': {
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }
            },
            '&.cutCell': {
              alignItems: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: '150px'
            },
            '&.centeredColumn': {
              textAlign: 'center'
            },
            '&.boldHeader': {
              '& div': {
                fontWeight: '500',
                width: 'max-content'
              }
            }
          },
          footer: {
            display: 'flex',
            backgroundColor: colors.clearGray,
            justifyContent: 'flex-end',
            borderRadius: '0 0 8px 8px',
            borderBottom: 'none'
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            '& > div': {
              color: colors.white
            }
          },
          data: {
            fontWeight: '500',
            width: 'max-content',
            color: colors.white
          },
          fixedHeader: {
            backgroundColor: colors.backViols
          },
          sortActive: {
            color: colors.white
          },
          toolButton: {
            '&:hover': {
              backgroundColor: `${colors.clearGray}!important`,

              '& *': {
                color: colors.black
              }
            }
          },
          sortAction: {
            display: 'flex',
            alignItems: 'center',

            '& svg path': {
              color: colors.white
            }
          }
        }
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: `${colors.softGray}!important`
            }
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: colors.black,

            '& svg path': {
              color: colors.white
            }
          }
        }
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.backViols,
            borderRadius: '8px 8px 0 0'
          },
          filterPaper: {
            maxWidth: '20%',
            '& > div': {
              paddingTop: '48px'
            }
          },
          actions: {
            '& svg path': {
              color: colors.white
            }
          }
        }
      },
      MUIDataTableSearch: {
        styleOverrides: {
          main: {
            display: 'flex',
            alignItems: 'center',
            color: colors.white,
            '& *': {
              color: `${colors.white}!important`
            }
          },
          searchIcon: {
            marginTop: '0'
          },
          clearIcon: {
            '& *': {
              color: colors.white
            }
          }
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            backgroundColor: colors.black,

            '& *': {
              color: colors.white
            }
          }
        }
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          root: {
            backgroundColor: colors.black,
            margin: '0',
            padding: '0 16px'
          },
          chip: {
            backgroundColor: colors.white,

            '& span': {
              color: colors.black
            }
          }
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            backgroundColor: colors.clearGray,
            '& .MuiGrid-root': {
              maxWidth: 'none',
              padding: '0',
              marginTop: '10px'
            }
          }
        }
      }
    }
  });

export default muiTheme;
