import { Checkbox, FormControlLabel } from '@mui/material';
import NoImage from 'assets/images/no-photo.png';
import ArrowIcon from 'assets/svg/arrow-left-blue.svg';
import EyeIcon from 'assets/svg/eye.svg';
import clx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import useWindowSize from 'utils/hooks/useWindowSize';

import ArchiveIcon from '../../../../assets/svg/archive_white_arrow_red.svg';
import CheckMark from '../../../../assets/svg/check_mark_white.svg';
import PencilIcon from '../../../../assets/svg/pencil_gray.svg';
import Activities from '../../../Common/Activities';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import Chat from '../../../Common/Chat';
import CustomCheckbox from '../../../Common/CustomCheckbox';
import ManageFilesModal from '../../../Common/ManageFilesModal';
import Modal from '../../../Common/Modal';
import SideModal from '../../../Common/SideModal';
import TextFieldMockup from '../../../Common/TextFieldMockup';
import { brokensActions } from '../../store';
import {
  selectActiveBroken,
  selectActiveBrokenStatuses,
  selectBrokenProductById
} from '../../store/selectors';
import BrokenAddAndEditModal from '../BrokensList/containers/BrokenAddAndEditModal';
import styles from './BrokenDetails.module.scss';
import BrokenProductModal from './containers/BrokenProductModal';
import OptionsModal from './containers/OptionsModal';

const commonOptions = [10, 11];

const BrokenDetails = () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { brokenId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(null);
  const [isEditReturnModalOpen, setIsEditReturnModalOpen] = useState(false);
  const [isManageFilesModalOpen, setIsManageFilesModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [activeProductId, setActiveProductId] = useState(null);

  const activeBroken = useSelector(selectActiveBroken());
  const brokenStatuses = useSelector(selectActiveBrokenStatuses());
  const activeProduct = useSelector(selectBrokenProductById(activeProductId));
  const properties = activeBroken?.property?.reduce(
    (acc, property) =>
      !commonOptions.includes(property.idCategory)
        ? { ...acc, extraProperty: [...acc.extraProperty, property] }
        : { ...acc, commonProperty: [...acc.commonProperty, property] },
    { commonProperty: [], extraProperty: [] }
  );

  const activeStatus = (productStatus) =>
    activeBroken.products?.statuses?.find(
      ({ idBreakProductStatuses, name, color }) => +productStatus === +idBreakProductStatuses
    );

  useEffect(() => {
    if (brokenId === 'all' || brokenId === 'archive') {
      navigate(`${ROUTES.BROKENS}/${brokenId}/0`);
    } else {
      dispatch(brokensActions.GET_BROKENS_STATUSES.REQUEST());
      dispatch(brokensActions.GET_BROKEN.REQUEST(brokenId));
    }
  }, [dispatch]);

  const editBroken = (breakObj) => {
    dispatch(
      brokensActions.EDIT_BROKEN.REQUEST({
        breakObj,
        products: [],
        property: []
      })
    );
  };

  const editProductStatus = (product) => {
    dispatch(
      brokensActions.EDIT_BROKEN.REQUEST({
        breakObj: { idBreak: brokenId },
        products: [product],
        property: []
      })
    );
  };

  const openProductImagesSidebar = (productId) => {
    setActiveProductId(productId);
    setIsManageFilesModalOpen(true);
  };

  const openProductEditModal = (productId) => {
    setActiveProductId(productId);
    setIsProductEditModalOpen(true);
  };

  return Object.values(activeBroken).length ? (
    <div
      className={clx(styles.return_detail_container, {
        [styles.return_detail_container_mbl]: isMobile
      })}>
      {location.state?.page >= 0 ? (
        <PrimaryButton
          height="34px"
          fontSize="13px"
          width="100px"
          padding="8px 10px"
          margin="0 0 20px 0"
          borderRadius="40px"
          text={'Indietro'}
          leftIconSrc={ArrowIcon}
          onClick={() =>
            navigate(location.state?.pathname, { state: { page: location.state?.page || 0 } })
          }
        />
      ) : null}
      <div className={styles.return_statuses_wrapper}>
        {brokenStatuses.map((status) => (
          <div className={styles.status_item} key={status.idBreakStatus}>
            <div
              onClick={() =>
                !status.isActive &&
                editBroken({
                  idBreak: activeBroken?.break?.idBreak,
                  status: status.idBreakStatus
                })
              }
              className={clx(
                styles.item_circle,
                { [styles.active]: !!status.isActive },
                { [styles.completed]: !!status.isCompleted }
              )}>
              <img src={CheckMark} alt="" />
              <div className={styles.item_value}>{status.idBreakStatus}</div>
            </div>
            <div className={styles.item_label}>{status.name}</div>
          </div>
        ))}
      </div>
      <div className={styles.return_data_wrapper}>
        <div className={styles.main_content_wrapper}>
          <div className={clx(styles.entity_form)}>
            <div className={styles.form_action}>
              <h2 className={styles.form_title}>Info ordine e cliente della rottura</h2>
              <PrimaryButton
                onClick={() => setIsEditReturnModalOpen(true)}
                text={'Modifica'}
                color={'#5D5D5D'}
                border={'1px solid #5D5D5D'}
                height={'27px'}
                padding={'4px'}
                leftIconSrc={PencilIcon}
              />
            </div>
            <div className={styles.main_form}>
              <TextFieldMockup
                value={`${activeBroken?.break?.number}`}
                label="Numero"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={moment(activeBroken?.break?.date).format('DD/MM/YYYY')}
                label="Data registrazione rottura"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={moment(activeBroken?.break?.orderDate).format('DD/MM/YYYY')}
                label="Data creazione ordine"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.customer?.name}
                label="Nome Cliente"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.customer?.email}
                label="Email Cliente"
                maxWidth={'275px'}
              />
              {properties.commonProperty.map((property) => {
                const value = property.categoryOptions.find(
                  (option) => +option.idOption === +property.selectedOption
                )?.value;
                return (
                  <div key={property.idCategory}>
                    <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                  </div>
                );
              })}
              <TextFieldMockup
                value={activeBroken?.break?.address?.address}
                label="Indirizzo"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.packagesNumber}
                label="Numero colli"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.shipmentWeight}
                label="Peso della spedizione"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.totalOrder}
                label="Totale Ordine"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.carrier}
                label="Corriere di andata"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.channel}
                label="Canale"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeBroken?.break?.insuranceValue}
                label="Val. Assicurato"
                maxWidth={'275px'}
              />
            </div>
          </div>
          <FormControlLabel
            style={{ margin: 0 }}
            labelPlacement="start"
            control={
              <Checkbox
                disabled={true}
                checked={!!activeBroken?.break && !!activeBroken?.break?.contrassegno}
              />
            }
            label={<p className={styles['delivery-cash']}>Contrassegno</p>}
          />
          {!!activeBroken?.products?.products?.length && (
            <div className={styles.products_container}>
              <h2 className={styles.products_title}>Info Prodotti dell’ordine</h2>
              <div className={styles['products-list']}>
                {activeBroken?.products?.products?.map((product) => (
                  <div key={product.idBreakProduct} className={styles['product-item']}>
                    <div className={styles['first-row']}>
                      <div className={styles.checkbox}>
                        <CustomCheckbox
                          checked={!!product.done}
                          setChecked={() => editProductStatus({ ...product, done: !product.done })}
                          size={20}
                        />
                      </div>
                      <div className={styles.image}>
                        <img src={product?.image || NoImage} alt="product-image" />
                      </div>
                      <div className={styles.info}>
                        <div className={styles['name-info']}>
                          <div className={styles['name-status']}>
                            <div className={styles.name}>{product.name}</div>
                            <div
                              className={styles.status}
                              style={{
                                backgroundColor: activeStatus(product?.packaging)?.color
                              }}>
                              {activeStatus(product?.packaging)?.name}
                            </div>
                          </div>
                          <div className={styles['info-files']}>
                            <p>Info cliente</p>
                            <div className={styles['long-value']}>
                              {product?.customerReason}
                            </div>
                            <div className={styles.files}>
                              <img
                                src={EyeIcon}
                                onClick={() => openProductImagesSidebar(product.idBreakProduct)}
                              />
                              <div className={styles.files__qty}>
                                +{product?.images?.length || 0}
                                &nbsp; allegato
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.quantities}>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità ordine</p>
                            <div className={styles['qty-value']}>{product.quantity}</div>
                          </div>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità rese</p>
                            <div className={styles['qty-value']}>{product.brokenQuantity}</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.edit}>
                        <img
                          onClick={() => openProductEditModal(product.idBreakProduct)}
                          src={PencilIcon}
                        />
                      </div>
                    </div>
                    <div className={styles['second-row']}>
                      <div>
                        <p>Nota</p>
                        <div className={styles['long-value']}>{product.note}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles['properties-section']}>
          <div className={styles.return_options_wrapper}>
            <div className={styles.option_block}>
              <div className={styles.option_action}>
                <h2 className={styles.option_title}>Stato Comunicazione</h2>
                <PrimaryButton
                  onClick={() =>
                    setIsOptionsModalOpen({
                      type: 1,
                      title: 'stato comunicazione'
                    })
                  }
                  text={'Modifica'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'4px'}
                  leftIconSrc={PencilIcon}
                />
              </div>
              <div>
                {properties.extraProperty.map((property) => {
                  const value = property.categoryOptions.find(
                    (option) => +option.idOption === +property.selectedOption
                  )?.value;
                  switch (+property.idCategory) {
                    case 14:
                      return (
                        <div key={property.idCategory}>
                          <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                        </div>
                      );
                  }
                })}
              </div>
            </div>
          </div>
          <div className={styles.return_options_wrapper}>
            <div className={styles.option_block}>
              <div className={styles.option_action}>
                <h2 className={styles.option_title}>Stato conclusione</h2>
                <PrimaryButton
                  onClick={() =>
                    setIsOptionsModalOpen({
                      type: 2,
                      title: 'stato conclusione'
                    })
                  }
                  text={'Modifica'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'4px'}
                  leftIconSrc={PencilIcon}
                />
              </div>
              <div>
                {properties.extraProperty.map((property) => {
                  const value = property.categoryOptions.find(
                    (option) => +option.idOption === +property.selectedOption
                  )?.value;
                  switch (+property.idCategory) {
                    case 15:
                    case 16:
                      return (
                        <div key={property.idCategory}>
                          <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                        </div>
                      );
                  }
                })}
                <TextFieldMockup
                  value={activeBroken?.break?.rimborsoTotal}
                  label="Importo rimborso"
                  maxWidth={'275px'}
                />
                {/*<TextFieldMockup*/}
                {/*  value={activeBroken?.break?.rimborsoQt}*/}
                {/*  label="Qt. Rimborso"*/}
                {/*  maxWidth={'275px'}*/}
                {/*/>*/}
                <TextFieldMockup
                  maxWidth={'275px'}
                  value={activeBroken?.break?.rimborsoNote}
                  label={'Nota rimborso'}
                  textarea={true}
                />
              </div>
            </div>
          </div>
          {/*<div className={styles.return_options_wrapper}>*/}
          {/*  <div className={styles.option_block}>*/}
          {/*    <div className={styles.option_action}>*/}
          {/*      <h2 className={styles.option_title}>TEMP TITLE!!!</h2>*/}
          {/*      <PrimaryButton*/}
          {/*        onClick={() => setIsOptionsModalOpen(3)}*/}
          {/*        text={'Modifica'}*/}
          {/*        color={'#5D5D5D'}*/}
          {/*        border={'1px solid #5D5D5D'}*/}
          {/*        height={'27px'}*/}
          {/*        padding={'4px'}*/}
          {/*        leftIconSrc={PencilIcon}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      {properties.extraProperty.map((property) => {*/}
          {/*        const value = property.categoryOptions.find(*/}
          {/*          (option) => +option.idOption === +property.selectedOption*/}
          {/*        )?.value;*/}
          {/*        switch (+property.idCategory) {*/}
          {/*          case 17:*/}
          {/*            return (*/}
          {/*              <div key={property.idCategory}>*/}
          {/*                <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />*/}
          {/*              </div>*/}
          {/*            );*/}
          {/*        }*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.return_options_wrapper}>
            <div className={styles.option_block}>
              <div className={styles.option_action}>
                <h2 className={styles.option_title}>Contestazione corriere</h2>
                <PrimaryButton
                  onClick={() =>
                    setIsOptionsModalOpen({
                      type: 5,
                      title: 'contestazione corriere'
                    })
                  }
                  text={'Modifica'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'4px'}
                  leftIconSrc={PencilIcon}
                />
              </div>
              <div>
                {properties.extraProperty.map((property) => {
                  const value = property.categoryOptions.find(
                    (option) => +option.idOption === +property.selectedOption
                  )?.value;
                  switch (+property.idCategory) {
                    case 18:
                    case 19:
                    case 20:
                      return (
                        <div key={property.idCategory}>
                          <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                        </div>
                      );
                  }
                })}
                <TextFieldMockup
                  value={activeBroken?.break?.amountReceived}
                  label="Importo contestato"
                  maxWidth={'275px'}
                />
                <TextFieldMockup
                  value={activeBroken?.break?.disputedAmount}
                  label="Importo ricevuto"
                  maxWidth={'275px'}
                />
              </div>
            </div>
          </div>
          {!activeBroken?.break?.archived ? (
            <div className={styles.return_options_wrapper}>
              <div className={styles.archive_block}>
                <h2 className={styles.archive_title}>Vuoi Archiviare questo rottura?</h2>
                <PrimaryButton
                  onClick={() =>
                    editBroken({
                      idBreak: activeBroken?.break?.idBreak,
                      archived: true
                    })
                  }
                  text={'Archivia'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'6px'}
                />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.return_options_wrapper}>
                <div className={styles.archive_block}>
                  <h2 className={styles.archive_title}>
                    Vuoi eliminare definitivamente questo Archivio?
                  </h2>
                  <h2 className={styles.archive_title}>
                    Vuoi eliminare definitivamente questo rottura?
                  </h2>
                  <PrimaryButton
                    onClick={() =>
                      editBroken({
                        idBreak: activeBroken?.break?.idBreak,
                        archived: false
                      })
                    }
                    text={'Ripristina'}
                    color={'#D11E1E'}
                    border={'1px solid #D11E1E'}
                    height={'27px'}
                    padding={'6px'}
                    leftIconSrc={ArchiveIcon}
                  />
                </div>
              </div>
              <div className={styles.return_options_wrapper}>
                <div className={styles.archive_block}>
                  <h2 className={styles.archive_title}>Vuoi rimuovere questo rottura Archivio?</h2>
                  <PrimaryButton
                    onClick={() =>
                      dispatch(
                        brokensActions.DELETE_BROKEN.REQUEST(activeBroken?.break?.idBreak, () =>
                          navigate(`${ROUTES.BROKENS}/all/0`)
                        )
                      )
                    }
                    text={'Ripristina'}
                    color={'#D11E1E'}
                    border={'1px solid #D11E1E'}
                    height={'27px'}
                    padding={'6px'}
                    leftIconSrc={ArchiveIcon}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={clx(styles.header_activities)}>
        <Chat entityId={activeBroken?.break?.idBreak} type={'broken'} data={activeBroken?.chat} />
        <Activities data={activeBroken?.activities} />
      </div>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={!!Object.values(isOptionsModalOpen || {}).length}
        setIsModalOpen={() => setIsOptionsModalOpen(null)}>
        <OptionsModal
          options={properties.extraProperty}
          editInfo={isOptionsModalOpen}
          idBreak={activeBroken?.break?.idBreak}
          rimborsoTotal={activeBroken?.break?.rimborsoTotal}
          rimborsoQt={activeBroken?.break?.rimborsoQt}
          rimborsoNote={activeBroken?.break?.rimborsoNote}
          disputedAmount={activeBroken?.break?.disputedAmount}
          amountReceived={activeBroken?.break?.amountReceived}
          setIsModalOpen={() => setIsOptionsModalOpen(null)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isProductEditModalOpen && activeProduct}
        setIsModalOpen={() => setIsProductEditModalOpen(false)}>
        <BrokenProductModal
          activeProduct={activeProduct}
          idBreak={activeBroken?.break?.idBreak}
          setIsModalOpen={() => setIsProductEditModalOpen(false)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isEditReturnModalOpen}
        setIsModalOpen={() => setIsEditReturnModalOpen(false)}>
        <BrokenAddAndEditModal
          isMobile={isMobile}
          isEditForm={true}
          setIsModalOpen={() => setIsEditReturnModalOpen(false)}
          defaultValues={{
            idOrder: activeBroken?.break?.idOrder || '',
            idAlberoOrder: activeBroken?.break?.idOrder || '',
            date: activeBroken?.break?.createdAt || null,
            clientName: activeBroken?.break?.customer?.name || '',
            customer: {
              email: activeBroken?.break?.customer?.email || ''
            }
          }}
          brokenValues={{
            date: activeBroken?.break?.date || null,
            packagesNumber: activeBroken?.break?.packagesNumber || '',
            shipmentWeight: activeBroken?.break?.shipmentWeight || '',
            insuranceValue: activeBroken?.break?.insuranceValue || '',
            totalOrder: activeBroken?.break?.totalOrder || '',
            contrassegno: activeBroken?.break?.contrassegno || false
          }}
          options={properties.commonProperty}
          idBreak={activeBroken?.break?.idBreak}
        />
      </Modal>
      <SideModal isModalOpen={isManageFilesModalOpen && activeProduct}>
        <ManageFilesModal
          product={activeProduct}
          setIsModalOpen={() => setIsManageFilesModalOpen(false)}
          type={'break'}
        />
      </SideModal>
    </div>
  ) : null;
};

export default BrokenDetails;
