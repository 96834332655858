import moment from 'moment/moment';
import React, { useState } from 'react';
import { ROUTES } from 'routes/constants';

import { DataTableDefault } from '../../Common/DataTables';
import styles from './BrokensTable.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectFilterFields, selectHasRemainingBreaks } from 'Containers/Brokens/store/selectors';
import { returnsActions } from 'Containers/Returns/store';
import { brokensActions } from 'Containers/Brokens/store';


export default ({
  brokensList = [],
  tableName,
  tableKey,
  searchText = '',
  paginationOption = true,
  isDetails = true,
  isDelete = false,
  isEdit = false
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const hasRemainingBreaks = useSelector(selectHasRemainingBreaks());
  const [offset, setOffset] = useState(state?.offset || 0);
  const filterFields = useSelector(selectFilterFields());


  const tableColumns = [
    {
      name: 'data',
      label: 'Data',
      filter: false,
      sort: true,
      centered: true,
      customBodyRender: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>
    },
    {
      name: 'id',
      label: 'Numero rottura',
      filter: false,
      sort: true,
      centered: true
    },
    {
      name: 'number',
      label: 'Numero ordine',
      filter: false,
      sort: true,
      centered: true
    },
    {
      name: 'chanel',
      label: 'Canale',
      filter: false,
      sort: true,
      centered: true
    },
    {
      name: 'customer',
      label: 'Cliente',
      filter: false,
      sort: true,
      centered: true
    },
    {
      name: 'statusProperty',
      label: 'Stato Comunicazione',
      filter: false,
      sort: true,
      centered: true,
      customBodyRender: (data) =>
        data?.value ? (
          <div className={styles.status} style={{ backgroundColor: data?.color }}>
            {data?.value}
          </div>
        ) : null
    },
    {
      name: 'statusConclusionProperty',
      label: 'Stato conclusione rottura',
      filter: false,
      sort: true,
      centered: true,
      customBodyRender: (data) =>
        data?.value ? (
          <div className={styles.status} style={{ backgroundColor: data?.color }}>
            {data?.value}
          </div>
        ) : null
    }
  ];

  async function fetchNewBrokens() {
    const newOffset = offset+50;
    setOffset(newOffset);

    await dispatch(
      !filterFields
        ? brokensActions.GET_BROKENS.REQUEST({
          savePrevious: true,
          offset: newOffset
          })
        : brokensActions.GET_BROKENS.REQUEST({
            params: {...filterFields},
            offset: newOffset,
            savePrevious: true
          })
    );
  }


  return (
    <div className={styles.container__inner}>
      <DataTableDefault
        tableData={brokensList?.map((returns) => ({
          id: returns?.idBreak,
          number: returns?.number,
          createdAt: returns?.createdAt,
          data: returns?.date,
          chanel: returns?.channel,
          customer: returns?.customer?.name || '',
          refundNote: returns.refundNote,
          statusConclusionProperty: returns?.statusConclusionProperty,
          statusProperty: returns?.statusProperty
        }))}
        tableName={tableName}
        searchText={searchText}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        offset={offset}
        blockFetchPagination={!hasRemainingBreaks}
        tableKey={tableKey}
        detailsLink={ROUTES.BROKENS}
        isDetails={isDetails}
        isDelete={isDelete}
        isEdit={isEdit}
        fetchNew={fetchNewBrokens}
        rowsPerPageNum={10}
      />
    </div>
  );
};
