import styles from './Card.module.scss';

export const Card = ({ children, className, title, ...props }) => (
  <div className={styles.card} {...props}>
    {title && (
      <>
        <div className={styles.header}>
          <h4 className={styles.title}>{title}</h4>
        </div>
        <hr />
      </>
    )}
    <div className={styles.body}>{children}</div>
  </div>
);
