import { radioTypes } from 'Containers/Orders/containers/OrdersList/containers/FilterModal/constants';

import { brokensTypes } from './actions';

const initialState = {
  brokenStatuses: [],
  brokens: [],
  has_remaining_breaks: false,
  activeBroken: {},
  filterFields: {
    searchString: '',
    from: '',
    to: '',
    shipped: radioTypes.ALL,
    channel: '',
    id_customer: '',
    state: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case brokensTypes.SET_FILTER_FIELDS.SUCCESS:
      return {
        ...state,
        filterFields: action.payload
      };
    case brokensTypes.GET_BROKENS.SUCCESS:
      const { savePrevious, data } = action.payload;
      const brokensById = data.reduce((acc, row) => {
        acc[row.idBreak] = row;
        return acc;
      }, {});
      const brokensUpdated = state.brokens.map((row) => {
        const breakId = brokensById[row.idBreak];
        delete brokensById[row.idBreak];

        return breakId || row;
      });

      return {
        ...state,
        brokens: savePrevious ? [...brokensUpdated, ...Object.values(brokensById)] : data,
        has_remaining_breaks: action.payload.has_remaining_breaks,
      };
    case brokensTypes.GET_BROKEN.SUCCESS:
      return {
        ...state,
        activeBroken: action.payload
      };
    // case brokensTypes.CREATE_RETURN.SUCCESS:
    //   return {
    //     ...state,
    //     // returns: action.payload
    //   };
    case brokensTypes.GET_BROKENS_STATUSES.SUCCESS:
      return {
        ...state,
        brokenStatuses: action.payload
      };
    case brokensTypes.EDIT_BROKEN.SUCCESS:
      return {
        ...state,
        activeBroken: {
          ...state.activeBroken,
          break: {
            ...state.activeBroken.break,
            ...action.payload.break
          },
          property: state.activeBroken.property.map((property) => {
            const activeOption = action.payload.options.find(
              (option) => +option.idCategory === +property.idCategory
            );
            return {
              ...property,
              selectedOption: activeOption?.idOption || property?.selectedOption
            };
          }),
          activities: [action.payload.activity, ...state.activeBroken.activities],
          products: {
            ...state.activeBroken.products,
            products: state.activeBroken.products.products.map((product) => {
              const activeProduct =
                action.payload.products.find(
                  (responseProduct) => +responseProduct.idBreakProduct === +product.idBreakProduct
                ) || {};
              return {
                ...product,
                ...activeProduct
              };
            })
          }
        }
      };
    case brokensTypes.ADD_BROKEN_MESSAGE.SUCCESS:
      return {
        ...state,
        activeBroken: {
          ...state.activeBroken,
          chat: [...state.activeBroken.chat, action.payload]
        }
      };
    case brokensTypes.DELETE_BROKEN_MESSAGE.SUCCESS:
      return {
        ...state,
        activeBroken: {
          ...state.activeBroken,
          chat: state.activeBroken.chat.filter((message) => +message.idMessage !== +action.payload)
        }
      };
    default:
      return { ...state };
  }
};
