import {Field, Form, Formik} from 'formik';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {brokensActions} from '../../../../Brokens/store';
import {returnsActions} from '../../../../Returns/store';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import TextField from '../../../TextField';
import {initialValues} from './constants';
import ChatUploadFile from './containers/ChatUploadFile';
import PreviewFile from './containers/PreviewFile';
import styles from './SendMessage.module.scss';

const SendMessage = ({entityId, type}) => {
  const dispatch = useDispatch();

  const [filesInfo, setFilesInfo] = useState([]);
  const [isFileFullSize, setIsFileFullSize] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, {resetForm}) => {
        switch (type) {
          case 'return': {
            return dispatch(
              returnsActions.ADD_RETURN_MESSAGE.REQUEST(
                {
                  message: { text: values?.message, idReturn: entityId },
                  base64: filesInfo.map(({ url }) => url)
                },
                () => {
                  resetForm({ message: '' });
                  setFilesInfo([]);
                }
              )
            );
          }
          case 'broken': {
            return dispatch(
              brokensActions.ADD_BROKEN_MESSAGE.REQUEST(
                {
                  message: { text: values?.message, idBreak: entityId },
                  base64: filesInfo.map(({ url }) => url)
                },
                () => {
                  resetForm({ message: '' });
                  setFilesInfo([]);
                }
              )
            );
          }
        }
      }}>
      {({values}) => (
        <Form autoComplete="off">
          <div className={styles.wrapper}>
            <div className={styles.icon_wrapper}>
              <ChatUploadFile setUploadFiles={setFilesInfo}/>
            </div>
            <Field
              name="message"
              id="message"
              margin="0 15px"
              placeholder="Scrivi qui il tuo messaggio"
              component={TextField}
            />
            <PrimaryButton
              disabled={!values?.message && !filesInfo.length}
              type="submit"
              width="100%"
              text="Invia"
            />
          </div>
          <div className={styles['list__wrapper']}>
            <div className={styles['list']}>
              {filesInfo?.length
                ? (
                  <PreviewFile
                    filesInfo={filesInfo}
                    setFilesInfo={setFilesInfo}
                    setIsFileFullSize={setIsFileFullSize}
                    isFileFullSize={isFileFullSize}
                  />
                )
                : null}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SendMessage;
