import { userActions } from 'Containers/Users/store';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { usePrevious } from 'utils/hooks/usePrevious';

import { DataTableCustomized } from '../../Common/DataTables';
import { TABLE_KEYS } from '../../Common/DataTables/constants';
import styles from './ClientsTable.module.scss';

export default ({
  clientsList,
  tableName,
  tableKey = TABLE_KEYS.CLIENTS,
  paginationOption = true,
  isDetails = true,
  isDelete = false,
  isEdit = false
}) => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();

  const prevList = usePrevious(clientsList);

  const [offset, setOffset] = useState(state?.offset || 50);

  /*
  {
        name: 'createdAt',
        label: 'Registrato il',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>,
        hidden: true
      },
   */

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        label: 'ID Cliente',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <div>#{data}</div>
      },
      {
        name: 'name',
        label: 'Nome',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'email',
        label: 'Email',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'type',
        label: 'Tipologia',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <div className={styles.type}>{data}</div>
      },
      {
        name: 'city',
        label: 'Città',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'totalOrder',
        label: 'Numeri ordine',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'totalReturn',
        label: 'Numero Resi',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'totalBroken',
        label: 'Numero Rotture',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'totalError',
        label: 'Numero Errori',
        filter: false,
        sort: true,
        centered: true
      }
    ],
    []
  );

  const fetchNewItems = async () => {
    const nextOffset = offset + 50;
    setOffset(nextOffset);
    await dispatch(userActions.GET_ALL_CLIENTS.REQUEST({ offset: nextOffset, savePrevious: true }));
  };

  return (
    <div className={styles.container__inner}>
      <DataTableCustomized
        tableData={clientsList?.map((client) => ({
          id: client?.idCustomer,
          name: client?.name,
          email: client?.email,
          createdAt: client?.registrationDate,
          type: client?.type,
          city: client?.city,
          totalOrder: client?.totalOrder,
          totalReturn: client?.totalReturn,
          totalBroken: client?.totalBroken,
          totalError: client?.totalError
        }))}
        tableName={tableName}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        tableKey={tableKey}
        detailsLink={ROUTES.CLIENTS}
        isDetails={isDetails}
        isDelete={isDelete}
        offset={offset}
        blockFetchPagination={clientsList?.length === prevList?.length}
        fetchNew={fetchNewItems}
        isEdit={isEdit}
      />
    </div>
  );
};
