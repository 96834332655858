import { combineReducers } from 'redux';

import { authReducer } from '../Containers/Auth/store';
import { brokensReducer } from '../Containers/Brokens/store';
import { invoicesReducer } from '../Containers/Invoices/store';
import { ordersReducer } from '../Containers/Orders/store';
import { productsReducer } from '../Containers/Products/store';
import { returnsReducer } from '../Containers/Returns/store';
import { settingsReducer } from '../Containers/Settings/store';
import { supportsReducer } from '../Containers/Supports/store';
import { usersReducer } from '../Containers/Users/store';
import { errorReducer } from '../shared/Error/store/reducer';
import { LoaderReducer } from '../shared/Loader/store/reducer';

export default () =>
  combineReducers({
    loader: LoaderReducer,
    errorReducer,
    authReducer,
    usersReducer,
    returnsReducer,
    ordersReducer,
    supportsReducer,
    settingsReducer,
    productsReducer,
    invoicesReducer,
    brokensReducer
  });
