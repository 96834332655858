import { settingsTypes } from './actions';

const initialState = {
  archiveReturnStatuses: [],
  estimation: undefined,
  states: [],
  channels: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case returnsTypes.GET_RETURNS.SUCCESS:
    //   return {
    //     ...state,
    //     returns: action.payload
    //   };
    // case returnsTypes.GET_RETURN.SUCCESS:
    //   return {
    //     ...state,
    //     activeReturn: action.payload
    //   };
    // case returnsTypes.CREATE_RETURN.SUCCESS:
    //   return {
    //     ...state,
    //     // returns: action.payload
    //   };
    case settingsTypes.GET_ESTIMATION.SUCCESS:
      return {
        ...state,
        estimation: action.payload
      };
    case settingsTypes.GET_STATES.SUCCESS:
      return {
        ...state,
        states: action.payload
      };
    case settingsTypes.GET_CHANNELS.SUCCESS:
      return {
        ...state,
        channels: action.payload
      };
    case settingsTypes.GET_ARCHIVE_RETURNS_STATUSES.SUCCESS:
      return {
        ...state,
        archiveReturnStatuses: action.payload
      };
    case settingsTypes.ADD_OPTION_TO_CATEGORY.SUCCESS:
      return {
        ...state,
        archiveReturnStatuses: action.payload
      };
    default:
      return { ...state };
  }
};
