export const PARCEL_STATUSES = [
  {
    name: 'REGGIA',
    key: 'reggia'
  },
  {
    name: 'PEDANA',
    key: 'pedana'
  },
  {
    name: 'BPM',
    key: 'bmp'
  },
  {
    name: 'SCOTCH CORRIERE',
    key: 'scotchCorriere'
  },
];
