import { appendFilterListToColumns } from 'helpers';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from 'utils/hooks/useWindowSize';

import BinIcon from '../../../../../assets/svg/bin_gray.svg';
import EyeIcon from '../../../../../assets/svg/eye-gray.svg';
import PencilIcon from '../../../../../assets/svg/pencil_gray.svg';
import DataTableFooter from '../DataTableFooter';
import OptionsTableHeadCell from '../OptionsTableHeadCell';

const DataTableCustomized = ({
  tableData,
  tableColumns,
  rowsPerPageNum = 10,
  detailsLink = false,
  tableKey,
  filterOption = true,
  selectableRowsOption = false,
  paginationOption = true,
  extraStringsOptions = false,
  blockFetchPagination = true,
  offset,
  fetchNew,
  children,
  isDetails = false,
  isDelete = false,
  isEdit = false
}) => {
  const { isMobile } = useWindowSize();
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const { pathname, state } = useLocation();
  const uniqueKey = `${pathname}/${tableKey}`;
  const [filterList, setFilterList] = useState(
    JSON.parse(localStorage.getItem(`filterList${uniqueKey}`))
  );
  const [sortingOptions, setSortingOptions] = useState(
    JSON.parse(localStorage.getItem(`sortingOptions${uniqueKey}`))
  );
  const [isReset, setReset] = useState(false);
  const [page, setPage] = useState(state?.page || 0);

  const extraColumns =
    isDetails || isEdit || isDelete
      ? [
          {
            name: 'options',
            label: '',
            options: {
              filter: false,
              sort: false,
              customBodyRender: (id) => (
                <>
                  {isDetails && (
                    <Link to={`${detailsLink}/${id}`} state={{ page, pathname, offset }}>
                      <img className="eye-icon" src={EyeIcon} alt="details" />
                    </Link>
                  )}
                  {isEdit && (
                    <Link to={{ pathname, search: `?${tableKey}Edit=${id}` }}>
                      <img className="eye-icon" src={PencilIcon} alt="edit" />
                    </Link>
                  )}
                  {isDelete && (
                    <Link to={{ pathname, search: `?${tableKey}Delete=${id}` }}>
                      <img className="eye-icon" src={BinIcon} alt="delete" />
                    </Link>
                  )}
                </>
              ),
              setCellHeaderProps: () => ({ className: 'centeredHeader' }),
              setCellProps: () => ({ className: 'centeredColumn cutCell' }),
              customHeadRender: () => (
                <OptionsTableHeadCell
                  key={tableColumns?.length}
                  filterOption={filterOption}
                  setIsFilterOpen={setIsFilterOpen}
                  resetOptions={() => setReset(true)}
                />
              )
            }
          }
        ]
      : [];

  const columns = [
    ...tableColumns?.map((column) => ({
      name: column?.name,
      label: column?.label,
      options: {
        filter: column?.filter,
        sort: column?.sort,
        setCellHeaderProps: () =>
          column?.centered && !isMobile && { className: 'centeredHeader boldHeader' },
        setCellProps: () =>
          column?.centered && !isMobile && { className: 'centeredColumn cutCell' },
        customBodyRender: (data) =>
          column?.customBodyRender ? column?.customBodyRender(data) : data
      }
    })),
    ...extraColumns
  ];

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(`searchText${uniqueKey}`))) {
      setIsFilterOpen(true);
    }

    if (uniqueKey) {
      setFilterList(JSON.parse(localStorage.getItem(`filterList${uniqueKey}`)));
      setSortingOptions(JSON.parse(localStorage.getItem(`sortingOptions${uniqueKey}`)));
    }
  }, [uniqueKey]);

  const options = {
    filterType: 'checkbox',
    filter: filterOption,
    sort: true,
    sortOrder: sortingOptions || {},
    searchOpen: true,
    responsive: 'vertical',
    selectableRows: selectableRowsOption ? 'multiple' : 'none',
    tableBodyMaxHeight: 'auto',
    rowsPerPage: rowsPerPageNum,
    rowsPerPageOptions: [],
    searchText: JSON.parse(localStorage.getItem(`searchText${uniqueKey}`))
      ? JSON.parse(localStorage.getItem(`searchText${uniqueKey}`))
      : '',
    pagination: paginationOption,
    onTableChange: (action, tableState) => {
      if (action === 'propsUpdate') {
        if (isReset) {
          setFilterList(false);
          setSortingOptions(false);
          localStorage.removeItem(`searchText${uniqueKey}`);
          localStorage.removeItem(`filterList${uniqueKey}`);
          localStorage.removeItem(`sortingOptions${uniqueKey}`);
          tableState.filterList = tableState.filterList.map((list) => []);
          tableState.sortOrder = {};
          setReset(false);
        }
      }
      if (action === 'filterChange') {
        setFilterList(tableState.filterList);
        localStorage.setItem(`filterList${uniqueKey}`, JSON.stringify(tableState.filterList));
        return;
      }
      if (action === 'search') {
        localStorage.setItem(`searchText${uniqueKey}`, JSON.stringify(tableState.searchText));
        return;
      }
      if (action === 'sort') {
        const fieldName = columns[tableState.activeColumn].name;
        const direction = tableState.announceText.includes('descending') ? 'desc' : 'asc';
        const option = {
          name: fieldName,
          direction
        };

        localStorage.setItem(`sortingOptions${uniqueKey}`, JSON.stringify(option));
        setSortingOptions(option);
        return;
      }
    },
    customSort: (data, colIndex, client) =>
      data.sort((a, b) => {
        const aVal = a.data[colIndex];
        const bVal = b.data[colIndex];

        if (aVal < bVal) {
          return -1 * (client === 'desc' ? -1 : 1);
        } else if (aVal > bVal) {
          return 1 * (client === 'desc' ? -1 : 1);
        } else {
          return 0;
        }
      }),
    textLabels: {
      body: {
        noMatch: 'Nessun oggetto trovato'
      }
    },
    customTableBodyFooterRender: () => extraStringsOptions && children,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) =>
      paginationOption && (
        <DataTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          blockFetchPagination={blockFetchPagination}
          fetchNew={fetchNew}
          setPage={setPage}
        />
      ),
    page
  };

  return (
    <div>
      {tableData && (
        <MUIDataTable
          className={isFiltersOpen ? 'withFilters' : 'noFilters'}
          key={uniqueKey}
          data={
            tableData?.length > 0 ? tableData.map((item) => ({ ...item, options: item.id })) : []
          }
          columns={appendFilterListToColumns(columns, filterList)}
          options={options}
        />
      )}
    </div>
  );
};

export default DataTableCustomized;
