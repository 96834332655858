import { Api } from 'entries/ApiTransport';
import { call, put, takeLatest } from 'redux-saga/effects';

import sagaAssessor from '../../../utils/sagaAssessor';
import { invoicesActions, invoicesTypes } from './actions';

const api = Api.getInstance();

const getInvoices = () =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { invoices }
        } = yield call(() => api.get('invoices'));
        yield put(invoicesActions.GET_INVOICES.SUCCESS(invoices));
      },
    (err) => invoicesActions.GET_INVOICES.FAILED(err)
  );

const editInvoice = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.put('invoices', payload));
        yield put(invoicesActions.EDIT_INVOICE.SUCCESS(payload));
      },
    (err) => invoicesActions.EDIT_INVOICE.FAILED(err)
  );

export default function* () {
  yield takeLatest(invoicesTypes.GET_INVOICES.REQUEST, getInvoices);
  yield takeLatest(invoicesTypes.EDIT_INVOICE.REQUEST, editInvoice);
}
