import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { ROUTES } from 'routes/constants';

import { DataTableDefault } from '../../Common/DataTables';
import styles from './ReturnsTable.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilterFields, selectHasRemainingReturns } from 'Containers/Returns/store/selectors';
import { useLocation } from 'react-router-dom';
import { returnsActions } from 'Containers/Returns/store';

export default ({
  returnsList = [],
  searchText = '',
  tableName,
  tableKey,
  paginationOption = true,
  isDetails = true,
  isDelete = false,
  isEdit = false
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const hasRemainingReturns = useSelector(selectHasRemainingReturns());
  const [offset, setOffset] = useState(state?.offset || 0);
  const filterFields = useSelector(selectFilterFields());

  const tableColumns = [
    {
      name: 'number',
      label: 'Numero',
      filter: false,
      sort: true,
      centered: true
    },
    {
      name: 'id',
      label: 'Numero reso',
      filter: false,
      sort: true,
      centered: true
    },
    {
      name: 'data',
      label: 'Data richiesta reso',
      filter: false,
      sort: true,
      centered: true,
      customBodyRender: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>
    },
    {
      name: 'refundNote',
      label: 'Motivazione reso',
      filter: false,
      sort: true,
      centered: true,
      customBodyRender: (data) =>
        data?.value ? <div style={{ color: data?.color }}>{data?.value}</div> : null
    },
    {
      name: 'customer',
      label: 'Cliente',
      filter: false,
      sort: true,
      centered: true
    },
    {
      name: 'chanel',
      label: 'Canale Vendita',
      filter: false,
      sort: true,
      centered: true
      /*
      customBodyRender: (data) =>
        data?.value ? (
          <div className={styles.status} style={{ backgroundColor: data?.color }}>
            {data?.value}
          </div>
        ) : null

       */
    },
    {
      name: 'stateOffice',
      label: 'Status Generale',
      filter: false,
      sort: true,
      centered: true,
      customBodyRender: (data) =>
        data?.value ? (
          <div className={styles.status} style={{ backgroundColor: data?.color }}>
            {data?.value}
          </div>
        ) : null
    }
  ];

  async function fetchNewReturns() {
    const newOffset = offset+50;
    setOffset(newOffset);

    await dispatch(
      !filterFields
        ? returnsActions.GET_RETURNS.REQUEST({
          savePrevious: true,
          offset: newOffset
          })
        : returnsActions.GET_RETURNS.REQUEST({
            params: {...filterFields},
            offset: newOffset,
            savePrevious: true
          })
    );
  }

  return (
    <div className={styles.container__inner}>
      <DataTableDefault
        tableData={returnsList?.map((returns) => ({
          id: returns?.idReturn,
          number: returns?.number,
          createdAt: returns?.createdAt,
          data: returns?.date,
          chanel: returns?.channel,
          customer: returns?.customer?.name || '',
          // reggia: returns.reggia ? 'Real' : 'Falso',
          // bmp: returns.bmp ? 'Real' : 'Falso',
          // pedana: returns.pedana ? 'Real' : 'Falso',
          refundNote: returns.reasonProperty,
          stateOffice: returns?.stateOfficeProperty
        }))}
        tableName={tableName}
        searchText={searchText}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        tableKey={tableKey}
        detailsLink={ROUTES.RETURNS}
        offset={offset}
        blockFetchPagination={!hasRemainingReturns}
        isDetails={isDetails}
        isDelete={isDelete}
        isEdit={isEdit}
        fetchNew={fetchNewReturns}
        rowsPerPageNum={10}
      />
    </div>
  );
};
