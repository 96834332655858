import BrtLogo from 'assets/svg/brt-logo.svg';
import EyeIcon from 'assets/svg/eye-white.svg';
import GlsLogo from 'assets/svg/gls-logo.svg';
import PlusIcon from 'assets/svg/plus-icon.svg';
import { ReactComponent as ReloadIcon } from 'assets/svg/reload.svg';
import clx from 'classnames';
import clsx from 'clsx';
import PrimaryButton from 'Containers/Common/Buttons/PrimaryButton';
import { ordersActions } from 'Containers/Orders/store';
import { selectFilterFields, selectHasRemainingOrders, selectQuerySearch } from 'Containers/Orders/store/selectors';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { usePrevious } from 'utils/hooks/usePrevious';

import { DataTableDefault } from '../../Common/DataTables';
import styles from './OrdersTable.module.scss';
import { radioTypes } from 'Containers/Orders/containers/OrdersList/containers/FilterModal/constants';
import { match } from 'ts-pattern';

export default ({
  ordersList,
  tableName,
  tableKey,
  paginationOption = true,
  isDetails = true,
  isDelete = false,
  isEdit = false
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const querySearch = useSelector(selectQuerySearch());
  const filterFields = useSelector(selectFilterFields());

  const prevList = usePrevious(ordersList);
  const hasRemainingOrders = useSelector(selectHasRemainingOrders());

  const [offset, setOffset] = useState(state?.offset || 0);

  const brtValues = ['002', '111', '110', '330', '123', '050', '300'];
  //Pacchi Standard 002, Mini Pedana 111 Italia, Micro Pedana 110, DPD Classic 330, Mini Pedana, 123 Estero, EuroExpress 050, DPD Direct 300
  const brtLabel = {
    '002': 'Pacchi Standard',
    111: 'Mini Pedana Italia',
    110: 'Micro Pedana',
    330: 'DPD Classic',
    123: 'Mini Pedana Estero',
    '050': 'EuroExpress',
    300: 'DPD Direct'
  };
  const tableColumns = useMemo(
    () => [
      {
        name: 'number',
        label: 'Numero',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'date',
        label: 'Data ordine',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'name',
        label: 'Nome',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'channel',
        label: 'Canale',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'escaped',
        label: 'Evaso',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => (
          <div
            className={clx(styles.escaped, {
              [styles.escaped_active]: data,
              [styles.escaped_inactive]: !data
            })}
          />
        )
      },
      {
        name: 'shipping',
        label: 'Spedizione',
        filter: false,
        sort: true,
        centered: true
      },

      {
        name: 'missingProducts',
        label: 'Prodotti mancanti',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) =>
          (
            <div
              style={{
                background: data === 0 ? 'green' : data <= 50 ? 'yellow' : 'red',
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                margin: 'auto'
              }}
            />
          )
      },
      {
        name: 'gls',
        label: <img src={GlsLogo} alt="gls-logo" />,
        centered: false,
        children: [
          {
            name: '',
            label: '',
            filter: false,
            sort: true,
            centered: true
          }
        ]
      },
      {
        name: 'brt',
        label: <img src={BrtLogo} alt="brt-logo" />,
        centered: false,
        children: brtValues?.map((brt) => ({
          label: (
            <div className={styles.brt_labels}>
              <span className={styles.brt_labels_information}>{brt}</span>
              <p className={styles.brt_labels_title}>{brtLabel[brt]}</p>
            </div>
          ),
          filter: false,
          sort: true,
          centered: true
        }))
      },
      {
        name: 'reload',
        label: 'Reload',
        filter: false,
        sort: true,
        centered: false
      }
    ],
    []
  );

  const fetchNewOrders = async () => {
    const formattedShipping = match(filterFields?.shipped)
          .with(radioTypes.ALL, () => '')
          .with(radioTypes.SHIPPED, () => true)
          .with(radioTypes.NOT_SHIPPED, () => false);

    const newOffset = offset+50;
    setOffset(newOffset);
    await dispatch(
      !filterFields
        ? ordersActions.GET_ORDERS.REQUEST({
            params: {canceled: pathname.includes('canceled')},
            offset: newOffset,
            savePrevious: true
          })
        :
          ordersActions.GET_ORDERS.REQUEST({
              params: {...filterFields, shipped: formattedShipping.state.value, canceled: pathname.includes('canceled')},
              offset: newOffset,
              savePrevious: true
          })
    );
  };
  const reloadClick = async (id) => {
    await dispatch(
      ordersActions.GET_SHIPPING.REQUEST(id, () => {
        dispatch(
          !querySearch
            ? ordersActions.GET_ORDERS.REQUEST({
                params: {canceled: pathname.includes('canceled')},
                offset: offset - 50,
                savePrevious: true
              })
            : ordersActions.SEARCH_ORDERS.REQUEST({
                query: querySearch,
                noLimit: true,
                withLoading: true
              })
        );
      })
    );
  };
  return (
    <div>
      <DataTableDefault
        tableData={ordersList?.map((order) => {
          const percentGls = (
            ((order?.estimation?.gls - order?.shipping_cost) * 100) /
            order?.shipping_cost
          ).toFixed(0);
          const percentages = brtValues.map((brt) => ({
            percent: (
              ((order?.estimation?.[`brt${brt}`] - order?.shipping_cost) * 100) /
              order?.shipping_cost
            ).toFixed(0),
            brt
          }));

          return {
            id: order?.idAlberoOrder,
            number: order?.number,
            date: moment(order?.date).format('DD/MM/YYYY'),
            name: order?.customerName,
            channel: order?.channel,
            escaped: order.shipped == 1,
            shipping: order.shipping_cost + '€',
            companyId: order?.companyId,
            missingProducts: order?.estimation?.missingProducts,
            gls: () => {
              const exist = order?.estimation?.gls;
              return (
                <div
                  className={clsx(styles.glsContainer, {
                    [styles.glsContainer__disable]: !exist
                  })}>
                  <div
                    className={clx(styles.subcolumn, {
                      [styles.positive]: percentGls < 0,
                      [styles.negative]: percentGls >= 0
                    })}>
                    {order?.shipping_cost && exist
                      ? `${percentGls >= 0 ? `+${percentGls}` : percentGls}%`
                      : ''}
                  </div>
                  <div className={styles.divider} />
                  <div className={styles.subcolumn}>
                    {exist ? <>{order?.estimation?.gls}&euro;</> : ''}
                  </div>
                </div>
              );
            },
            brt: (
              <div className={styles.brtContainer}>
                {brtValues?.map((brt, idx) => {
                  const percentage = percentages.find((p) => p.brt == brt)?.percent;
                  const exist = order?.estimation?.[`brt${brt}`];
                  return (
                    <div
                      key={idx}
                      className={clsx(styles.glsContainer, {
                        [styles.glsContainer__disable]: !exist
                      })}>
                      <div
                        className={clx(styles.subcolumn, {
                          [styles.positive]: percentage < 0,
                          [styles.negative]: percentage >= 0
                        })}>
                        {order?.estimation?.[`brt${brt}`] && order?.shipping_cost
                          ? `${percentage >= 0 ? `+${percentage}` : percentage}%`
                          : ''}
                      </div>
                      <div className={styles.divider} />
                      <div className={styles.subcolumn}>
                        {order?.estimation?.[`brt${brt}`] ? (
                          <>{order?.estimation?.[`brt${brt}`]}&euro;</>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ),
            reload: (
              <div
                role="button"
                style={{ cursor: 'pointer' }}
                onClick={() => reloadClick(order.idAlberoOrder)}>
                {<ReloadIcon height={'20px'} />}
              </div>
            )
          };
        })}
        tableName={tableName}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        tableKey={tableKey}
        detailsLink={ROUTES.ORDERS}
        isDetails={isDetails}
        isDelete={isDelete}
        isEdit={isEdit}
        offset={offset}
        blockFetchPagination={!hasRemainingOrders}
        fetchNew={fetchNewOrders}
        rowsPerPageNum={10}
      />
    </div>
  );
};
