import 'moment/locale/it';

import { TextField as MaterialTextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import clx from 'classnames';
import { settingsActions } from 'Containers/Settings/store';
import { selectChannels, selectStates } from 'Containers/Settings/store/selectors';
import { Form, Formik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import useWindowSize from 'utils/hooks/useWindowSize';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import { returnsActions } from '../../../../store';
import {
  selectActiveReturnStatuses,
  selectCarriers,
  selectFilterFields,
  selectReasons
} from '../../../../store/selectors';
import styles from '../ReturnAddAndEditModal/ReturnAddAndEditModal.module.scss';

const LocalSelect = (props) => (
  <Select
    components={{
      IndicatorSeparator: () => null
    }}
    menuPortalTarget={document.body}
    menuPosition="fixed"
    styles={{
      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
      control: (provided) => ({
        ...provided,
        borderRadius: '30px',
        marginLeft: '1px',
        minHeight: '36px'
      }),
      input: (provided) => ({
        ...provided,
        input: { fontSize: '16px' }
      })
    }}
    {...props}
  />
);

const FilterModal = ({ setIsModalOpen, setSelectedStatus }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const states = useSelector(selectStates());
  const carriers = useSelector(selectCarriers());
  const channels = useSelector(selectChannels());
  const reasons = useSelector(selectReasons());
  const statuses = useSelector(selectActiveReturnStatuses());
  const initialValues = useSelector(selectFilterFields());

  const stateRef = useRef(null);
  const carrierRef = useRef(null);
  const channelRef = useRef(null);
  const reasonRef = useRef(null);
  const statusRef = useRef(null);

  useEffect(() => {
    dispatch(returnsActions.GET_CARRIERS.REQUEST());
    dispatch(settingsActions.GET_CHANNELS.REQUEST());
    dispatch(returnsActions.GET_REASONS.REQUEST());
    dispatch(settingsActions.GET_STATES.REQUEST());
    dispatch(returnsActions.GET_RETURNS_STATUSES.REQUEST());
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        const { from, to, carrier, channel, status, reason, state, archived } = values;
        const params = {
          from: from ? moment(from).format('YYYY-MM-DD') : '',
          to: to ? moment(to).format('YYYY-MM-DD') : '',
          carrier,
          channel,
          status,
          reason,
          state,
          archived,
        };
        dispatch(returnsActions.SET_FILTER_FIELDS.SUCCESS(params));
        dispatch(returnsActions.GET_RETURNS.REQUEST({params}, setIsModalOpen));
      }}>
      {({ values, setFieldValue, resetForm }) => (
        <Form className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}>
          <h2 className={styles.form_title}>Filtri</h2>
          <div className={styles.main_form}>
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Dal</p>
                <DatePicker
                  label="Dal"
                  value={values.from}
                  onChange={(value) => setFieldValue('from', value)}
                  renderInput={(params) => (
                    <MaterialTextField {...params} size="small" error={false} />
                  )}
                />
              </div>
            </LocalizationProvider>
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Al</p>
                <DatePicker
                  label="Al"
                  value={values.to}
                  onChange={(value) => setFieldValue('to', value)}
                  renderInput={(params) => (
                    <MaterialTextField {...params} size="small" error={false} />
                  )}
                />
              </div>
            </LocalizationProvider>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Nazione</div>
              <LocalSelect
                ref={stateRef}
                onChange={(data) => setFieldValue('state', data?.value)}
                value={states
                  .map(({ state }) => ({ value: state, label: state }))
                  .find((item) => item.value === values?.state)}
                name="disable"
                options={states.map(({ state }) => ({ value: state, label: state }))}
              />
            </div>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Corriere</div>
              <LocalSelect
                ref={carrierRef}
                onChange={(data) => setFieldValue('carrier', data?.value)}
                value={carriers
                  .map(({ id, name }) => ({ value: id, label: name }))
                  .find((item) => item.value === values?.carrier)}
                name="disable"
                options={carriers.map(({ id, name }) => ({ value: id, label: name }))}
              />
            </div>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Canale</div>
              <LocalSelect
                ref={channelRef}
                onChange={(data) => setFieldValue('channel', data?.value)}
                value={channels
                  .map(({ id, name }) => ({ value: id, label: name }))
                  .find((item) => item.value === values?.channel)}
                name="disable"
                options={channels.map(({ id, name }) => ({ value: id, label: name }))}
              />
            </div>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Stato</div>
              <LocalSelect
                ref={statusRef}
                onChange={(data) => {
                  setFieldValue('status', data?.value);
                  if (typeof setSelectedStatus === 'function') {
                    setSelectedStatus(statuses.find((status) => status.idReturnStatus === data?.value));
                  }
                }}
                value={statuses
                  .map(({ idReturnStatus, name }) => ({ value: idReturnStatus, label: name }))
                  .find((item) => item.value === values?.status)}
                name="disable"
                options={statuses.map(({ idReturnStatus, name }) => ({
                  value: idReturnStatus,
                  label: name
                }))}
              />
            </div>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Motivo del reso</div>
              <LocalSelect
                ref={reasonRef}
                onChange={(data) => setFieldValue('reason', data?.value)}
                value={reasons
                  .map(({ idOption, value }) => ({ value: idOption, label: value }))
                  .find((item) => item.value === values?.reason)}
                name="disable"
                options={reasons.map(({ idOption, value }) => ({ value: idOption, label: value }))}
              />
            </div>
          </div>
          <div className={styles.spaced__buttons}>
            <PrimaryButton
              type="button"
              width="40%"
              background="white"
              color="#4E44C6"
              text={'Reset'}
              onClick={() => {
                resetForm();
                carrierRef.current?.clearValue();
                channelRef.current?.clearValue();
                statusRef.current?.clearValue();
                reasonRef.current?.clearValue();
                dispatch(
                  returnsActions.SET_FILTER_FIELDS.SUCCESS({
                    from: '',
                    to: '',
                    carrier: '',
                    channel: '',
                    status: '',
                    reason: ''
                  })
                );
                dispatch(returnsActions.GET_RETURNS.REQUEST({}, setIsModalOpen));
              }}
            />
            <PrimaryButton
              type="submit"
              width="40%"
              background="#4E44C6"
              color="white"
              text={'Apply'}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FilterModal;
