import clx from 'classnames';
import moment from 'moment';
import {useDispatch} from 'react-redux';

import BinIcon from '../../../../../assets/svg/bin_gray.svg';
import FileIcon from '../../../../../assets/svg/doc.svg';
import {imgExtensions} from '../../../../../helpers';
import {downloadUsingFetch} from '../../../../../utils/downloadUsingFetch';
import {brokensActions} from '../../../../Brokens/store';
import {returnsActions} from '../../../../Returns/store';
import styles from './Message.module.scss';

const Message = ({message, isMyMessage, type}) => {
  const dispatch = useDispatch();
  return (
    <div className={clx(styles.message, {[styles.user_message]: isMyMessage})}>
      <div className={styles.delete_button_wrapper}>
        <p className={styles.owner}>
          {message.user?.name} {message.user?.surname}
        </p>
        <img
          src={BinIcon}
          alt="Delete message"
          onClick={() => {
            switch (type) {
              case 'return': {
                return dispatch(
                  returnsActions.DELETE_RETURN_MESSAGE.REQUEST(
                    {
                      idMessage: message.idMessage
                    },
                  )
                );
              }
              case 'broken': {
                return dispatch(
                  brokensActions.DELETE_BROKEN_MESSAGE.REQUEST(
                    {
                      idMessage: message.idMessage
                    },
                  )
                );
              }
            }
          }}
        />
      </div>
      <p className={styles.body}>{message.text}</p>
      <div>
        {message?.file?.map((file) => (
          <div key={file.idFile}>
            {imgExtensions.includes(file.extName)
              ? (
                <img className={styles.photo} src={file.url}/>
              )
              : (
                <div
                  className={styles.file_wrapper}
                  onClick={() =>
                    downloadUsingFetch(file.url, `File_${file.idAllegato}${file.extention}`)
                  }>
                  <img src={FileIcon}/>
                  <p>
                    File #{file.idAllegato}
                    {file.extention}
                  </p>
                </div>
              )}
          </div>
        ))}
      </div>
      <p className={styles.date}>{moment(message.data).format('HH:MM DD/MM/YYYY')}</p>
    </div>
  );
};

export default Message;
