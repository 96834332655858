import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../Common/TextField';
import { brokensActions } from '../../../../store';
import styles from './OptionsModal.module.scss';

const OptionsModal = ({
  options = [],
  editInfo,
  idBreak = false,
  rimborsoTotal = '',
  rimborsoQt = '',
  rimborsoNote = '',
  disputedAmount = '',
  amountReceived = '',
  setIsModalOpen
}) => {
  const dispatch = useDispatch();
  const INITIAL_VALUES = options.reduce(
    (acc, option) => {
      acc[option.idCategory] = option.selectedOption;
      return acc;
    },
    {
      rimborsoTotal,
      rimborsoQt,
      rimborsoNote,
      disputedAmount,
      amountReceived
    }
  );

  const validationEditSchema = yup.object().shape({
    rimborsoQt: yup.number().typeError('Must be a number')
  });
  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      validationEditSchema={validationEditSchema}
      onSubmit={(values) => {
        const {
          rimborsoNote,
          rimborsoQt,
          rimborsoTotal,
          amountReceived,
          disputedAmount,
          ...options
        } = values;
        const arrayOfOptions = Object.entries(options).map(([idCategory, idOption]) => ({
          idCategory,
          idOption
        }));
        const res = {
          breakObj: {
            idBreak,
            rimborsoTotal,
            rimborsoQt,
            rimborsoNote,
            amountReceived,
            disputedAmount
          },
          products: [],
          property: arrayOfOptions
        };
        dispatch(brokensActions.EDIT_BROKEN.REQUEST(res, () => setIsModalOpen(false)));
      }}>
      {({ errors, touched, values, setFieldValue }) => (
        <Form className={styles.entity_form} autoComplete="off">
          <h2 className={styles.form_title}>Modifica {editInfo?.title}</h2>
          <div className={styles.main_form}>
            {options
              ?.filter(({ idCategory }) => {
                switch (editInfo?.type) {
                  case 1:
                    return idCategory === 14;
                  case 2:
                    return idCategory === 15 || idCategory === 16;
                  case 3:
                    return idCategory === 17;
                  case 5:
                    return idCategory === 18 || idCategory === 19 || idCategory === 20;
                }
              })
              ?.map((property) => (
                <div key={property.idCategory} className={styles.select_wrapper}>
                  <div className={styles.select_label}>{property.name}</div>
                  <Select
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onChange={(data) => setFieldValue(property.idCategory, data?.value)}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{
                      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '30px',
                        marginLeft: '1px',
                        minHeight: '36px'
                      }),
                      input: (provided) => ({
                        ...provided,
                        input: { fontSize: '16px' }
                      })
                    }}
                    value={property.categoryOptions
                      .map(({ value, idOption }) => ({ value: idOption, label: value }))
                      .find((item) => +item.value === +values[property.idCategory])}
                    options={property.categoryOptions.map(({ value, idOption }) => ({
                      value: idOption,
                      label: value
                    }))}
                  />
                  {touched[property.idCategory] && errors[property.idCategory] && (
                    <div className={styles.select_error}>{errors[property.idCategory]}</div>
                  )}
                </div>
              ))}
            {editInfo?.type === 2 ? (
              <>
                <Field
                  name="rimborsoTotal"
                  id="rimborsoTotal"
                  label="Importo rimborso"
                  placeholder="Importo rimborso"
                  type="number"
                  component={TextField}
                />
                {/*<Field*/}
                {/*  name="rimborsoQt"*/}
                {/*  id="rimborsoQt"*/}
                {/*  label="Qt. Rimborso"*/}
                {/*  placeholder="Qt. Rimborso"*/}
                {/*  type="number"*/}
                {/*  component={TextField}*/}
                {/*/>*/}
                <Field
                  name="rimborsoNote"
                  id="rimborsoNote"
                  label="Nota rimborso"
                  placeholder="Nota rimborso"
                  textarea={true}
                  component={TextField}
                />
              </>
            ) : null}
            {editInfo?.type === 5 ? (
              <>
                <Field
                  name="amountReceived"
                  id="amountReceived"
                  label="Importo contestato"
                  type="number"
                  placeholder="Importo contestato"
                  component={TextField}
                />
                <Field
                  name="disputedAmount"
                  id="disputedAmount"
                  label="Importo ricevuto"
                  type="number"
                  placeholder="Importo ricevuto"
                  component={TextField}
                />
              </>
            ) : null}
          </div>
          <PrimaryButton type="submit" width="100%" text="Modifica" />
        </Form>
      )}
    </Formik>
  );
};

export default OptionsModal;
