import clx from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../../../assets/svg/arrow-small.svg';
import styles from './NavItem.module.scss';

const NavItem = ({ navItem, isCollapsedSideBar = false }) => {
  const navigate = useNavigate();
  return (
    <li
      className={clx(
        styles.nav__item,
        {[styles['nav-item__active']]: navItem.is_active},
        {[styles['nav-item__collapsed']]: isCollapsedSideBar}
      )}
    >
      {navItem.children ? (
        <>
          <span
            className={clx(styles.item__inner, styles['inner__complex'])}
            onClick={() => navigate(navItem.active_children_link, {state: {isParentLink: true}})}
          >
            <img src={navItem.icon} alt=""/>
            <span>{navItem.label}</span>
            <ArrowIcon
              className={clx(styles.item__complex__arrow, { [styles.arrow__rotate]: navItem.is_active && navItem.is_active_children })}
            />
          </span>
          {navItem.is_active && navItem.is_active_children && (
            <ul className={styles['nav__sub-menu']}>
              {navItem.children.map((subItem, idx) => (
                <li
                  key={idx}
                  className={clx(styles['nav__sub-wrapper'])}
                >
                  <div className={styles.union}/>
                  <div
                    onClick={() => navigate(subItem.link)}
                    className={clx(styles['nav__sub-item'], {
                      [styles['sub-item__active']]: subItem.is_active
                    })}
                  >
                    <span>{subItem.label}</span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <span className={styles.item__inner} onClick={() => navigate(navItem.link)}>
          <img src={navItem.icon} alt=""/>
          <span>{navItem.label}</span>
          <ArrowIcon className={styles.item__arrow} />
        </span>
      )}
    </li>
  );
};

export default NavItem;
