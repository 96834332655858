import clx from 'classnames';

import styles from './TextFieldMockup.module.scss';

const TextFieldMockup = (
  {
    label,
    value = '',
    height = 'fit-content',
    minHeight = '36px',
    bgColor = 'transparent',
    color = '#4E4E4E',
    borderRadius = '15px',
    border = '1px solid #EAEAEA',
    maxWidth = '100%',
    textarea = false,
  }
) => (
  <div className={styles.field__wrapper}>
    <span className={styles['field__label']}>{label}</span>
    <div
      className={clx(
        styles['field__value'],
        { [styles['field__value__text']]: textarea },
      )}
      style={{
        borderRadius: `${borderRadius}`,
        border,
        background: bgColor,
        color,
        minHeight,
        height,
        maxWidth
      }}
    >
      {typeof value === 'function' ? value() : value}
    </div>
  </div>
);

export default TextFieldMockup;
