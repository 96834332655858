export const radioTypes = {
  ALL: 'ALL',
  SHIPPED: 'SHIPPED',
  NOT_SHIPPED: 'NOT_SHIPPED'
};

export const radioLabels = {
  [radioTypes.ALL]: 'Tutto',
  [radioTypes.SHIPPED]: 'Spedito',
  [radioTypes.NOT_SHIPPED]: 'Non inviato'
};
