import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import LogoImage from '../../../../assets/images/logo.svg';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import TextField from '../../../Common/TextField';
import { authActions } from '../../store';
import styles from './SignIn.module.scss';

export default () => {
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = {
    email: yup
      .string('Inserisci la tua email')
      .email('Inserisci una email valida')
      .required('Email è richiesta'),
    password: yup
      .string()
      .min(3, 'La password è troppo corta')
      .max(100, 'Your password is very big!')
      .required('Password richiesta')
  };
  return (
    <div className={styles.container__inner}>
      <div className={styles['login__container']}>
        <img src={LogoImage} alt="logo" className={styles.logo} />
        <Formik
          initialValues={initialValues}
          validationSchema={yup.object().shape(validationSchema)}
          onSubmit={(values) => {
            dispatch(authActions.SIGN_IN.REQUEST(values));
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles['login__form']}>
              <Field
                name="email"
                id="email"
                label="Email"
                placeholder="Email"
                component={TextField}
                type="email"
                inputColor="white"
              />
              <Field
                name="password"
                id="password"
                label="Password"
                placeholder="Password"
                showPassword={true}
                component={TextField}
                type="password"
                inputColor="white"
              />
              <PrimaryButton width="100%" text="Accedi" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
