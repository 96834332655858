export const ROUTES = {
  SIGN_IN: '/login',
  RETURNS: '/returns',
  ASSISTANCE_REQUEST: '/assistance_request',
  ORDERS: '/orders',
  INVOICES: '/invoices',
  PRODUCTS: '/products',
  CLIENTS: '/clients',
  USERS: '/users',
  BROKENS: '/brokens',
  ERRORS: '/errors',
  DELIVERY: '/delivery',
  STATISTIC: '/statistic',
  SETTINGS: '/settings'
};

export const ROLES = {
  USER: {
    id: 0,
    label: 'User'
  },
  CLIENT: {
    id: 1,
    label: 'Client'
  },
  DEVELOPER: {
    id: 2,
    label: 'Developer'
  },
  SUPER_ADMIN: {
    id: 3,
    label: 'Super-admin'
  }
};
