import TableHeadCell from 'Containers/Common/DataTables/containers/TableHeadCell';
import MUIDataTable from 'mui-datatables';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from 'utils/hooks/useWindowSize';

import BinIcon from '../../../../../assets/svg/bin_gray.svg';
import EyeIcon from '../../../../../assets/svg/eye-gray.svg';
import PencilIcon from '../../../../../assets/svg/pencil_gray.svg';
import DataTableFooter from '../DataTableFooter';
import OptionsTableHeadCell from '../OptionsTableHeadCell';

const DataTableDefault = ({
  tableData,
  tableColumns,
  rowsPerPageNum = 10,
  searchText = '',
  detailsLink = '',
  isDetails = false,
  isDelete = false,
  isEdit = false,
  tableKey,
  filterOption = true,
  offset,
  selectableRowsOption = false,
  paginationOption = true,
  extraStringsOptions = false,
  blockFetchPagination = true,
  fetchNew,
  children
}) => {
  const { isMobile } = useWindowSize();
  const { pathname, state } = useLocation();

  const [isFiltersOpen, setIsFilterOpen] = useState(false);

  const [page, setPage] = useState(state?.page || 0);

  const extraColumns =
    isDetails || isEdit || isDelete
      ? [
          {
            name: 'options',
            label: '',
            options: {
              filter: false,
              sort: false,
              setCellHeaderProps: () => ({ className: 'centeredHeader' }),
              setCellProps: () => ({ className: 'centeredColumn cutCell' }),
              customHeadRender: () => (
                <OptionsTableHeadCell
                  key={tableColumns?.length}
                  length={tableColumns?.length}
                  filterOption={filterOption}
                  setIsFilterOpen={setIsFilterOpen}
                />
              ),
              customBodyRender: (id) => (
                <>
                  {isDetails && (
                    <Link to={`${detailsLink}/${id}`} state={{ page, pathname, offset }}>
                      <img className="eye-icon" src={EyeIcon} alt="details" />
                    </Link>
                  )}
                  {isEdit && (
                    <Link to={{ pathname, search: `?${tableKey}Edit=${id}` }}>
                      <img className="eye-icon" src={PencilIcon} alt="edit" />
                    </Link>
                  )}
                  {isDelete && (
                    <Link to={{ pathname, search: `?${tableKey}Delete=${id}` }}>
                      <img className="eye-icon" src={BinIcon} alt="delete" />
                    </Link>
                  )}
                </>
              )
            }
          }
        ]
      : [];
  const columns = [
    ...tableColumns?.map((column) => ({
      name: column?.name,
      label: column?.label,
      options: {
        filter: column?.filter,
        sort: column?.sort,
        setCellHeaderProps: () =>
          column?.centered && !isMobile && { className: 'centeredHeader boldHeader' },
        setCellProps: () => ({
          className: 'centeredColumn cutCell',
          style: { minWidth: `${column?.children?.length * 100}px` }
        }),
        customHeadRender: (data) => (
          <TableHeadCell label={data.label} subtitles={column?.children} />
        ),
        customBodyRender: (data) =>
          column?.customBodyRender ? column?.customBodyRender(data) : data
      }
    })),
    ...extraColumns
  ];

  const options = {
    filterType: 'checkbox',
    filter: filterOption,
    sort: true,
    searchOpen: true,
    search: true,
    searchText,
    responsive: 'vertical',
    selectableRows: selectableRowsOption ? 'multiple' : 'none',
    tableBodyMaxHeight: 'auto',
    rowsPerPage: rowsPerPageNum,
    rowsPerPageOptions: [],
    pagination: paginationOption,
    textLabels: {
      body: {
        noMatch: 'Nessun oggetto trovato'
      }
    },
    customTableBodyFooterRender: () => extraStringsOptions && children,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) =>
      paginationOption && (
        <DataTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          blockFetchPagination={blockFetchPagination}
          fetchNew={fetchNew}
          setPage={setPage}
        />
      ),
    page
  };
  if (searchText) {
    options.customSearchRender = () => null;
  }
  return (
    <div>
      {tableData && (
        <MUIDataTable
          className={isFiltersOpen ? 'withFilters' : 'noFilters'}
          key={tableKey}
          data={
            tableData?.length > 0 ? tableData.map((item) => ({ ...item, options: item.id })) : []
          }
          columns={columns}
          options={options}
        />
      )}
    </div>
  );
};

export default DataTableDefault;
