import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PencilIcon from '../../../../assets/svg/pencil_gray.svg';
import PlusIcon from '../../../../assets/svg/plus-circle.svg';
import useWindowSize from 'utils/hooks/useWindowSize';
import TableHeader from '../../../Common/DataTables/containers/TableHeader/TableHeader';
import Modal from '../../../Common/Modal';
import { settingsActions } from '../../store';
import { selectArchiveReturnStatuses } from '../../store/selectors';
import styles from './ArchiveReturnStatuses.module.scss';
import CategoryAddAndEditModal from './containers/CategoryAddAndEditModal';

export default () => {
  const dispatch = useDispatch();
  const returnArchiveStatuses = useSelector(selectArchiveReturnStatuses());

  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState({});
  const { isMobile } = useWindowSize();

  useEffect(() => {
    dispatch(settingsActions.GET_ARCHIVE_RETURNS_STATUSES.REQUEST());
  }, [dispatch]);

  return (
    <div className={styles.container__inner}>
      <TableHeader
        title="Statuses"
        buttonText="Aggiungi Status"
        // showAddButton={true}
        // action={() => setIsAddStatusModalOpen(true)}
      />
      <div className={styles.cards_wrapper}>
        {returnArchiveStatuses.map((status) => (
          <div key={status.idCategory} className={styles.cards_item}>
            <div className={styles.item_header}>
              <p>{status.title}</p>
              <img
                onClick={() => setIsCategoryModalOpen({ idCategory: status.idCategory })}
                src={PlusIcon}
              />
            </div>
            {status.options.map((option) => (
              <div key={option.idOption} className={styles.item_option}>
                <div className={styles.option_values}>
                  <div className={styles.value_color} style={{ backgroundColor: option.color }} />
                  <div className={styles.value_description}>
                    {option.value}
                    {option.internalCode ? `${option.internalCode}` : null}
                  </div>
                </div>
                <img
                  src={PencilIcon}
                  onClick={() =>
                    setIsCategoryModalOpen({ ...option, idCategory: status.idCategory })
                  }
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={Object.values(isCategoryModalOpen).length}
        setIsModalOpen={() => setIsCategoryModalOpen({})}>
        <CategoryAddAndEditModal
          isEditForm={Object.values(isCategoryModalOpen).length > 1}
          dataForEdit={isCategoryModalOpen}
          setIsModalOpen={() => setIsCategoryModalOpen({})}
        />
      </Modal>
    </div>
  );
};
