import ArrowIcon from 'assets/svg/arrow-left-blue.svg';
import EyeIcon from 'assets/svg/eye-white.svg';
import PlusIcon from 'assets/svg/plus-icon.svg';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import useWindowSize from 'utils/hooks/useWindowSize';

import BrokenAddAndEditModal from '../../../Brokens/containers/BrokensList/containers/BrokenAddAndEditModal';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import Modal from '../../../Common/Modal';
import TextFieldMockup from '../../../Common/TextFieldMockup';
import ReturnAddAndEditModal from '../../../Returns/containers/ReturnsList/containers/ReturnAddAndEditModal';
import { ordersActions } from '../../store';
import { selectOrder, selectShipping } from '../../store/selectors';
import { selectChannels } from 'Containers/Settings/store/selectors';
import styles from './OrderDetails.module.scss';
import { settingsActions } from 'Containers/Settings/store';
import Select from 'react-select';
import { Button } from '@mui/material';



function syntaxHighlight(json) {
  if (!json) return ''; //no JSON from response

  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + styles[cls] + '">' + match + '</span>';
    }
  );
}

function formattedDate({ state, province, city, postalCode, address }) {
  return `${state || ''} ${province || ''} ${city || ''} ${postalCode || ''} ${address || ''}`;
}

const LocalSelect = (props) => (
  <Select
    components={{
      IndicatorSeparator: () => null
    }}
    menuPortalTarget={document.body}
    menuPosition="fixed"
    styles={{
      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
      control: (provided) => ({
        ...provided,
        borderRadius: '30px',
        marginLeft: '1px',
        minHeight: '36px'
      }),
      input: (provided) => ({
        ...provided,
        input: { fontSize: '16px' }
      })
    }}
    {...props}
  />
);


const OrderDetails = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [isCreateReturnModalOpen, setIsCreateReturnModalOpen] = useState(false);
  const [isCreateBrokenModalOpen, setIsCreateBrokenModalOpen] = useState(false);
  const shipping = useSelector(selectShipping());
  const order = useSelector(selectOrder());
  const channels = useSelector(selectChannels());
  const channelRef = useRef(null);
  const [fieldValue, setFieldValue] = useState(null);
  const [showButton, setShowButton] = useState(false);


  useEffect(() => {
    dispatch(settingsActions.GET_CHANNELS.REQUEST());
  }, []);

  useEffect(() => {
    setFieldValue(order.channelId);
  }, [order]);


  useEffect(() => {
    window.history.replaceState({}, document.title);
    dispatch(ordersActions.GET_ORDER.REQUEST(orderId));
    return () => dispatch(ordersActions.GET_SHIPPING.CLEAR());
  }, []);

  const infoDetails = [
    {
      id: 1,
      label: 'Numero',
      value: order?.number
    },
    {
      id: 2,
      label: 'Corriere di andata',
      value: order?.carrier
    },
    {
      id: 3,
      label: 'Data creazione ordine',
      value: moment(order?.date).format('DD/MM/YYYY')
    },
    {
      id: 4,
      label: 'Nome cliente',
      value: `${order?.customer?.name || ''}`
    },
    {
      id: 5,
      label: 'Canale',
      value: order?.channel
    },
    {
      id: 6,
      label: 'Email Cliente',
      value: order?.customer?.email
    },
    {
      id: 7,
      label: 'Indirizzo di spedizione',
      value: formattedDate(order?.shippingAddress ?? {})
    },
    {
      id: 8,
      label: 'Indirizzo di fatturazione',
      value: formattedDate(order?.billingAddress ?? {})
    },
    {
      id: 9,
      label: 'Costo di spedizione',
      value: order?.shipping_cost
    },
    {
      id: 10,
      label: 'Totale',
      value: order?.value
    },
    {
      id: 11,
      label: 'Data di spedizione',
      value: order?.shipping_date ? moment(order?.shipping_date).format('DD/MM/YYYY') : null
    },
    {
      id: 12,
      label: 'Tracking',
      value: order?.tracking
    }
  ];

  const actions = [
    {
      id: 1,
      value: order?.return,
      label: 'Reso'
    },
    {
      id: 2,
      value: order?.break,
      label: 'Rottura'
    },
    {
      id: 3,
      value: order?.error,
      label: 'Errore'
    }
  ];

  function handleEdit() {
    const body = {idAlberoOrder: orderId, idChannel: fieldValue};
    dispatch(ordersActions.EDIT_CHANNEL.REQUEST(body, setShowButton(false)));
  }


  return (
    <>
      {location.state?.page >= 0 ? (
        <PrimaryButton
          height="34px"
          fontSize="13px"
          width="100px"
          padding="8px 10px"
          margin="0 0 20px 0"
          borderRadius="40px"
          text={'Indietro'}
          leftIconSrc={ArrowIcon}
          onClick={() =>
            navigate(location.state?.pathname, {
              state: {
                page: location.state?.page || 0,
                offset: location.state?.offset || 0,
                withoutRefreshItems: true
              }
            })
          }
        />
      ) : null}
      <div className={styles['container-inner']}>
        <div className={styles.info}>
          <div className={styles['block-wrapper']}>
            <p className={styles.info__title}>Info ordine del cliente</p>
            <div className={styles.info__body}>
              {infoDetails.map((i) => {
                if (i.id === 2) {
                  return (
                    <div key={i.id}>
                      {i.label}
                      <div style={{ height: '5px' }} />
                      <LocalSelect
                        ref={channelRef}
                        onChange={(data) => {
                          setFieldValue(data?.value);
                          setShowButton(true);
                        }}
                        value={channels
                          .map(({ id, name }) => ({ value: id, label: name }))
                          .find((item) => item.value === fieldValue)}
                        options={channels.map(({ id, name }) => ({ value: id, label: name }))}
                      />
                      {showButton && (<div style={{display: 'flex', justifyContent: 'flex-end', padding: '5px 0'}}>
                        <Button onClick={() => handleEdit()}>
                          Salva
                        </Button>
                      </div>
                      )}
                    </div>
                  );
                }
                else return (<TextFieldMockup key={i.id} maxWidth={'275px'} value={i.value} label={i.label} />);
              })}
            </div>
          </div>
          <div>
            <p className={styles.info__title}>Info Prodotti dell’ordine</p>
            <div className={styles.products}>
              {order?.products?.map((product) => (
                <div key={product.idProduct} className={styles.product}>
                  <div className={styles.product__header}>Info prodotto</div>
                  <div className={styles.product__body}>
                    <img src={product.image} alt="img" />
                    <p className={styles.product__body__description}>
                      {product.name} ({product.sku})
                    </p>
                    <div className={styles.product__body__qty}>Quantità {product.quantity}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.made__box}>
          <div className={styles.made}>
            <div className={styles.made__actions}>
              {actions.map((action) => (
                <div key={action.id} className={styles.actions__item}>
                  <p className={styles.made__title}>{action.label}</p>
                  <PrimaryButton
                    onClick={() => {
                      switch (action.id) {
                        case 1: {
                          action?.value
                            ? navigate(`${ROUTES.RETURNS}/${action?.value}`)
                            : setIsCreateReturnModalOpen(true);
                          break;
                        }
                        case 2: {
                          action?.value
                            ? navigate(`${ROUTES.BROKENS}/${action?.value}`)
                            : setIsCreateBrokenModalOpen(true);
                          break;
                        }
                        case 3: {
                          return;
                        }
                      }
                    }}
                    leftIconSrc={action.value ? EyeIcon : PlusIcon}
                    background={`${action.value ? '#4E44C6' : '#E1DFDF'}`}
                    text={`${action.value ? `Vedi ${action.label}` : `Crea ${action.label}`}`}
                    fontSize="10px"
                    color={`${action.value ? '#fff' : '#5D5D5D'}`}
                    padding="5px 10px"
                    border=""
                    leftIconHeight="10px"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={styles.made}>
            <div className={styles.made__actions}>
              <div className={styles.actions__item}>
                <p className={styles.made__title}>Preventivo spedizione</p>
                <PrimaryButton
                  onClick={() => {
                    dispatch(ordersActions.GET_SHIPPING.REQUEST(orderId));
                  }}
                  leftIconSrc={PlusIcon}
                  background="#E1DFDF"
                  text="Genera"
                  fontSize="10px"
                  color="#5D5D5D"
                  padding="5px 10px"
                  border=""
                  leftIconHeight="10px"
                />
                {!!Object.values(shipping).length && (
                  <div className={styles.code__box}>
                    <pre
                      className={styles['pre']}
                      dangerouslySetInnerHTML={{
                        __html: syntaxHighlight(JSON.stringify(shipping, undefined, 4))
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isCreateReturnModalOpen}
        setIsModalOpen={() => setIsCreateReturnModalOpen(false)}>
        <ReturnAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsCreateReturnModalOpen(false)}
          defaultValues={{
            idOrder: order?.idOrder || '',
            idAlberoOrder: order?.idAlberoOrder || '',
            CorriereDiAndata: order?.CorriereDiAndata || '',
            channelCarrier: order?.channelCarrier || '',
            channel: order?.channel || '',
            date: order?.date || null,
            clientName: order?.customer?.name || '',
            customer: {
              email: order?.customer?.email || ''
            }
          }}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isCreateBrokenModalOpen}
        setIsModalOpen={() => setIsCreateBrokenModalOpen(false)}>
        <BrokenAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsCreateBrokenModalOpen(false)}
          defaultValues={{
            idOrder: order?.idOrder || '',
            idAlberoOrder: order?.idAlberoOrder || '',
            CorriereDiAndata: order?.CorriereDiAndata || '',
            channelCarrier: order?.channelCarrier || '',
            channel: order?.channel || '',
            date: order?.date || null,
            clientName: order?.customer?.name || '',
            customer: {
              email: order?.customer?.email || ''
            }
          }}
        />
      </Modal>
    </>
  );
};

export default OrderDetails;
