import styles from './SideModal.module.scss';

const SideModal = ({ children, isModalOpen }) => (
  <>
    {isModalOpen && (
      <div className={styles.modal__wrapper}>
        <div
          className={styles.modal}
        >
          {children}
        </div>
      </div>
    )}
  </>
);

export default SideModal;
