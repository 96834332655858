import { Api } from 'entries/ApiTransport';
import { objectToQuery } from 'helpers';
import { call, put, takeLatest } from 'redux-saga/effects';

import sagaAssessor from '../../../utils/sagaAssessor';
import { supportsActions, supportsTypes } from './actions';

const api = Api.getInstance();

const getSupports = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { params } = payload;
        const query = objectToQuery({ ...params }, { stayBoolean: true });
        const { data } = yield call(() => api.get(`support${query}`));
        const { supports, status } = data;

        yield put(supportsActions.GET_SUPPORTS.SUCCESS({ supports, status }));
        typeof callback === 'function' && callback();
      },
    (err) => supportsActions.GET_SUPPORTS.FAILED(err)
  );

const getSupport = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`support/${payload}`));
        yield put(supportsActions.GET_SUPPORT.SUCCESS(data.data));
        typeof callback === 'function' && callback();
      },
    (err) => supportsActions.GET_SUPPORT.FAILED(err)
  );

const editSupport = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { id, ...rest } = payload;
        const { data } = yield call(() => api.put(`support/${id}`, rest));
        // yield put(supportsActions.EDIT_SUPPORT.SUCCESS(data.data));
        typeof callback === 'function' && callback();
      },
    (err) => supportsActions.EDIT_SUPPORT.FAILED(err)
  );

export default function* () {
  yield takeLatest(supportsTypes.GET_SUPPORTS.REQUEST, getSupports);
  yield takeLatest(supportsTypes.GET_SUPPORT.REQUEST, getSupport);
  yield takeLatest(supportsTypes.EDIT_SUPPORT.REQUEST, editSupport);
}
