import actionCreator from '../../../utils/actionCreator';

const ticketsActionTypes = [
  'GET_ARCHIVE_RETURNS_STATUSES',
  'ADD_ARCHIVE_RETURNS_STATUSES',
  'EDIT_ARCHIVE_RETURNS_STATUSES',
  'EDIT_ARCHIVE_RETURNS_STATUSES_OPTION',
  'ADD_OPTION_TO_CATEGORY',
  'EDIT_OPTION',
  'GET_ESTIMATION',
  'SET_ESTIMATION',
  'GET_STATES',
  'GET_CHANNELS'
];

export const { types: settingsTypes, actions: settingsActions } = actionCreator(ticketsActionTypes);
