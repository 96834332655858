import 'moment/locale/it';

import SearchIcon from '@mui/icons-material/Search';
import { TextField as MaterialTextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import clx from 'classnames';
import CustomRadios from 'Containers/Common/CustomRadios';
import {
  radioLabels,
  radioTypes
} from 'Containers/Orders/containers/OrdersList/containers/FilterModal/constants';
import { ordersActions } from 'Containers/Orders/store';
import { selectFilterFields } from 'Containers/Orders/store/selectors';
import { settingsActions } from 'Containers/Settings/store';
import { selectChannels, selectStates } from 'Containers/Settings/store/selectors';
import { userActions } from 'Containers/Users/store';
import { Form, Formik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { match } from 'ts-pattern';
import useWindowSize from 'utils/hooks/useWindowSize';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import styles from './FilterModal.module.scss';

const LocalSelect = (props) => (
  <Select
    components={{
      IndicatorSeparator: () => null
    }}
    menuPortalTarget={document.body}
    menuPosition="fixed"
    styles={{
      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
      control: (provided) => ({
        ...provided,
        borderRadius: '30px',
        marginLeft: '1px',
        minHeight: '36px'
      }),
      input: (provided) => ({
        ...provided,
        input: { fontSize: '16px' }
      })
    }}
    {...props}
  />
);

const FilterModal = ({ closeModal }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const states = useSelector(selectStates());
  const channels = useSelector(selectChannels());
  const initialValues = useSelector(selectFilterFields());

  const stateRef = useRef(null);
  const channelRef = useRef(null);

  useEffect(() => {
    dispatch(settingsActions.GET_CHANNELS.REQUEST());
    dispatch(settingsActions.GET_STATES.REQUEST());
  }, []);

  const searchCustomers = (inputValue, callback) => {
    dispatch(
      userActions.SEARCH_CLIENTS.REQUEST(inputValue, (value) =>
        callback(
          value.map((item) => ({
            value: item,
            label: (
              <div>
                <div>#{item.idCustomer}</div>
                {item?.name && <div>{item.name}</div>}
                {item?.email && <div>{item.email}</div>}
              </div>
            )
          }))
        )
      )
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        const { from, to, shipped, channel, id_customer, state } = values;
        const params = {
          from: from ? moment(from).format('YYYY-MM-DD') : '',
          to: to ? moment(to).format('YYYY-MM-DD') : '',
          channel,
          id_customer,
          state,
          canceled: window.location.pathname.includes('canceled'),
          free_text: ''
        };
        const formattedShipping = match(shipped)
          .with(radioTypes.ALL, () => '')
          .with(radioTypes.SHIPPED, () => true)
          .with(radioTypes.NOT_SHIPPED, () => false);
        dispatch(
          ordersActions.SET_FILTER_FIELDS.SUCCESS({
            ...params,
            shipped,
            searchString: values.searchString
          })
        );
        dispatch(
          ordersActions.GET_ORDERS.REQUEST(
            { params: { ...params, shipped: formattedShipping.state.value } },
            closeModal
          )
        );
      }}>
      {({ values, setFieldValue, resetForm }) => (
        <Form className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}>
          <h2 className={styles.form_title}>Filtri</h2>
          <div className={styles.main_form}>
            <div className={styles.search_container}>
              <div className={styles.select_wrapper}>
                <div className={styles.select_label}>Cerca cliente</div>
                <AsyncSelect
                  components={{
                    DropdownIndicator: () => <SearchIcon style={{ margin: '0 10px' }} />
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    input: (provided) => ({
                      ...provided,
                      input: {
                        opacity: '1 !important'
                      }
                    }),
                    container: (provided) => ({
                      ...provided,
                      width: '100%'
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '30px',
                      minHeight: '36px'
                    })
                  }}
                  placeholder={'Cerca...'}
                  cacheOptions
                  loadOptions={searchCustomers}
                  isSearchable
                  onChange={({ value }) => setFieldValue('id_customer', value?.idCustomer)}
                  inputValue={values.searchString}
                  value={{ value: values.searchString }}
                  onInputChange={(value, actions) => {
                    switch (actions.action) {
                      case 'input-change':
                        return setFieldValue('searchString', value);
                    }
                    return values.searchString;
                  }}
                />
              </div>
            </div>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Nazione</div>
              <LocalSelect
                ref={stateRef}
                onChange={(data) => setFieldValue('state', data?.value)}
                value={states
                  .map(({ state }) => ({ value: state, label: state }))
                  .find((item) => item.value === values?.state)}
                name="disable"
                options={states.map(({ state }) => ({ value: state, label: state }))}
              />
            </div>
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Dal</p>
                <DatePicker
                  label="Dal"
                  value={values.from}
                  onChange={(value) => setFieldValue('from', value)}
                  renderInput={(params) => (
                    <MaterialTextField {...params} size="small" error={false} />
                  )}
                />
              </div>
            </LocalizationProvider>
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Al</p>
                <DatePicker
                  label="Al"
                  value={values.to}
                  onChange={(value) => setFieldValue('to', value)}
                  renderInput={(params) => (
                    <MaterialTextField {...params} size="small" error={false} />
                  )}
                />
              </div>
            </LocalizationProvider>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Canale</div>
              <LocalSelect
                ref={channelRef}
                onChange={(data) => setFieldValue('channel', data?.value)}
                value={channels
                  .map(({ id, name }) => ({ value: id, label: name }))
                  .find((item) => item.value === values?.channel)}
                name="disable"
                options={channels.map(({ id, name }) => ({ value: id, label: name }))}
              />
            </div>
            <CustomRadios
              label={'Spedizione'}
              value={values?.shipped}
              options={Object.keys(radioTypes)
                .filter((key) => key)
                .map((i) => ({ value: i }))}
              formControlStyles={{ width: '100%' }}
              outputComponent={(val, iconSrc) => (
                <div>
                  {iconSrc ? <img src={iconSrc} width={24} height={24} alt={'icon'} /> : null}
                  <div>{radioLabels[val]}</div>
                </div>
              )}
              handleChange={(val) => setFieldValue('shipped', val)}
            />
          </div>
          <div className={styles.spaced__buttons}>
            <PrimaryButton
              type="button"
              width="40%"
              background="white"
              color="#4E44C6"
              text={'Reset'}
              onClick={() => {
                resetForm();
                channelRef.current?.clearValue();
                stateRef.current?.clearValue();
                dispatch(
                  ordersActions.SET_FILTER_FIELDS.SUCCESS({
                    searchString: '',
                    from: '',
                    to: '',
                    shipped: radioTypes.ALL,
                    channel: '',
                    id_customer: '',
                    state: ''
                  })
                );
                dispatch(ordersActions.GET_ORDERS.REQUEST({
                  params: { canceled: window.location.pathname.includes('canceled')},
                }, closeModal));
              }}
            />
            <PrimaryButton
              type="submit"
              width="40%"
              background="#4E44C6"
              color="white"
              text={'Apply'}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FilterModal;
