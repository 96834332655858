import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import {getUser} from '../../Auth/store/selectors';
import styles from './Chat.module.scss';
import Message from './containers/Message';
import SendMessage from './containers/SendMessage';

const Chat = (
  {
    data = [],
    entityId,
    type
  }
) => {
  const user = useSelector(getUser());
  const messagesEndRef = useRef(null);
  useEffect(() => {
    if (messagesEndRef) messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  }, [data]);
  return (
    <div className={styles.chat}>
      <div className={styles.header}>Chat</div>
      <div ref={messagesEndRef} className={styles.content}>
        {data?.map((msg) => (
          <Message
            key={msg.idMessage}
            message={msg}
            isMyMessage={+user.idUser !== +msg.user?.idUser}
            type={type}
          />
        ))}
        <div className={styles.empty_space}/>
      </div>
      <div className={styles.footer}>
        <SendMessage
          entityId={entityId}
          type={type}
        />
      </div>
    </div>
  );
};

export default Chat;
