// To save the table state with filters and search

export const appendFilterListToColumns = (columns, filterList) => {
  if (filterList) {
    filterList.forEach((filter, index) => {
      if (!columns[index]?.options) {
        let filters = {
          filter: true,
          filterList: []
        };
        columns[index].options = filters;
      }
      if (filter && filter.length > 0) {
        columns[index].options.filterList = filter;
      }
    });
  }
  return columns;
};

export const fileReaderToBase64 = async (file) => {
  const prom = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadedFile = await prom(file[0]);
  return uploadedFile;
};

export const clearLocalStorageByTableKey = (tableKey) => {
  Object.entries(localStorage).map(([key]) => {
    const KEY = key.split('/').pop();
    if (KEY === tableKey) localStorage.removeItem(key);
    return key;
  });
};

export const formatFileNamesFromUrls = (files = []) =>
  files.map((picture) => {
    const name = picture?.url
      .split('/')
      .pop()
      .split('.')[1]
      ?.replaceAll('%20', ' ')
      ?.replaceAll('%CC%', '-');

    return {
      id: picture?.id,
      name,
      url: picture?.url
    };
  });

export const imgExtensions = ['.jpeg', '.png', '.jpg'];

export const checkInaccessibleFile = (url) => {
  const acceptedFormats = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/webp',
    'image/svg',
    'png',
    'jpeg',
    'jpg',
    'webp',
    'svg'
  ];
  const alreadyAdded = /^https:/.test(url);
  const newFileType = url?.split(';')[0].split('/');
  const fileType =
    alreadyAdded && acceptedFormats.some((i) => i === url?.split('.').pop())
      ? 'img'
      : alreadyAdded
      ? url?.split('.').pop()
      : newFileType[1] === 'pdf'
      ? 'pdf'
      : newFileType[0]?.includes('application')
      ? 'doc'
      : newFileType[0]?.includes('data:image')
      ? 'img'
      : false;
  return { alreadyAdded, fileType };
};

export const calcDiffDays = (date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  const givenDate = new Date(date);

  const diffDays = Math.round(Math.abs((today - givenDate) / oneDay));
  return diffDays;
};

export const objectToQuery = (obj, options) =>
  obj
    ? Object.entries(obj)
        .reduce(
          (acc, [key, value]) =>
            value ||
            (typeof value === 'number' && value >= 0) ||
            (options.stayBoolean && typeof value === 'boolean')
              ? `${acc}&${key}=${value}`
              : acc,
          ''
        )
        .replace('&', '?')
    : '';
