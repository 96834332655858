import clx from 'classnames';

import { ReactComponent as ArrowIcon } from '../../../assets/svg/arrow-small.svg';
import useWindowSize from 'utils/hooks/useWindowSize';
import styles from './FilePreviewBlock.module.scss';
import BinIcon from '../../../assets/svg/bin_gray.svg';
import PrimaryButton from '../Buttons/PrimaryButton';
import React from 'react';

const FilePreviewBlock = ({ info, isFileFullSize, readonly, setIsFileFullSize, removeItem }) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={clx(styles['file__full-size'], { [styles.file_mbl]: isMobile })}>
      <ArrowIcon onClick={() => setIsFileFullSize(null)} className={styles.arrow__btn} />
      <div className={styles.file__wrapper}>
        <div className={styles.file__header}>
          {info ? <div className={styles.info}>{info}</div> : null}
          {!readonly ? (
            <PrimaryButton
              type="button"
              width="fit-content"
              text="Elimina"
              color="#999"
              fontSize="14px"
              onClick={() => removeItem(isFileFullSize?.imageId)}
              border="1px solid #999"
              leftIconHeight="18px"
              leftIconSrc={BinIcon}
            />
          ) : null}
        </div>
        <img src={isFileFullSize?.url} className={styles['uploaded-file']} />
      </div>
    </div>
  );
};
export default FilePreviewBlock;
