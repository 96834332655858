import clx from 'classnames';
import React from 'react';
import useWindowSize from 'utils/hooks/useWindowSize';

import PlusIcon from '../../../../../assets/svg/plus-circle.svg';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import styles from './TableHeader.module.scss';

const TableHeader = ({
  title,
  buttonText,
  showAddButton,
  action,
  showFilterButton = false,
  filterAction,
  showSearchButton = false,
  searchComponent = null
}) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={clx(styles.container__header, { [styles.container_mbl]: isMobile })}>
      <h2>{title}</h2>
      <div className={styles.container_inner_buttons}>
        {showSearchButton && searchComponent}
        {showFilterButton && (
          <PrimaryButton
            height="34px"
            fontSize="13px"
            padding="8px 10px"
            borderRadius="40px"
            text="Filtri"
            onClick={filterAction}
            filterIcon={true}
          />
        )}
        {showAddButton && (
          <PrimaryButton
            height="34px"
            fontSize="13px"
            padding="8px 10px"
            borderRadius="40px"
            text={buttonText}
            leftIconSrc={PlusIcon}
            onClick={action}
          />
        )}
      </div>
    </div>
  );
};

export default TableHeader;
