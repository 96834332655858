import actionCreator from '../../../utils/actionCreator';

const UsersActionTypes = [
  'GET_ALL_CLIENTS',
  'GET_CLIENT',
  'ADD_CLIENT',
  'EDIT_CLIENT',
  'GET_ALL_USERS',
  'SEARCH_CLIENTS',
  'SEARCH_ALL_CLIENTS',
  'ADD_USER',
  'EDIT_USER'
];

export const { types: userTypes, actions: userActions } = actionCreator(UsersActionTypes);
