import InputWithDebounce from 'Containers/Common/InputWithDebounce';
import { productsActions } from 'Containers/Products/store';
import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './ProductsHeader.module.scss';

const ProductsHeader = () => {
  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(productsActions.GET_PRODUCTS.REQUEST());
  };
  return (
    <div className={styles.wrapper}>
      <h1>Prodotti</h1>
      <InputWithDebounce
        onReset={handleReset}
        onChange={(inputValue) => {
          dispatch(
            productsActions.SEARCH_PRODUCTS.REQUEST({
              query: inputValue,
              noLimit: true,
              withLoading: true
            })
          );
        }}
      />
    </div>
  );
};

export default ProductsHeader;
