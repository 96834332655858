import 'moment/locale/it';

import { TextField as MaterialTextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import clx from 'classnames';
import { selectFilterFields } from 'Containers/Orders/store/selectors';
import { Form, Formik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import useWindowSize from 'utils/hooks/useWindowSize';
import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import styles from './FilterModal.module.scss';
import { selectStatuses } from 'Containers/Supports/store/selectors';
import { supportsActions } from 'Containers/Supports/store';

const LocalSelect = (props) => (
  <Select
    components={{
      IndicatorSeparator: () => null
    }}
    menuPortalTarget={document.body}
    menuPosition="fixed"
    styles={{
      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
      control: (provided) => ({
        ...provided,
        borderRadius: '30px',
        marginLeft: '1px',
        minHeight: '36px'
      }),
      input: (provided) => ({
        ...provided,
        input: { fontSize: '16px' }
      })
    }}
    {...props}
  />
);

const FilterModal = ({ closeModal }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const statuses = useSelector(selectStatuses());
  const initialValues = useSelector(selectFilterFields());

  const statusRef = useRef(null);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        const { from, to, status} = values;
        const params = {
          from: from ? moment(from).format('YYYY-MM-DD') : '',
          to: to ? moment(to).format('YYYY-MM-DD') : '',
          status,
          free_text: initialValues.free_text ?? ''
        };
        dispatch(
          supportsActions.SET_FILTER_FIELDS.SUCCESS({
            ...params,
          })
        );
        dispatch(
          supportsActions.GET_SUPPORTS.REQUEST(
            { params },
            closeModal
          )
        );
      }}>
      {({ values, setFieldValue, resetForm }) => (
        <Form className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}>
          <h2 className={styles.form_title}>Filtri</h2>
          <div className={styles.main_form}>
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Dal</p>
                <DatePicker
                  label="Dal"
                  value={values.from}
                  onChange={(value) => setFieldValue('from', value)}
                  renderInput={(params) => (
                    <MaterialTextField {...params} size="small" error={false} />
                  )}
                />
              </div>
            </LocalizationProvider>
            <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterMoment}>
              <div className={styles.date}>
                <p className={styles.date__title}>Al</p>
                <DatePicker
                  label="Al"
                  value={values.to}
                  onChange={(value) => setFieldValue('to', value)}
                  renderInput={(params) => (
                    <MaterialTextField {...params} size="small" error={false} />
                  )}
                />
              </div>
            </LocalizationProvider>
            <div className={styles.select_wrapper}>
              <div className={styles.select_label}>Status</div>
              <LocalSelect
                ref={statusRef}
                onChange={(data) => setFieldValue('status', data?.value)}
                value={statuses
                  .map(({ value, label }) => ({ value, label }))
                  .find((item) => item.value === values?.status)}
                name="disable"
                options={statuses.map(({ value, label }) => ({ value, label }))}
              />
            </div>

          </div>
          <div className={styles.spaced__buttons}>
            <PrimaryButton
              type="button"
              width="40%"
              background="white"
              color="#4E44C6"
              text={'Reset'}
              onClick={() => {
                resetForm();
                statusRef.current?.clearValue();
                dispatch(
                  supportsActions.SET_FILTER_FIELDS.SUCCESS({
                    from: '',
                    to: '',
                    status: '',
                  })
                );
                dispatch(supportsActions.GET_SUPPORTS.REQUEST({}, closeModal));
              }}
            />
            <PrimaryButton
              type="submit"
              width="40%"
              background="#4E44C6"
              color="white"
              text={'Apply'}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FilterModal;
