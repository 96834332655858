import clx from 'classnames';

import styles from './TableHeadCell.module.scss';

const TableHeadCell = ({ label, subtitles = [] }) => (
  <th className={clx(styles.tableHead, { [styles.tableHeadWithSubtitles]: subtitles.length })}>
    <div className={styles.containerInner}>
      <div className={styles.title}>{label}</div>
      <ul className={styles.subtitles}>
        {subtitles?.map((subtitle, idx) => (
          <li key={idx} className={styles.subtitle}>
            {subtitle?.label}
          </li>
        ))}
      </ul>
    </div>
  </th>
);

export default TableHeadCell;
