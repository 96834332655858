import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../Common/TextField';
import { returnsActions } from '../../../../store';
import styles from './ReturnProductModal.module.scss';
import { selectReturnProductStatuses } from '../../../../store/selectors';

const ReturnProductModal = ({ setIsModalOpen, activeProduct = {}, idReturn = false }) => {
  const dispatch = useDispatch();
  const returnProductStatuses = useSelector(selectReturnProductStatuses());
  const INITIAL_VALUES = {
    idReturnProduct: activeProduct.idReturnProduct,
    quantity: activeProduct.quantity || 0,
    returnQuantity: activeProduct.returnQuantity || 0,
    statusQuantity: activeProduct.statusQuantity || 0,
    productStatus: activeProduct.productStatus || '',
    customerReason: activeProduct.customerReason || '',
    note: activeProduct.note || ''
  };

  const VALIDATION_SCHEMA = yup.object().shape({
    quantity: yup.number().required('Obbligatorio'),
    returnQuantity: yup.number().required('Obbligatorio'),
    statusQuantity: yup.number().required('Obbligatorio'),
    productStatus: yup.number().required('Obbligatorio')
  });

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values) => {
        const res = {
          returnObj: {
            idReturn
          },
          products: [
            {
              ...values
            }
          ],
          property: []
        };
        dispatch(returnsActions.EDIT_RETURN.REQUEST(res, () => setIsModalOpen(false)));
      }}>
      {({ errors, touched, values, setFieldValue }) => (
        <Form className={styles.entity_form} autoComplete="off">
          <h2 className={styles.form_title}>Modifica product reso</h2>
          <p className={styles.product_name}>{activeProduct?.name}</p>
          <div className={styles.main_form}>
            <div>
              <Field
                name="quantity"
                id="quantity"
                labeledTag={
                  <div className={styles['input-label']}>
                    Quantità ordinate <p>(conf)</p>
                  </div>
                }
                placeholder="Quantità ordinate"
                component={TextField}
                disabled={true}
              />
              <Field
                name="returnQuantity"
                id="returnQuantity"
                labeledTag={
                  <div className={styles['input-label']}>
                    Quantità rese <p>(pezzi)</p>
                  </div>
                }
                placeholder="Quantità rese"
                component={TextField}
              />
              <Field
                name="customerReason"
                id="customerReason"
                label="Motivo cliente"
                placeholder="Motivo cliente"
                textarea={true}
                component={TextField}
                disabled={true}
              />
            </div>
            <div>
              <Field
                name="statusQuantity"
                id="statusQuantity"
                labeledTag={
                  <div className={styles['input-label']}>
                    Quantità rotte <p>(pezzi)</p>
                  </div>
                }
                placeholder="Quantità rotte"
                disabled={+values?.productStatus === 3}
                component={TextField}
              />
              <div className={styles.select_wrapper}>
                <div className={styles.select_label}>Stato reso cliente</div>
                <Select
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  onChange={(data) => setFieldValue('productStatus', data?.value)}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                    control: (provided) => {
                      const activeColor = returnProductStatuses.find(
                        (status) => values.productStatus === +status.idReturnProductStatuses
                      )?.color;
                      return {
                        ...provided,
                        borderRadius: '10px',
                        marginLeft: '1px',
                        backgroundColor: activeColor || 'transparent',
                        minHeight: '36px',
                        height: '36px'
                      };
                    },
                    input: (provided) => ({
                      ...provided,
                      color: 'white!important',
                      margin: '0px',
                      input: { fontSize: '16px' }
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'white',
                      padding: '2px 0'
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: '36px'
                    })
                  }}
                  value={returnProductStatuses
                    .map(({ idReturnProductStatuses, name }) => ({
                      value: idReturnProductStatuses,
                      label: name
                    }))
                    .find((status) => +values.productStatus === +status.value)}
                  options={returnProductStatuses?.map(({ idReturnProductStatuses, name }) => ({
                    value: idReturnProductStatuses,
                    label: name
                  }))}
                />
                {touched.productStatus && errors.productStatus && (
                  <div className={styles.select_error}>{errors.productStatus}</div>
                )}
              </div>
              <Field
                name="note"
                id="note"
                label="Nota"
                placeholder="Nota"
                textarea={true}
                component={TextField}
              />
              <div className={styles['submit-btn__wrapper']}>
                <PrimaryButton type="submit" width="100%" text="Modifica" />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReturnProductModal;
