import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from 'Containers/Common/TextField';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useWindowSize from 'utils/hooks/useWindowSize';

import { TABLE_KEYS } from '../../../Common/DataTables/constants';
import TableHeader from '../../../Common/DataTables/containers/TableHeader/TableHeader';
import Modal from '../../../Common/Modal';
import ReturnsTable from '../../../Tables/ReturnsTable';
import { returnsActions } from '../../store';
import FilterModal from './containers/FilterModal';
import ReturnAddAndEditModal from './containers/ReturnAddAndEditModal';
import StatusHeader from './containers/StatusHeader';
import styles from './ReturnsList.module.scss';
import { selectFilteredReturns, selectCountReturns, selectFilterFields } from 'Containers/Returns/store/selectors';

export default ({ archived = false, returnStatuses, selectedStatus, setSelectedStatus }) => {
  const { statusId } = useParams();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const returns = useSelector(selectFilteredReturns(0));
  const returnsFilters = useSelector(selectFilterFields());

  const [searchText, setSearchText] = useState('');
  const [isAddReturnModalOpen, setIsAddReturnModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const ReturnsTableRefresh = useCallback((props) => <ReturnsTable {...props}/>, [selectedStatus]);

  useEffect(() => {
    dispatch(
      returnsActions.GET_COUNT_RETURNS.REQUEST()
    );
    dispatch(
      returnsActions.GET_RETURNS_STATUSES.REQUEST(null, (data) =>
        setSelectedStatus(data?.find((status) => +status?.idReturnStatus === +statusId))
      )
    );
    dispatch(
      returnsActions.SET_FILTER_FIELDS.SUCCESS({
        from: '',
        to: '',
        carrier: '',
        channel: '',
        status: '',
        reason: '',
        archived
      })
    );
    dispatch(returnsActions.GET_RETURNS.REQUEST({ params: { archived } }));
  }, []);

  useEffect(() => {
    const statusId = selectedStatus?.idReturnStatus > 0 ? selectedStatus?.idReturnStatus : '';
    const params = { ...returnsFilters, status: statusId};
      dispatch(returnsActions.SET_FILTER_FIELDS.SUCCESS(params));
      dispatch(returnsActions.GET_RETURNS.REQUEST({params}));
    }, [selectedStatus]);

  return (
    <div className={styles.container__inner}>
      <StatusHeader
        returnStatuses={returnStatuses}
        value={selectedStatus}
        setValue={setSelectedStatus}
      />
      <TableHeader
        title="Resi"
        buttonText="Aggiungi Reso"
        showAddButton={true}
        action={() => setIsAddReturnModalOpen(true)}
        showFilterButton={true}
        filterAction={() => setIsFilterModalOpen(true)}
        showSearchButton={true}
        searchComponent={
          <TextField
            placeholder="Cerco..."
            width="200px"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            iconComponent={
              <>
                {searchText && (
                  <div onClick={() => setSearchText('')}>
                    <CloseIcon style={{ margin: '0' }} />
                  </div>
                )}
                <SearchIcon style={{ margin: '0 10px' }} />
              </>
            }
          />
        }
      />
      <ReturnsTableRefresh searchText={searchText} returnsList={returns} tableKey={TABLE_KEYS.RETURNS} />
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isAddReturnModalOpen}
        setIsModalOpen={() => setIsAddReturnModalOpen(false)}>
        <ReturnAddAndEditModal
          isMobile={isMobile}
          setIsModalOpen={() => setIsAddReturnModalOpen(false)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isFilterModalOpen}
        setIsModalOpen={() => setIsFilterModalOpen(false)}>
        <FilterModal setIsModalOpen={() => setIsFilterModalOpen(false)} setSelectedStatus={setSelectedStatus}/>
      </Modal>
    </div>
  );
};
