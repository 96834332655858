import { supportsTypes } from './actions';
import { STATUSES } from '../containers/constants';

const initialState = {
  supports: [],
  activeSupport: {},
  statuses: [],
  filterFields: {
    from: '',
    to: '',
    status: '',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case supportsTypes.SET_FILTER_FIELDS.SUCCESS:
      return {
        ...state,
        filterFields: action.payload
      };
    case supportsTypes.GET_SUPPORTS.SUCCESS:
      return {
        ...state,
        supports: action.payload.supports,
        statuses: action.payload.status?.map((status) => ({
          ...status,
          color: STATUSES[status.value]
        }))
      };
    case supportsTypes.GET_SUPPORT.SUCCESS:
      return {
        ...state,
        activeSupport: action.payload
      };
    default:
      return { ...state };
  }
};
