import clx from 'classnames';
import { useEffect, useState } from 'react';

import { ReactComponent as EyeIcon } from '../../../assets/svg/eye.svg';
import styles from './TextField.module.scss';

const TextField = ({
  field,
  form,
  className,
  label,
  width = '100%',
  labeledTag,
  margin = '0 0 10px 0',
  type = 'text',
  inputColor = '#ffff',
  inputDisabledColor = '#CFD4DF',
  borderRadius = '15px',
  border = '1px solid #C9C9C9',
  textarea = false,
  height = '36px',
  showPassword = false,
  iconComponent,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);

  const { touched, errors } = form || {};

  const isError =
    touched && errors
      ? field?.name.split('.').reduce(
          (acc, item) =>
            acc.errors[item] && acc.touched[item]
              ? {
                  errors: acc.errors[item],
                  touched: acc.touched[item],
                  errorText: acc.errors[item]
                }
              : {
                  errors,
                  touched,
                  errorText: ''
                },
          { errors, touched, errorText: '' }
        )
      : '';

  useEffect(() => {
    setInputType(type);
  }, [type]);

  return (
    <div
      className={clx(styles.field__wrapper, styles[`${className}`], {
        [styles['full-height']]: textarea && height === '100%'
      })}
      style={{ margin, width }}>
      {label ? (
        <label className={styles['field__label']} htmlFor={field?.name}>
          {label}
        </label>
      ) : (
        labeledTag
      )}
      {textarea ? (
        <textarea
          className={clx(styles['field__textarea'], {
            [styles['error-textarea']]: !!isError.errorText
          })}
          {...field}
          {...props}
          style={{
            borderRadius,
            border,
            height,
            background: `${props.disabled ? inputDisabledColor : inputColor}`
          }}
        />
      ) : (
        <div
          className={clx(styles['input-group'], { [styles['error-field']]: !!isError.errorText })}
          style={{
            borderRadius,
            height,
            border,
            background: `${props.disabled ? inputDisabledColor : inputColor}`
          }}>
          <input
            className={styles['field__input']}
            type={inputType}
            autoComplete="new-password"
            {...field}
            {...props}
            style={{
              background: `${props.disabled ? inputDisabledColor : inputColor}`
            }}
          />
          {showPassword && (
            <EyeIcon
              className={
                !isError.errorText ? styles['show-pwd-icon'] : styles['show-pwd-icon__red']
              }
              onClick={() => {
                if (inputType === 'password') {
                  setInputType('text');
                } else {
                  setInputType('password');
                }
              }}
            />
          )}
          {iconComponent}
        </div>
      )}
      {!!isError.errorText && <div className={styles.error}>{isError.errorText}</div>}
    </div>
  );
};

export default TextField;
