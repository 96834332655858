import PrimaryButton from 'Containers/Common/Buttons/PrimaryButton';
import InputWithDebounce from 'Containers/Common/InputWithDebounce';
import TextField from 'Containers/Common/TextField';
import { ordersActions } from 'Containers/Orders/store';
import { selectCreateOrderResponse, selectFilterFields } from 'Containers/Orders/store/selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../../../Common/Modal';
import styles from './OrderHeader.module.scss';

const OrderHeader = ({ openFilters }) => {
  const dispatch = useDispatch();
  const orderResponse = useSelector(selectCreateOrderResponse());
  const filterFields = useSelector(selectFilterFields());
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState();
  const handleChange = (event) => {
    setOrder(event.target.value);
  };
  const handleReset = () => {
    dispatch(ordersActions.GET_ORDERS.REQUEST({
      params: {...filterFields, free_text: ''}
    }));
  };
  const submitOrder = () => {
    dispatch(
      ordersActions.CREATE_ORDER.REQUEST(order, () => {
        setOpen(true);
      })
    );
  };
  const closeModal = () => {
    setOpen(false);
    dispatch(ordersActions.CREATE_ORDER.SUCCESS({ msg: '' }));
    window.location.reload();
  };

  return (
    <div className={styles.wrapper}>
      <Modal
        isModalOpen={open}
        setIsModalOpen={closeModal}
        title="Ordine"
        showCloseBtn
        width="330px"
        height={'300px'}>
        <div className={styles.modal__content}>
          <h3>Ordine Aggiunto</h3>
          {orderResponse}
          <div className={styles.modal__actions}>
            <PrimaryButton
              text="Annul"
              background="transparent"
              padding="8px"
              height="auto"
              fontSize="15px"
              width="180px"
              color="#AEAEB2"
              onClick={closeModal}
            />
          </div>
        </div>
      </Modal>
      <h1>Ordini</h1>
      <div className={styles.wrapper__create}>
        <TextField placeholder="id" width="100px" margin={'0'} onChange={handleChange} />
        <PrimaryButton text={'Aggiungi'} onClick={submitOrder} />
      </div>
      <InputWithDebounce
        onReset={handleReset}
        onChange={(inputValue) => {
          dispatch(ordersActions.SET_FILTER_FIELDS.SUCCESS({
            ...filterFields,
            free_text: inputValue
          }));
          dispatch(
            ordersActions.GET_ORDERS.REQUEST({
              params: {...filterFields, free_text: inputValue}
            })
          );
        }}
      />
      <PrimaryButton
        height="34px"
        fontSize="13px"
        padding="8px 10px"
        borderRadius="40px"
        text="Filtri"
        onClick={openFilters}
        filterIcon={true}
      />
    </div>
  );
};

export default OrderHeader;
