import { radioTypes } from 'Containers/Orders/containers/OrdersList/containers/FilterModal/constants';

import { ordersTypes } from './actions';

const initialState = {
  orders: [],
  has_remaining_orders: false,
  activeOrder: {},
  shipping: {},
  createOrderResponse: '',
  isSearching: '',
  query: '',
  filterFields: {
    searchString: '',
    from: '',
    to: '',
    shipped: radioTypes.ALL,
    channel: '',
    id_customer: '',
    state: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ordersTypes.SET_FILTER_FIELDS.SUCCESS:
      return {
        ...state,
        filterFields: action.payload
      };
    case ordersTypes.GET_ORDERS.SUCCESS:
      const { savePrevious, data } = action.payload;
      const ordersById = data.reduce((acc, order) => {
        acc[order.idOrder] = order;
        return acc;
      }, {});
      const ordersUpdated = state.orders.map((order) => {
        const orderId = ordersById[order.idOrder];
        delete ordersById[order.idOrder];
        return orderId || order;
      });
      return {
        ...state,
        orders: savePrevious ? [...ordersUpdated, ...Object.values(ordersById)] : data,
        has_remaining_orders: action.payload.has_remaining_orders,
        query: ''
      };
    case ordersTypes.GET_ORDER.SUCCESS:
      return {
        ...state,
        activeOrder: action.payload
      };
    case ordersTypes.CREATE_ORDER.SUCCESS:
      return {
        ...state,
        createOrderResponse: action.payload.msg
      };
    case ordersTypes.GET_SHIPPING.SUCCESS:
      return {
        ...state,
        shipping: action.payload
      };
    case ordersTypes.GET_SHIPPING.FAILED:
      return {
        ...state,
        shipping: action.payload
      };
    case ordersTypes.GET_SHIPPING.CLEAR:
      return {
        ...state,
        shipping: {}
      };
    case ordersTypes.SEARCH_ORDERS.SUCCESS:
      const { query, orders } = action.payload;
      return {
        ...state,
        orders,
        query
      };
    default:
      return { ...state };
  }
};
