import * as yup from 'yup';

export const COMPANY_STATUSES = [
  {
    label: 'Disabilitato',
    value: 1
  },
  {
    label: 'Attivo',
    value: 0
  }
];


export const initialCreateValues = {
  nomeAzienda: '',
  logo: '',
  nome: '',
  cognome: '',
  email: '',
  pwd: ''
};

export const validationCreateSchema = yup.object().shape({
  nomeAzienda: yup.string().required('Obbligatorio'),
  nome: yup.string().required('Obbligatorio'),
  cognome: yup.string().required('Obbligatorio'),
  email: yup.string().required('Obbligatorio'),
  pwd: yup.string().required('Obbligatorio')
});

export const validationEditSchema = yup.object().shape({
  nomeAzienda: yup.string().required('Obbligatorio'),
  idAdmin: yup.number().required('Obbligatorio'),
  nome: yup.string().required('Obbligatorio'),
  cognome: yup.string().required('Obbligatorio'),
  email: yup.string().required('Obbligatorio'),
  statusAzienda: yup.number().required('Obbligatorio')
});
