import {Field, Form, Formik} from 'formik';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../Common/TextField';
import {brokensActions} from '../../../../store';
import {selectBrokenProductStatuses} from '../../../../store/selectors';
import styles from './BrokenProductModal.module.scss';

const BrokenProductModal = (
  {
    setIsModalOpen,
    activeProduct = {},
    idBreak = false,
  }
) => {
  const dispatch = useDispatch();
  const breakProductStatuses = useSelector(selectBrokenProductStatuses());
  const INITIAL_VALUES = {
    idBreakProduct: activeProduct.idBreakProduct,
    quantity: activeProduct.quantity || 0,
    brokenQuantity: activeProduct.brokenQuantity || 0,
    note: activeProduct.note || '',
    packaging: activeProduct.packaging || '',
  };

  const VALIDATION_SCHEMA = yup.object().shape({
    quantity: yup.number().required('Obbligatorio'),
    brokenQuantity: yup.number().required('Obbligatorio'),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values) => {
        const res = {
          breakObj: {
            idBreak,
          },
          products: [{
            ...values
          }],
          property: []
        };
        dispatch(brokensActions.EDIT_BROKEN.REQUEST(res, () => setIsModalOpen(false)));
      }}>
      {({errors, touched, values, setFieldValue}) => (
        <Form className={styles.entity_form} autoComplete="off">
          <h2 className={styles.form_title}>Modifica product rottura</h2>
          <div className={styles.main_form}>
            <div>
              <Field
                name="quantity"
                id="quantity"
                labeledTag={
                  <div className={styles['input-label']}>
                    Quantità ordinate <p>(conf)</p>
                  </div>
                }
                placeholder="Quantità ordinate"
                component={TextField}
                disabled={true}
              />
              <Field
                name="brokenQuantity"
                id="brokenQuantity"
                labeledTag={
                  <div className={styles['input-label']}>
                    Qt. pezzi rotti <p>(pezzi)</p>
                  </div>
                }
                placeholder="Qt. pezzi rotti"
                component={TextField}
              />
              <div className={styles.select_wrapper}>
                <div className={styles.select_label}>Tipo imballagio</div>
                <Select
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  onChange={(data) => setFieldValue('packaging', data?.value)}
                  styles={{
                    menuPortal: (provided) => ({...provided, zIndex: 520}),
                    control: (provided) => {
                      const activeColor = breakProductStatuses
                        .find(status => +values.packaging === +status.idBreakProductStatuses)?.color;
                      return {
                        ...provided,
                        borderRadius: '10px',
                        marginLeft: '1px',
                        backgroundColor: activeColor || 'transparent',
                        minHeight: '36px',
                        height: '36px'
                      };
                    },
                    input: (provided) => ({
                      ...provided,
                      color: 'white!important',
                      margin: '0px',
                      input: {fontSize: '16px'}
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'white',
                      padding: '2px 0'
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: '36px'
                    })
                  }}
                  value={breakProductStatuses
                    .map(({idBreakProductStatuses, name}) => ({
                      value: idBreakProductStatuses,
                      label: name
                    }))
                    .find((status) => +values.packaging === +status.value)}
                  options={breakProductStatuses?.map(({idBreakProductStatuses, name}) => ({
                    value: idBreakProductStatuses,
                    label: name
                  }))}
                />
                {touched.packaging && errors.packaging && (
                  <div className={styles.select_error}>{errors.packaging}</div>
                )}
              </div>
            </div>
            <Field
              name="note"
              id="note"
              label="Nota"
              placeholder="Nota"
              textarea={true}
              component={TextField}
              height={'90%'}
            />
          </div>
          <div className={styles['submit-btn__wrapper']}>
            <PrimaryButton
              type="submit"
              width="50%"
              text="Modifica"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BrokenProductModal;
