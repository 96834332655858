import moment from 'moment';
import React, { useMemo } from 'react';

import { ROUTES } from '../../../routes/constants';
import { DataTableCustomized } from '../../Common/DataTables';
import Label from '../../Common/Label';
import { STATUSES } from '../../Users/constants';
import styles from './UsersTable.module.scss';
import {TABLE_KEYS} from '../../Common/DataTables/constants';

export default ({
  usersList,
  tableName,
  tableKey = TABLE_KEYS.USERS,
  paginationOption = true,
  isDetails = false,
  isDelete = false,
  isEdit = true
}) => {
  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        label: 'ID Utente',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <div>#{data}</div>
      },
      {
        name: 'name',
        label: 'Nome',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'email',
        label: 'Email',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'createdAt',
        label: 'Registrato il',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>
      },
      {
        name: 'status',
        label: 'Status',
        filter: false,
        sort: true,
        centered: true,
        customBodyRender: (data) => (
          <span className={styles['table__status-column']}>
            <Label
              content={data?.label}
              background="lightgray"
              borderRadius="40px"
              color="#FFFF"
              padding="5px"
              width="100px"
              height="26px"
            />
          </span>
        )
      }
    ],
    []
  );

  return (
    <div className={styles.container__inner}>
      <DataTableCustomized
        tableData={usersList?.map((client) => ({
          id: client?.idUser,
          name: `${client?.name} ${client?.surname}`,
          email: client?.email,
          status: STATUSES.find((status) => +status.value === +client?.status),
          createdAt: client?.createdAt
        }))}
        tableName={tableName}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        tableKey={tableKey}
        detailsLink={ROUTES.USERS}
        isDetails={isDetails}
        isDelete={isDelete}
        isEdit={isEdit}
      />
    </div>
  );
};
