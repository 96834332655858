import actionCreator from '../../../utils/actionCreator';

const returnsActionTypes = [
  'GET_RETURNS_STATUSES',
  'GET_RETURNS',
  'GET_RETURN',
  'CREATE_RETURN',
  'EDIT_RETURN',
  'EDIT_RETURN',
  'ADD_RETURN_MESSAGE',
  'DELETE_RETURN_MESSAGE',
  'ADD_IMAGE_TO_PRODUCT_RETURN',
  'DELETE_IMAGE_FROM_PRODUCT_RETURN',
  'GET_CARRIERS',
  'DELETE_RETURN',
  'GET_REASONS',
  'SET_FILTER_FIELDS',
  'GET_COUNT_RETURNS',
];

export const { types: returnsTypes, actions: returnsActions } = actionCreator(returnsActionTypes);
