import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '../../../routes/constants';
import { DataTableDefault } from '../../Common/DataTables';
import { selectStatuses } from '../../Supports/store/selectors';
import styles from './SupportsTable.module.scss';

export default ({
  supportsList,
  tableName,
  tableKey,
  paginationOption = true,
  isDetails = true,
  isDelete = false,
  isEdit = false
}) => {
  const statuses = useSelector(selectStatuses());

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        label: 'Id supporto',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'date',
        label: 'Data richiesta',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'number',
        label: 'Numero ordine',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'customer',
        label: 'Cliente',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'channel',
        label: 'Canale',
        filter: false,
        sort: true,
        centered: true
      },
      {
        name: 'status',
        label: 'Status',
        filter: false,
        sort: true,
        centered: false,
        customBodyRender: (data) => (
          <div className={styles.status} style={{ backgroundColor: data?.color }}>
            {data.label}
          </div>
        )
      }
    ],
    []
  );

  return (
    <div>
      <DataTableDefault
        tableData={supportsList?.map((support) => ({
          id: support?.idSupport,
          date: moment(support?.date).format('DD/MM/YYYY'),
          customer: support?.customer,
          channel: support?.channel,
          status: statuses?.find((status) => +status.value === +support?.status),
          number: support?.number
        }))}
        tableName={tableName}
        tableColumns={tableColumns}
        paginationOption={paginationOption}
        tableKey={tableKey}
        detailsLink={ROUTES.ASSISTANCE_REQUEST}
        isDetails={isDetails}
        isDelete={isDelete}
        isEdit={isEdit}
      />
    </div>
  );
};
