/* eslint-disable multiline-ternary */
import './styles/index.scss';

import { ThemeProvider } from '@mui/material/styles';
import Estimation from 'Containers/Settings/containers/Estimation';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import useAuth from 'utils/hooks/useAuth';
import useWindowSize from 'utils/hooks/useWindowSize';

import { SignIn } from './Containers/Auth/containers';
import { getAuth, getUserRoleId } from './Containers/Auth/store/selectors';
import {
  ActiveBrokensList,
  ArchivedBrokensList,
  BrokenDetails
} from './Containers/Brokens/containers';
import PageWrapper from './Containers/Common/PageWrapper';
import Spinner from './Containers/Common/Spinner';
import { InvoiceList } from './Containers/Invoices/containers';
import { OrderDetails, OrdersList } from './Containers/Orders/containers';
import { ProductDetails, ProductsList } from './Containers/Products/containers';
import {
  ActiveReturnsList,
  ArchivedReturnsList,
  ReturnDetails
} from './Containers/Returns/containers';
import { ArchiveReturnStatuses } from './Containers/Settings/containers';
import { SupportDetails, SupportsList } from './Containers/Supports/containers';
import { ClientsDetails, ClientsList, UsersList } from './Containers/Users/containers';
import { ROLES, ROUTES } from './routes/constants';
import { PrivateRoute, PublicRoute } from './routes/containers';
import Error from './shared/Error/Error';
import muiTheme from './themes/muiTheme';

export default () => {
  const { isLoading } = useAuth();
  const { isMobile } = useWindowSize();
  const isAuthenticated = useSelector(getAuth());
  const userRoleId = useSelector(getUserRoleId());

  return (
    <>
      {!isLoading ? (
        <ThemeProvider theme={muiTheme(isMobile)}>
          <Error />
          <main className="container">
            <Routes>
              <Route
                path={ROUTES.SIGN_IN}
                element={
                  <PublicRoute
                    component={SignIn}
                    isAuthenticated={isAuthenticated}
                    restricted={true}
                  />
                }
              />
              <Route
                element={
                  <PageWrapper>
                    <Outlet />
                  </PageWrapper>
                }>
                <Route path={ROUTES.RETURNS} element={<Outlet />}>
                  <Route
                    path="all/:statusId"
                    // path="all"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={ActiveReturnsList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="archive/:statusId"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={ArchivedReturnsList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path=":returnId"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={ReturnDetails}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.ASSISTANCE_REQUEST} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        roles={[ROLES.SUPER_ADMIN]}
                        component={SupportsList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path=":supportId"
                    element={
                      <PrivateRoute
                        roles={[ROLES.SUPER_ADMIN]}
                        component={SupportDetails}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.ORDERS} element={<Outlet />}>
                  <Route
                    path="all"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.ADMIN]}
                        component={OrdersList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="canceled"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.ADMIN]}
                        component={OrdersList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path=":orderId"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.ADMIN]}
                        component={OrderDetails}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.CLIENTS} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        roles={[ROLES.SUPER_ADMIN]}
                        component={ClientsList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path=":clientId"
                    element={
                      <PrivateRoute
                        roles={[ROLES.SUPER_ADMIN]}
                        component={ClientsDetails}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.BROKENS} element={<Outlet />}>
                  <Route
                    path="all/:statusId"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={ActiveBrokensList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="archive/:statusId"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={ArchivedBrokensList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path=":brokenId"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={BrokenDetails}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.INVOICES} element={<Outlet />}>
                  <Route
                    path="all"
                    element={
                      <PrivateRoute component={InvoiceList} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path="archive"
                    element={
                      <PrivateRoute component={InvoiceList} isAuthenticated={isAuthenticated} />
                    }
                  />
                </Route>

                <Route path={ROUTES.ERRORS} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        // roles={[ROLES.ADMIN]}
                        component={() => <div>ALL ERRORS</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.PRODUCTS} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        // roles={[ROLES.ADMIN]}
                        component={ProductsList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path=":productId"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.ADMIN]}
                        component={ProductDetails}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.DELIVERY} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        // roles={[ROLES.ADMIN]}
                        component={() => <div>ALL DELIVERY</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.STATISTIC} element={<Outlet />}>
                  <Route
                    path="general"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={() => <div>STATISTIC GENERAL</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="returns"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={() => <div>STATISTIC RETURNS</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="brokens"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={() => <div>STATISTIC BROKENS</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="errors"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={() => <div>STATISTIC ERRORS</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.SETTINGS} element={<Outlet />}>
                  <Route
                    path="all_fields_tags"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={ArchiveReturnStatuses}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="users"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={UsersList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="admins"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={() => <div>SETTINGS ADMINS</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="workers"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={() => <div>SETTINGS WORKERS</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="office"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={() => <div>SETTINGS OFFICE</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path="preventivo"
                    element={
                      <PrivateRoute
                        // roles={[ROLES.SUPER_ADMIN, ROLES.DEVELOPER, ROLES.CLIENT, ROLES.USER]}
                        component={Estimation}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    isAuthenticated ? (
                      <Navigate to={`${ROUTES.RETURNS}/all/0`} />
                    ) : (
                      <Navigate to={ROUTES.SIGN_IN} />
                    )
                  }
                />
              </Route>
            </Routes>
          </main>
        </ThemeProvider>
      ) : (
        <Spinner />
      )}
    </>
  );
};
