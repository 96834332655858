import { createSelector } from 'reselect';

const selectProductsState = (state) => state.productsReducer;

export const selectProducts = () =>
  createSelector(selectProductsState, (state) =>
    state.products.map((product) => {
      // const activePackageTypeStatus = state.productsStatuses.find(item => +product.packageType === +item.idCategory);
      const packageTypeCategory = state.productsCategories.reduce((acc, category) => {
        const activeOption = category.options?.find(
          (option) => +product.packageType === +option.idOption
        );
        if (activeOption) {
          return {
            idCategory: category.idCategory,
            title: category.title,
            option: {
              idOption: activeOption.idOption,
              value: activeOption.value,
              color: activeOption.color
            }
          };
        }

        return acc;
      }, {});
      return {
        ...product,
        packageTypeCategory
      };
    })
  );

export const selectActiveProduct = () =>
  createSelector(selectProductsState, (state) => state.activeProduct);

export const selectProductsQuery = () =>
  createSelector(selectProductsState, (state) => state.query);

export const selectProductCategories = () =>
  createSelector(selectProductsState, (state) => state.productsCategories);

export const selectProductOrderById = (id) =>
  createSelector(selectProductsState, (state) =>
    state.activeProduct.productOrders.find((order) => +order.idAlberoOrder === +id)
  );
