import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../Common/TextField';
import { returnsActions } from '../../../../store';
import styles from './OptionsModal.module.scss';

const OptionsModal = ({
  editInfo,
  options = [],
  idReturn = false,
  refundNote = '',
  rimborsoTotal = '',
  disputedAmount = '',
  amountReceived = '',
  setIsModalOpen
}) => {
  const dispatch = useDispatch();

  const optionsToInitialValues = (options) => {
    const arrOfArrays = Object.values(options);

    return [...arrOfArrays[0], ...arrOfArrays[1]];
  };

  const INITIAL_VALUES = optionsToInitialValues(options)?.reduce(
    (acc, option) => {
      acc[option.idCategory] = option.selectedOption;
      return acc;
    },
    { refundNote, rimborsoTotal, disputedAmount, amountReceived }
  );

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      onSubmit={(values) => {
        const { refundNote, rimborsoTotal, amountReceived, disputedAmount, ...options } = values;
        const arrayOfOptions = Object.entries(options).map(([idCategory, idOption]) => ({
          idCategory,
          idOption
        }));
        const res = {
          returnObj: {
            idReturn,
            refundNote,
            rimborsoTotal,
            disputedAmount,
            amountReceived
          },
          products: [],
          property: arrayOfOptions
        };
        dispatch(returnsActions.EDIT_RETURN.REQUEST(res, () => setIsModalOpen(false)));
      }}>
      {({ errors, touched, values, setFieldValue }) => (
        <Form className={styles.entity_form} autoComplete="off">
          <h2 className={styles.form_title}>Modifica {editInfo?.title}</h2>
          <div className={styles.main_form}>
            {options?.extraProperty
              ?.filter(({ idCategory }) => {
                switch (editInfo?.type) {
                  case 1:
                    return idCategory === 5 || idCategory === 6;
                  case 2:
                    return idCategory === 7;
                  case 3:
                    return idCategory === 17;
                  case 5:
                    return idCategory === 18 || idCategory === 19 || idCategory === 20;
                }
              })
              ?.map((property) => (
                <div key={property.idCategory} className={styles.select_wrapper}>
                  <div className={styles.select_label}>{property.name}</div>
                  <Select
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onChange={(data) => setFieldValue(property.idCategory, data?.value)}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{
                      menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '30px',
                        marginLeft: '1px',
                        minHeight: '36px'
                      }),
                      input: (provided) => ({
                        ...provided,
                        input: { fontSize: '16px' }
                      })
                    }}
                    value={property.categoryOptions
                      .map(({ value, idOption }) => ({ value: idOption, label: value }))
                      .find((item) => +item.value === +values[property.idCategory])}
                    options={property.categoryOptions.map(({ value, idOption }) => ({
                      value: idOption,
                      label: value
                    }))}
                  />
                  {touched[property.idCategory] && errors[property.idCategory] && (
                    <div className={styles.select_error}>{errors[property.idCategory]}</div>
                  )}
                </div>
              ))}
            {editInfo?.type === 4
              ? options?.commonProperty
                  ?.filter((property) => property.idCategory === 9)
                  ?.map((property) => (
                    <div key={property.idCategory} className={styles.select_wrapper}>
                      <div className={styles.select_label}>{property.name}</div>
                      <Select
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        onChange={(data) => setFieldValue(property.idCategory, data?.value)}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={{
                          menuPortal: (provided) => ({ ...provided, zIndex: 520 }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: '30px',
                            marginLeft: '1px',
                            minHeight: '36px'
                          }),
                          input: (provided) => ({
                            ...provided,
                            input: { fontSize: '16px' }
                          })
                        }}
                        value={property.categoryOptions
                          .map(({ value, idOption }) => ({ value: idOption, label: value }))
                          .find((item) => +item.value === +values[property.idCategory])}
                        options={property.categoryOptions.map(({ value, idOption }) => ({
                          value: idOption,
                          label: value
                        }))}
                      />
                      {touched[property.idCategory] && errors[property.idCategory] && (
                        <div className={styles.select_error}>{errors[property.idCategory]}</div>
                      )}
                    </div>
                  ))
              : null}
            {editInfo?.type === 2 ? (
              <Field
                name="rimborsoTotal"
                id="rimborsoTotal"
                label="Importo Rimborso"
                placeholder="Importo Rimborso"
                type="number"
                component={TextField}
              />
            ) : null}
            {editInfo?.type === 1 ? (
              <Field
                name="refundNote"
                id="refundNote"
                label="Nota"
                placeholder="Nota"
                textarea={true}
                component={TextField}
                height={'90%'}
              />
            ) : null}
            {editInfo?.type === 5 ? (
              <>
                <Field
                  name="amountReceived"
                  id="amountReceived"
                  label="Importo contestato"
                  type="number"
                  placeholder="Importo contestato"
                  component={TextField}
                />
                <Field
                  name="disputedAmount"
                  id="disputedAmount"
                  label="Importo ricevuto"
                  type="number"
                  placeholder="Importo ricevuto"
                  component={TextField}
                />
              </>
            ) : null}
          </div>
          <PrimaryButton type="submit" width="100%" text="Modifica" />
        </Form>
      )}
    </Formik>
  );
};

export default OptionsModal;
