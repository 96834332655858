import actionCreator from '../../../utils/actionCreator';

const supportsActionTypes = [
  'GET_SUPPORTS',
  'GET_SUPPORT',
  'EDIT_SUPPORT',
  'SET_FILTER_FIELDS',
];

export const { types: supportsTypes, actions: supportsActions } =
  actionCreator(supportsActionTypes);
