import { createSelector } from 'reselect';

const selectUsersState = (state) => state.usersReducer;
export const selectClients = () => createSelector(selectUsersState, (state) => state.clients);
export const selectClientById = (id) =>
  createSelector(selectUsersState, (state) => state.clients.find((cli) => +cli.idUser === +id));
export const selectUserById = (id) =>
  createSelector(selectUsersState, (state) => state.users.find((user) => +user.idUser === +id));
export const selectClient = () => createSelector(selectUsersState, (state) => state.activeClient);
export const selectUsers = () => createSelector(selectUsersState, (state) => state.users);
export const selectUserOrderById = (id) =>
  createSelector(selectUsersState, (state) => state.activeClient?.orders?.find(order => +order.idAlberoOrder === +id));
