import actionCreator from '../../../utils/actionCreator';

const brokensActionTypes = [
  'GET_BROKENS_STATUSES',
  'GET_BROKENS',
  'GET_BROKEN',
  'CREATE_BROKEN',
  'EDIT_BROKEN',
  'ADD_BROKEN_MESSAGE',
  'DELETE_BROKEN_MESSAGE',
  'ADD_IMAGE_TO_PRODUCT_BROKEN',
  'DELETE_IMAGE_FROM_PRODUCT_BROKEN',
  'DELETE_BROKEN',
  'SET_FILTER_FIELDS'
];

export const { types: brokensTypes, actions: brokensActions } = actionCreator(brokensActionTypes);
