import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

import styles from './StatusHeader.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const StatusHeader = ({ value, setValue, brokenStatuses }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    const idxBeforeParam = location.pathname.lastIndexOf('/');
    navigate(location.pathname.substr(0, idxBeforeParam + 1) + newValue);
    setValue(brokenStatuses[newValue]);
  };

  return (
    <div className={styles.tabsWrapper}>
      <Tabs
        value={value?.idBreakStatus || 0}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="basic tabs example">
        {brokenStatuses?.map((status) => (
          <Tab
            key={status.name}
            className={styles.item}
            icon={<div className={styles.count}>{status.count}</div>}
            iconPosition="top"
            label={status.name}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default StatusHeader;
