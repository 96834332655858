import {DevSupport} from '@react-buddy/ide-toolbox';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import {ComponentPreviews, useInitial} from './dev';
import reportWebVitals from './reportWebVitals';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <DevSupport ComponentPreviews={ComponentPreviews}
        useInitialHook={useInitial}
      >
        <App/>
      </DevSupport>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
