import moment from 'moment';

import styles from './Activities.module.scss';
import Card from './containers/Card';

const Activities = ({ data = [] }) => {
  const formattedData = data?.reduce((acc, item) => {
    const date = moment(item.created_at).format('D MMMM YYYY');
    const findItem = acc.find((item) => item?.date === date);

    if (findItem) {
      acc[findItem?.index].value.push({ ...item, index: acc[findItem?.index].value.length + 1 });
    } else {
      acc.push({ index: acc.length, date, value: [{ ...item, index: 1 }] });
    }
    return acc;
  }, []);

  return (
    <div className={styles.activities}>
      <div className={styles.header}>Attività</div>
      <div className={styles.content}>
        {formattedData?.map((item) => (
          <div key={item.index}>
            <p className={styles.title}>{item.date}</p>
            {item.value.map((el) => (
              <Card key={item.index + el.index} data={el} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activities;
