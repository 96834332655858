import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import TableHeader from '../../../../Common/DataTables/containers/TableHeader/TableHeader';
import InvoicesTable from '../../../../Tables/InvoicesTable';
import { invoicesActions } from '../../../store';
import { selectInvoices } from '../../../store/selectors';
import styles from './InvoiceList.module.scss';

export default () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const invoices = useSelector(selectInvoices(pathname === `${ROUTES.INVOICES}/archive`));

  useEffect(() => {
    dispatch(invoicesActions.GET_INVOICES.REQUEST());
  }, []);

  return (
    <div className={styles.container__inner}>
      <TableHeader title="Fatture" showAddButton={false} />
      <InvoicesTable invoicesList={invoices} tableNumber={50} />
    </div>
  );
};
