import clx from 'classnames';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';

import { VALIDATION_SCHEMA } from './constants';
import BinIcon from '../../../../../../assets/svg/bin_gray.svg';
import styles from './CategoryAddAndEditModal.module.scss';
import useWindowSize from 'utils/hooks/useWindowSize';
import TextField from '../../../../../Common/TextField';
import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import {settingsActions} from '../../../../store';

const CategoryAddAndEditModal = ({ isEditForm = false, dataForEdit = {}, setIsModalOpen }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const INITIAL_VALUES = {
    value: dataForEdit?.value || '',
    color: dataForEdit?.color || '',
    internalCode: dataForEdit?.internalCode || ''
  };

  const removeItem = () => {
    dispatch(
      settingsActions.EDIT_OPTION.REQUEST({
        idoption: dataForEdit?.idOption,
        value: dataForEdit?.value,
        color: dataForEdit?.color,
        internalCode: dataForEdit?.internalCode,
        disabled: true
      }, setIsModalOpen)
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values) => {
        if (isEditForm) {
          dispatch(
            settingsActions.EDIT_OPTION.REQUEST({
              ...values,
              idoption: dataForEdit?.idOption,
              disabled: false
            }, setIsModalOpen)
          );
        } else {
          dispatch(
            settingsActions.ADD_OPTION_TO_CATEGORY.REQUEST({
              idCategory: dataForEdit?.idCategory,
              option: {
                ...values,
              }
            }, setIsModalOpen)
          );
        }
      }}>
      {() => (
        <Form
          className={clx(styles.entity_form, { [styles.entity_mbl]: isMobile })}
          autoComplete="off">
          <h2 className={styles.form_title}>{isEditForm ? 'Modifica' : 'Aggiungi'} Categoria</h2>
          <div className={styles.main_form}>
            <Field name="value" id="value" label="Nome" placeholder="Nome" component={TextField} />
            <Field
              name="color"
              id="color"
              label="Colore"
              placeholder="Colore"
              component={TextField}
            />
            <Field
              name="internalCode"
              id="internalCode"
              label="Codice interno"
              placeholder="Codice interno"
              component={TextField}
            />
          </div>
          <div className={styles.actions}>
            {isEditForm ? (
              <PrimaryButton
                type="button"
                width="100%"
                text="Elimina"
                color="#999"
                border="1px solid #999"
                onClick={removeItem}
                leftIconSrc={BinIcon}
              />
            ) : null}
            <PrimaryButton
              type="submit"
              width="100%"
              text={`${isEditForm ? 'Modifica' : 'Aggiungi'} Categoria`}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default CategoryAddAndEditModal;
