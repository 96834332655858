import { createSelector } from 'reselect';

import {ROLES} from '../../../routes/constants';

const selectUsersState = (state) => state.authReducer;

export const getAuth = () => createSelector(selectUsersState, (state) => state.isLogin);
export const getUser = () => createSelector(selectUsersState, (state) => state.user);
export const getUserRoleId = () => createSelector(selectUsersState, (state) => state.user?.role);
export const getIsAdminRole = () => createSelector(selectUsersState, (state) => !!state.user?.admin);
export const getIsForemanRole = () => createSelector(selectUsersState, (state) => +state.user?.role_id === ROLES.FOREMAN.id);
