import clx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useWindowSize from 'utils/hooks/useWindowSize';

import CloseIcon from '../../../assets/svg/close.svg';
import FileIcon from '../../../assets/svg/file.svg';
import { brokensActions } from '../../Brokens/store';
import { returnsActions } from '../../Returns/store';
import FilePreviewBlock from '../FilePreviewBlock';
import UploadFileModal from '../UploadFileModal';
import styles from './ManageFilesModal.module.scss';

const ManageFilesModal = ({ product, readonly, setIsModalOpen, type }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const [imagesInfo, setImagesInfo] = useState(null);
  const [modifiedImagesInfo, setModifiedImagesInfo] = useState([]);
  const [isImageFullSize, setIsImageFullSize] = useState(null);

  useEffect(() => {
    if (product?.images?.length) {
      switch (type) {
        case 'return': {
          setModifiedImagesInfo(
            product?.images?.map((image) => ({
              ...image,
              name: `Filename#${image.idReturnProductsImage}${image.extName}`,
              imageId: image.idReturnProductsImage
            }))
          );
          break;
        }
        case 'break': {
          setModifiedImagesInfo(
            product?.images?.map((image) => ({
              ...image,
              name: `Filename#${image.idBreakProductsImage}${image.extName}`,
              imageId: image.idBreakProductsImage
            }))
          );
          break;
        }
        case 'support': {
          setModifiedImagesInfo(
            product?.images?.map((image) => ({
              ...image,
              name: `Filename#${image.idSupportProductsImages}${image.extName}`,
              imageId: image.idSupportProductsImages
            }))
          );
          break;
        }
      }
    }
  }, [product]);

  useEffect(() => {
    if (imagesInfo) {
      switch (type) {
        case 'return': {
          dispatch(
            returnsActions.ADD_IMAGE_TO_PRODUCT_RETURN.REQUEST({
              id: product?.idReturn,
              idReturnProduct: product?.idReturnProduct,
              base64: imagesInfo?.url
            })
          );
          break;
        }
        case 'break': {
          dispatch(
            brokensActions.ADD_IMAGE_TO_PRODUCT_BROKEN.REQUEST({
              id: product?.idBreak,
              idBreakProduct: product?.idBreakProduct,
              base64: imagesInfo?.url
            })
          );
          break;
        }
      }
    }
    setImagesInfo(null);
  }, [imagesInfo]);

  const removeItem = (id) => {
    switch (type) {
      case 'return': {
        dispatch(
          returnsActions.DELETE_IMAGE_FROM_PRODUCT_RETURN.REQUEST({
            id: product?.idReturn,
            idReturnProductsImage: id
          })
        );
        break;
      }
      case 'break': {
        dispatch(
          brokensActions.DELETE_IMAGE_FROM_PRODUCT_BROKEN.REQUEST({
            id: product?.idBreak,
            idBrokenProductsImage: id
          })
        );
        break;
      }
    }
    closeAndClear();
  };

  const closeAndClear = () => {
    setIsModalOpen();
    setIsImageFullSize(null);
    setImagesInfo(null);
  };

  return (
    <div
      className={clx(styles.images, {
        [styles['images_full-size']]: isImageFullSize,
        [styles.images_mbl]: isMobile
      })}>
      <div className={styles['out-wrapper']}>
        {isImageFullSize && (
          <FilePreviewBlock
            info={
              isImageFullSize
                ? `Allegato creato dal "Cliente" il  ${moment(isImageFullSize?.createdAt).format(
                    'DD/MM/YYYY or HH:mm'
                  )}`
                : null
            }
            readonly={readonly}
            isFileFullSize={isImageFullSize}
            setIsFileFullSize={setIsImageFullSize}
            removeItem={removeItem}
          />
        )}
        <div className={styles.main}>
          <div className={styles.modal__header} onClick={closeAndClear}>
            <img src={CloseIcon} alt="close" />
          </div>
          <h2 className={styles.modal__title}>Allegati ({modifiedImagesInfo.length})</h2>
          <div className={styles['files-form']}>
            <div className={styles['files-form__list']}>
              {modifiedImagesInfo.map((image, index) => (
                <div
                  key={index}
                  className={clx(styles['files-form__list__item'], {
                    [styles['files-form__list__item_active']]: image?.url === isImageFullSize?.url
                  })}
                  onClick={() => setIsImageFullSize(image)}>
                  <img src={FileIcon} />
                  <span className={styles['files-form__list__item__filename']}>{image?.name}</span>
                </div>
              ))}
              {!readonly ? (
                <UploadFileModal
                  isImage={true}
                  filesInfo={imagesInfo}
                  setUploadFiles={setImagesInfo}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageFilesModal;
