import { Checkbox, FormControlLabel } from '@mui/material';
import clx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import useWindowSize from 'utils/hooks/useWindowSize';

import NoImage from '../../../../assets/images/no-photo.png';
import ArchiveIcon from '../../../../assets/svg/archive_white_arrow_red.svg';
import ArrowIcon from '../../../../assets/svg/arrow-left-blue.svg';
import CheckMark from '../../../../assets/svg/check_mark_white.svg';
import EyeIcon from '../../../../assets/svg/eye.svg';
import PencilIcon from '../../../../assets/svg/pencil_gray.svg';
import Activities from '../../../Common/Activities';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import Chat from '../../../Common/Chat';
import CustomCheckbox from '../../../Common/CustomCheckbox';
import ManageFilesModal from '../../../Common/ManageFilesModal';
import Modal from '../../../Common/Modal';
import SideModal from '../../../Common/SideModal';
import TextFieldMockup from '../../../Common/TextFieldMockup';
import { returnsActions } from '../../store';
import {
  selectActiveReturn,
  selectActiveReturnStatuses,
  selectReturnProductById
} from '../../store/selectors';
import { PARCEL_STATUSES } from '../constants';
import ReturnAddAndEditModal from '../ReturnsList/containers/ReturnAddAndEditModal';
import OptionsModal from './containers/OptionsModal';
import ReturnProductModal from './containers/ReturnProductModal';
import styles from './ReturnDetails.module.scss';

const commonOptions = [9, 10, 11];

const ReturnDetails = () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { returnId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(null);
  const [isEditReturnModalOpen, setIsEditReturnModalOpen] = useState(false);
  const [isManageFilesModalOpen, setIsManageFilesModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [activeProductId, setActiveProductId] = useState(null);

  const activeReturn = useSelector(selectActiveReturn());
  const returnStatuses = useSelector(selectActiveReturnStatuses());
  const activeProduct = useSelector(selectReturnProductById(activeProductId));
  const properties = activeReturn?.property?.reduce(
    (acc, property) =>
      !commonOptions.includes(property.idCategory)
        ? { ...acc, extraProperty: [...acc.extraProperty, property] }
        : { ...acc, commonProperty: [...acc.commonProperty, property] },
    { commonProperty: [], extraProperty: [] }
  );

  const activeStatus = (productStatus) =>
    activeReturn.products?.statuses?.find(
      ({ idReturnProductStatuses, name, color }) => +productStatus === +idReturnProductStatuses
    );

  useEffect(() => {
    if (returnId === 'all' || returnId === 'archive') {
      navigate(`${ROUTES.RETURNS}/${returnId}/0`);
    } else {
      dispatch(returnsActions.GET_RETURNS_STATUSES.REQUEST());
      dispatch(returnsActions.GET_RETURN.REQUEST(returnId));
    }
  }, [dispatch]);

  const editReturn = (returnObj) => {
    dispatch(
      returnsActions.EDIT_RETURN.REQUEST({
        returnObj,
        products: [],
        property: []
      })
    );
  };

  const editProductStatus = (product) => {
    dispatch(
      returnsActions.EDIT_RETURN.REQUEST({
        returnObj: { idReturn: returnId },
        products: [product],
        property: []
      })
    );
  };

  const openProductImagesSidebar = (productId) => {
    setActiveProductId(productId);
    setIsManageFilesModalOpen(true);
  };

  const openProductEditModal = (productId) => {
    setActiveProductId(productId);
    setIsProductEditModalOpen(true);
  };

  const formattedDate = ({ state, province, city, postalCode, address }) =>
    `${state || ''} ${province || ''} ${city || ''} ${postalCode || ''} ${address || ''}`;

  return Object.values(activeReturn).length ? (
    <div
      className={clx(styles.return_detail_container, {
        [styles.return_detail_container_mbl]: isMobile
      })}>
      {location.state?.page >= 0 ? (
        <PrimaryButton
          height="34px"
          fontSize="13px"
          width="100px"
          padding="8px 10px"
          margin="0 0 20px 0"
          borderRadius="40px"
          text={'Indietro'}
          leftIconSrc={ArrowIcon}
          onClick={() =>
            navigate(location.state?.pathname, { state: { page: location.state?.page || 0 } })
          }
        />
      ) : null}
      <div className={styles.return_statuses_wrapper}>
        {returnStatuses.map((status) => (
          <div className={styles.status_item} key={status.idReturnStatus}>
            <div
              onClick={() =>
                !status.isActive &&
                editReturn({
                  idReturn: activeReturn?.return?.idReturn,
                  status: status.idReturnStatus
                })
              }
              className={clx(
                styles.item_circle,
                { [styles.active]: !!status.isActive },
                { [styles.completed]: !!status.isCompleted }
              )}>
              <img src={CheckMark} alt="" />
              <div className={styles.item_value}>{status.idReturnStatus}</div>
            </div>
            <div className={styles.item_label}>{status.name}</div>
          </div>
        ))}
      </div>
      <div className={styles.return_data_wrapper}>
        <div className={styles.main_content_wrapper}>
          <div className={clx(styles.entity_form)}>
            <div className={styles.form_action}>
              <h2 className={styles.form_title}>Info ordine e cliente del reso</h2>
              <PrimaryButton
                onClick={() => setIsEditReturnModalOpen(true)}
                text={'Modifica'}
                color={'#5D5D5D'}
                border={'1px solid #5D5D5D'}
                height={'27px'}
                padding={'4px'}
                leftIconSrc={PencilIcon}
              />
            </div>
            <div className={styles.main_form}>
              <TextFieldMockup
                value={`${activeReturn?.return?.number}`}
                label="Numero"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={moment(activeReturn?.return?.orderDate).format('DD/MM/YYYY')}
                label="Data creazione ordine"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeReturn?.return?.carrier}
                label="Corriere di andata"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeReturn?.return?.returnCarrier}
                label="Corriere di ritorno"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeReturn?.return?.channel}
                label="Canale"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeReturn?.return?.customer?.name}
                label="Nome Cliente"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeReturn?.return?.customer?.email}
                label="Email Cliente"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={activeReturn?.return?.ldv}
                label="Num. altra LDV"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={() => formattedDate(activeReturn?.return?.shippingAddress)}
                label="Indirizzo di spedizione"
                maxWidth={'275px'}
              />
              <TextFieldMockup
                value={() => formattedDate(activeReturn?.return?.billingAddress)}
                label="Indirizzo di fatturazione"
                maxWidth={'275px'}
              />
              {/*<TextFieldMockup
                color={'#fff'}
                bgColor={'#00189A'}
                value={activeReturn?.return?.userRequestSolution}
                label="Soluzione Richiesta Da utente"
              />*/}
              {properties.commonProperty.map((property) => {
                const value = property.categoryOptions.find(
                  (option) => +option.idOption === +property.selectedOption
                )?.value;
                return property.idCategory === 11 || property.idCategory === 10 ? (
                  <div key={property.idCategory}>
                    <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className={styles.status_block}>
            <h2 className={styles.status_block_title}>Stato del pacco</h2>
            <div>
              {PARCEL_STATUSES.map((status) => (
                <FormControlLabel
                  onChange={({ target: { checked } }) => {
                    const returnObj = {
                      idReturn: activeReturn?.return?.idReturn
                    };
                    returnObj[status.key] = checked;
                    editReturn(returnObj);
                  }}
                  labelPlacement="start"
                  key={status.key}
                  control={
                    <Checkbox
                      checked={!!activeReturn?.return && !!activeReturn?.return[status.key]}
                    />
                  }
                  label={status.name}
                />
              ))}
            </div>
          </div>
          {!!activeReturn?.products?.products?.length && (
            <div className={styles.products_container}>
              <h2 className={styles.products_title}>Info Prodotti dell’ordine</h2>
              <div className={styles['products-list']}>
                {activeReturn?.products?.products?.map((product) => (
                  <div key={product.idReturnProduct} className={styles['product-item']}>
                    <div className={styles['first-row']}>
                      <div className={styles.checkbox}>
                        <CustomCheckbox
                          checked={!!product.done}
                          setChecked={() => editProductStatus({ ...product, done: !product.done })}
                          size={20}
                        />
                      </div>
                      <div className={styles.image}>
                        <img src={product?.image || NoImage} alt="product-image" width={'100%'}/>
                      </div>
                      <div className={styles.info}>
                        <div className={styles['name-info']}>
                          <div className={styles['name-status']}>
                            <div className={styles.name}>
                              {product.name} ({product.sku})
                            </div>
                            <div
                              className={styles.status}
                              style={{
                                backgroundColor: activeStatus(product?.productStatus)?.color
                              }}>
                              {activeStatus(product?.productStatus)?.name}
                            </div>
                          </div>
                          <div className={styles['info-files']}>
                            <p>Info cliente</p>
                            <div className={styles['long-value']}>{product.customerReason}</div>
                            <div className={styles.files}>
                              <img
                                src={EyeIcon}
                                onClick={() => openProductImagesSidebar(product.idReturnProduct)}
                              />
                              <div className={styles.files__qty}>
                                +{product?.images?.length || 0}
                                &nbsp; allegato
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.quantities}>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità ordine</p>
                            <div className={styles['qty-value']}>{product.quantity}</div>
                          </div>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità rese</p>
                            <div className={styles['qty-value']}>{product.returnQuantity}</div>
                          </div>
                          <div className={styles['qty-item']}>
                            <p className={styles['qty-title']}>Quantità rotte</p>
                            <div className={styles['qty-value']}>{product.statusQuantity}</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.edit}>
                        <img
                          onClick={() => openProductEditModal(product.idReturnProduct)}
                          src={PencilIcon}
                        />
                      </div>
                    </div>
                    <div className={styles['second-row']}>
                      <div>
                        <p>Nota</p>
                        <div className={styles['long-value']}>{product.note}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles['properties-section']}>
          <div className={styles.return_options_wrapper}>
            <div className={styles.option_block}>
              <div className={styles.option_action}>
                <h2 className={styles.option_title}>Info reso</h2>
                <PrimaryButton
                  onClick={() =>
                    setIsOptionsModalOpen({
                      type: 1,
                      title: 'info reso'
                    })
                  }
                  text={'Modifica'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'4px'}
                  leftIconSrc={PencilIcon}
                />
              </div>
              <div className={styles.option_form}>
                {properties.extraProperty.map((property) => {
                  const value = property.categoryOptions.find(
                    (option) => +option.idOption === +property.selectedOption
                  )?.value;
                  switch (+property.idCategory) {
                    case 5:
                      return (
                        <div key={property.idCategory}>
                          <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                        </div>
                      );
                    case 6:
                      return (
                        <div key={property.idCategory}>
                          <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                          <TextFieldMockup
                            maxWidth={'275px'}
                            value={activeReturn?.return?.refundNote}
                            label={'Nota'}
                            textarea={true}
                          />
                        </div>
                      );
                  }
                })}
              </div>
            </div>
          </div>
          <div className={styles.return_options_wrapper}>
            <div className={styles.option_block}>
              <div className={styles.option_action}>
                <h2 className={styles.option_title}>Stato del rimborso</h2>
                <PrimaryButton
                  onClick={() =>
                    setIsOptionsModalOpen({
                      type: 2,
                      title: 'stato del rimborso'
                    })
                  }
                  text={'Modifica'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'4px'}
                  leftIconSrc={PencilIcon}
                />
              </div>
              <div>
                {properties.extraProperty.map((property) => {
                  const value = property.categoryOptions.find(
                    (option) => +option.idOption === +property.selectedOption
                  )?.value;
                  switch (+property.idCategory) {
                    case 7:
                      return (
                        <div key={property.idCategory}>
                          <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                        </div>
                      );
                  }
                })}
                <TextFieldMockup
                  value={activeReturn?.return?.rimborsoTotal}
                  label="Importo Rimborso"
                  maxWidth={'275px'}
                />
              </div>
            </div>
          </div>
          {/*<div className={styles.return_options_wrapper}>*/}
          {/*  <div className={styles.option_block}>*/}
          {/*    <div className={styles.option_action}>*/}
          {/*      <h2 className={styles.option_title}>TEMP TITLE!!!</h2>*/}
          {/*      <PrimaryButton*/}
          {/*        onClick={() => setIsOptionsModalOpen(3)}*/}
          {/*        text={'Modifica'}*/}
          {/*        color={'#5D5D5D'}*/}
          {/*        border={'1px solid #5D5D5D'}*/}
          {/*        height={'27px'}*/}
          {/*        padding={'4px'}*/}
          {/*        leftIconSrc={PencilIcon}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      {properties.extraProperty.map((property) => {*/}
          {/*        const value = property.categoryOptions.find(*/}
          {/*          (option) => +option.idOption === +property.selectedOption*/}
          {/*        )?.value;*/}
          {/*        switch (+property.idCategory) {*/}
          {/*          case 17:*/}
          {/*            return (*/}
          {/*              <div key={property.idCategory}>*/}
          {/*                <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />*/}
          {/*              </div>*/}
          {/*            );*/}
          {/*        }*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.return_options_wrapper}>
            <div className={styles.option_block}>
              <div className={styles.option_action}>
                <h2 className={styles.option_title}>Stato Magazzino</h2>
                <PrimaryButton
                  onClick={() =>
                    setIsOptionsModalOpen({
                      type: 4,
                      title: 'Stato Magazzino'
                    })
                  }
                  text={'Modifica'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'4px'}
                  leftIconSrc={PencilIcon}
                />
              </div>
              <div>
                {properties.commonProperty.map((property) => {
                  const value = property.categoryOptions.find(
                    (option) => +option.idOption === +property.selectedOption
                  )?.value;
                  return property.idCategory === 9 ? (
                    <div key={property.idCategory}>
                      <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                    </div>
                  ) : null;
                })}
              </div>
            </div>
          </div>
          <div className={styles.return_options_wrapper}>
            <div className={styles.option_block}>
              <div className={styles.option_action}>
                <h2 className={styles.option_title}>Contestazione corriere</h2>
                <PrimaryButton
                  onClick={() =>
                    setIsOptionsModalOpen({
                      type: 5,
                      title: 'contestazione corriere'
                    })
                  }
                  text={'Modifica'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'4px'}
                  leftIconSrc={PencilIcon}
                />
              </div>
              <div>
                {properties.extraProperty.map((property) => {
                  const value = property.categoryOptions.find(
                    (option) => +option.idOption === +property.selectedOption
                  )?.value;
                  switch (+property.idCategory) {
                    case 18:
                    case 19:
                    case 20:
                      return (
                        <div key={property.idCategory}>
                          <TextFieldMockup maxWidth={'275px'} value={value} label={property.name} />
                        </div>
                      );
                  }
                })}
                <TextFieldMockup
                  value={activeReturn?.return?.amountReceived}
                  label="Importo contestato"
                  maxWidth={'275px'}
                />
                <TextFieldMockup
                  value={activeReturn?.return?.disputedAmount}
                  label="Importo ricevuto"
                  maxWidth={'275px'}
                />
              </div>
            </div>
          </div>
          {!activeReturn?.return?.archived ? (
            <div className={styles.return_options_wrapper}>
              <div className={styles.archive_block}>
                <h2 className={styles.archive_title}>Vuoi Archiviare questo reso?</h2>
                <PrimaryButton
                  onClick={() =>
                    editReturn({
                      idReturn: activeReturn?.return?.idReturn,
                      archived: true
                    })
                  }
                  text={'Archivia'}
                  color={'#5D5D5D'}
                  border={'1px solid #5D5D5D'}
                  height={'27px'}
                  padding={'6px'}
                />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.return_options_wrapper}>
                <div className={styles.archive_block}>
                  <h2 className={styles.archive_title}>Vuoi rimuovere questo reso Archivio?</h2>
                  <PrimaryButton
                    onClick={() =>
                      editReturn({
                        idReturn: activeReturn?.return?.idReturn,
                        archived: false
                      })
                    }
                    text={'Ripristina'}
                    color={'#D11E1E'}
                    border={'1px solid #D11E1E'}
                    height={'27px'}
                    padding={'6px'}
                    leftIconSrc={ArchiveIcon}
                  />
                </div>
              </div>
              <div className={styles.return_options_wrapper}>
                <div className={styles.archive_block}>
                  <h2 className={styles.archive_title}>
                    Vuoi eliminare definitivamente questo reso?
                  </h2>
                  <PrimaryButton
                    onClick={() =>
                      dispatch(
                        returnsActions.DELETE_RETURN.REQUEST(activeReturn?.return?.idReturn, () =>
                          navigate(`${ROUTES.RETURNS}/all/0`)
                        )
                      )
                    }
                    text={'Elimina'}
                    color={'#D11E1E'}
                    border={'1px solid #D11E1E'}
                    height={'27px'}
                    padding={'6px'}
                    leftIconSrc={ArchiveIcon}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={clx(styles.header_activities)}>
        <Chat data={activeReturn?.chat} entityId={activeReturn?.return?.idReturn} type={'return'} />
        <Activities data={activeReturn?.activities} />
      </div>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={!!Object.values(isOptionsModalOpen || {}).length}
        setIsModalOpen={() => setIsOptionsModalOpen(null)}>
        <OptionsModal
          editInfo={isOptionsModalOpen}
          options={properties}
          idReturn={activeReturn?.return?.idReturn}
          refundNote={activeReturn?.return?.refundNote}
          rimborsoTotal={activeReturn?.return?.rimborsoTotal}
          disputedAmount={activeReturn?.return?.disputedAmount}
          amountReceived={activeReturn?.return?.amountReceived}
          setIsModalOpen={() => setIsOptionsModalOpen(null)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isProductEditModalOpen && activeProduct}
        setIsModalOpen={() => setIsProductEditModalOpen(false)}>
        <ReturnProductModal
          activeProduct={activeProduct}
          idReturn={activeReturn?.return?.idReturn}
          setIsModalOpen={() => setIsProductEditModalOpen(false)}
        />
      </Modal>
      <Modal
        width={isMobile && '100%'}
        height={isMobile && '100%'}
        showCloseBtn={true}
        isModalOpen={isEditReturnModalOpen}
        setIsModalOpen={() => setIsEditReturnModalOpen(false)}>
        <ReturnAddAndEditModal
          isMobile={isMobile}
          isEditForm={true}
          setIsModalOpen={() => setIsEditReturnModalOpen(false)}
          defaultValues={{
            number: activeReturn?.return?.number || '',
            idAlberoOrder: activeReturn?.return?.idOrder || '',
            date: activeReturn?.return?.createdAt || null,
            clientName: activeReturn?.return?.customer?.name || '',
            customer: {
              email: activeReturn?.return?.customer?.email || ''
            }
          }}
          returnValues={{
            date: activeReturn?.return?.date || null,
            ldv: activeReturn?.return?.ldv || '',
            returnCarrier: activeReturn?.return?.returnCarrierId || '',
            carrier: activeReturn?.return?.carrierId || '',
            channel: activeReturn?.return?.channelId || ''
          }}
          options={properties.commonProperty}
          idReturn={activeReturn?.return?.idReturn}
        />
      </Modal>
      <SideModal isModalOpen={isManageFilesModalOpen && activeProduct}>
        <ManageFilesModal
          product={activeProduct}
          setIsModalOpen={() => setIsManageFilesModalOpen(false)}
          type={'return'}
        />
      </SideModal>
    </div>
  ) : null;
};

export default ReturnDetails;
